import { Button, Divider, Drawer, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import Header from "./Header";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import ViewTemplate from "../../../Templates/components/ViewTemplate";
import PrintDrawer from "../../../../../components/GlobalComponents/PrintDrawer";
import { usePDF } from "react-to-pdf";


const {  Title } = Typography;
const VisitPad = ({ visible, data, onClose }) => {
  const [loading, setLoading] = useState(true)
  const { officeSetting, patientBasicInfo ,providerDetails , getProviderDetails } = useStateGlobalContext();
  const full_name = `${patientBasicInfo?.first_name || ''} ${patientBasicInfo?.middle_name ? patientBasicInfo.middle_name + ' ' : ''}${patientBasicInfo?.last_name || ''}`.trim();


  useEffect(() => {
    if (data?.provider_id) {
      getProviderDetails(data?.provider_id);
      setLoading(false)
    }
  }, [data?.provider_id]);

  const { toPDF, targetRef } = usePDF({
    filename: `${full_name}.pdf` || "Encounter.pdf",
    page: {
      format: "A4",
      orientation: "portrait",
      margin: 10,
    },
    scale: 0.95,
  });

  const downloadPDF = () => {
    const noPrintElements = document.querySelectorAll(".no-print");

    // Hide no-print elements before generating the PDF
    noPrintElements.forEach((el) => (el.style.display = "none"));

    // Generate the PDF
    toPDF().then(() => {
      // Show no-print elements after PDF is generated
      noPrintElements.forEach((el) => (el.style.display = ""));
    });
  };
 
  return (
    <PrintDrawer
      title={full_name}
      visible={visible}
      onClose={onClose}
    >
      <div className="invoices-print">
        <div className="invoice-container">
          <Button onClick={downloadPDF} className="mb-2" type="primary"> 
            Download PDF
          </Button>
          <div className="invoice-content" ref={targetRef}>
            <h2 className="text-center mt-4 mb-4">Patient Encounter Notes {data?.status === 'Cancelled' && <span className="font-medium text-red-500">({data.status})</span>}</h2>
            <Header
              officeInfo={officeSetting}
              patientInfo={patientBasicInfo}
              date={data?.date_time}
              providerDetails={providerDetails}
            />
            <Divider />
            <Title className="text-center mt-4" level={5}>
              Encounter Notes
            </Title>
            <ViewTemplate template={data?.encounter_details}/>
          </div>
        </div>
      </div>
      </PrintDrawer>
  );
};

export default VisitPad;
