import React, {  useState } from "react";
import { ConfigProvider, Tabs } from "antd";
import CreatePrescription from './CreateNewPrescription';
import AllMedications from "./AllMedications";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";


const tabPaneData = [
  {
    label: "All Medications",
    key: "1",
    content: <AllMedications />,
  },
  {
    label: "New Prescription",
    key: "2",
    content: <CreatePrescription />,
  },
];

function Prescription() {
  const [activeKey, setActiveKey] = useState("1");
  const { profile } = useStateGlobalContext();

  const role = profile && profile?.user_info[0]?.role;
  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              fontSize: 13,
            },
          },
        }}
      >
        <Tabs activeKey={activeKey} type="card" onTabClick={handleTabChange}>
        {tabPaneData.map((tab) =>
            (tab.key === "2" && (role === "nurse" || role === "biller")) ? null : (
              <Tabs.TabPane key={tab.key} tab={tab.label}>
                {activeKey === tab.key && tab.content}
              </Tabs.TabPane>
            )
          )}
        </Tabs>
      </ConfigProvider>
    </div>
  );
}

export default Prescription;
