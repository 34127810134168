import React, { useMemo, useState, useCallback, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  Card,
  Input,
  Table,
  AutoComplete,
  Select,
  Tag,
} from "antd";
import {
  useSearchCPT,
  useSearchDiagnosis,
  useSearchProvider,
  useSearchServices,
} from "../../../../../../API/apiUtils";
import { useParams } from "react-router-dom";
import FacilityCode from "../../../../Setting/components/Datasets/FacilityCode";
import { DeleteOutlined } from "@ant-design/icons";
import { doPost } from "../../../../../../API/apis";
import { useStateGlobalContext } from "../../../../../../contexts/GlobalContext";
import dayjs from "dayjs";
import LabelDisplay from "../../../../../../components/LabelDisplay/LabelDisplay";
import Notification from "../../../../../../components/Notification/Notification";
import FormatDate from "../../../../../../components/FormatDate/FormatDate";
import {
  validateNumber,
  validatePrice,
} from "../../../../../../utils/validator";
import BaseDatePicker from "../../../../../../components/BaseDatePicker/BaseDatePicker";

const { Option } = Select;

const rules = {
  required: true,
  message: "This Field is Requried",
};

const SuperbillDrawer = ({ open, onClose, clientDetails }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [form] = Form.useForm();
  const [diagnosisDetails, setDiagnosisDetails] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedCPT, setSelectedCPT] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState(FacilityCode);
  const [providerDetails, setProviderDetails] = useState(null);
  const [modifiers, setModifiers] = useState([]);
  const {
    officeSetting,
    getOfficeSettings,
    billingInformation,
    getBillingInformation,
    getSuperbill,
  } = useStateGlobalContext();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await Promise.all([getBillingInformation(), getOfficeSettings()]);
      } catch (error) {
        Notification.error("Something Went Wrong");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  const handleCancel = () => {
    onClose();
    form.resetFields();
    setDiagnosisDetails(null);
    setServices([]);
    setProviderDetails(null);
  };

  const {
    facilty_registration_number,
    tax_id,
    facilty_code,
    additional_billing_data_1,
    additional_billing_data_2,
    additional_billing_data_3,
  } = billingInformation || {};


  const { facility_name, facility_address, office_phone, office_fax } =
    officeSetting || {};

 
    const practise_info = [
      facility_name && facility_name[1] 
        ? { title: "Facility Name", value: facility_name[1] } 
        : null,
      facilty_registration_number && facilty_registration_number[1] 
        ? { title: "Facility Registration Number", value: facilty_registration_number[0] } 
        : null,
        facilty_registration_number && facilty_registration_number[3] ?
      { title: "Office NPI (Billing Provider)", value: facilty_registration_number[2] } 
      : null,
      tax_id && tax_id[1] 
        ? { title: "Tax ID", value: tax_id[0] } 
        : null,
      facility_address && facility_address[0] 
        ? { title: "Facility Address", value: facility_address[0] } 
        : null,
      office_phone && office_phone[0] 
        ? { title: "Office Phone", value: office_phone[0] } 
        : null,
      office_fax && office_fax[0] 
        ? { title: "Office Fax", value: office_fax[0] } 
        : null,
        additional_billing_data_1 && additional_billing_data_1[1] 
        ? { title: "Data l", value: additional_billing_data_1[0] } 
        : null,
        additional_billing_data_2 && additional_billing_data_2[1] 
        ? { title: "Data ll", value: additional_billing_data_2[0] } 
        : null,
        additional_billing_data_3 && additional_billing_data_3[1]
        ? { title: "Data lll", value: additional_billing_data_3[0] } 
        : null,
    ].filter(Boolean);
    

  const formattedPractiseInfo = practise_info
  .map((info) => `${info.title}: ${info.value}`)
  .join("\n");

  const [selectedDiagnosisIds, setSelectedDiagnosisIds] = useState([]);
  const { diagnosisOptions: options, handleSearchDiagnosis } =
    useSearchDiagnosis();
  const { CPTCodesOptions, handleSearchCPTCodes } = useSearchCPT();
  const { providerInfo, handleSearchProviderInfo } = useSearchProvider();

  const { options: serviceTypeOptions, handleSearchServices } =
    useSearchServices();

  const handleSearchFacilityCodes = useCallback((searchText) => {
    const filteredOptions = FacilityCode.filter(
      (option) =>
        option?.service_name
          ?.toLowerCase()
          .includes(searchText.toLowerCase()) ||
        option?.description?.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredOptions(filteredOptions);
  }, []);

  const memoizedFilteredOptions = useMemo(
    () => filteredOptions,
    [filteredOptions]
  );

  const handleSelectDX = (selectedIds) => {
    setSelectedDiagnosisIds(selectedIds);
  };

  const generateLetter = (index=0) => {
    return String.fromCharCode(65 + index);
  };

  const handleChange = (value) => {
    if (value.length > 4) {
      Notification.error("You can only select up to 4 modifiers.");

      value.pop();
      return;
    }
    setModifiers(value);
  };

  const diagnosisColumn = [
    {
      key: 1,
      title: "#",
      align: "center",
      dataIndex: "dx_link",
    },
    {
      key: 2,
      title: "DX",
      align: "center",
      dataIndex: "dx",
    },
    {
      key: 3,
      title: "Diagnosis Description",
      dataIndex: "diagnosis_code",
    },
    {
      key: 4,
      title: "Action",
      align: "center",
      render: (_, __, index) => (
        <span>
          <DeleteOutlined
            style={{ color: "#F44336" }}
            onClick={() => handleDeleteDiagnosis(index)}
          />
        </span>
      ),
    },
  ];

  const servicesColumn = [
    {
      key: 1,
      title: "#",
      align: "center",
      render: (_, __, index) => index + 1,
    },
    {
      key: 2,
      title: "Date of Service",
      align: "center",
      dataIndex:"date",
      render:(text)=> <FormatDate date={text}/>
    },
    {
      key: 3,
      title: "POS",
      dataIndex: "pos",
    },
    {
      key: 4,
      title: "Code",
      align: "center",
      dataIndex: "code",
    },
    {
      key: 5,
      title: "Service",
      align: "center",
      dataIndex: "service",
    },
    {
      key: 6,
      title: "DX",
      dataIndex: "dx",
      render: (dx) => (
        <>
          {dx.map((id, index) => (
            <Tag key={index}>{id}</Tag>
          ))}
        </>
      ),
    },

    {
      key: 7,
      title: "Modifier",
      align: "center",
      dataIndex: "modifier",
      render: (modifier) => (
        <>{modifier && modifier?.map((tag) => <Tag key={tag}>{tag}</Tag>)}</>
      ),
    },
    {
      key: 8,
      title: "Unit",
      align: "center",
      dataIndex: "unit",
    },
    {
      key: 9,
      title: "Fee",
      dataIndex: "fee",
    },
    {
      key: 10,
      title: "Paid",
      align: "center",
      dataIndex: "paid",
    },
    {
      key: 11,
      title: "Action",
      align: "center",
      render: (_, __, index) => (
        <span>
          <DeleteOutlined
            style={{ color: "#F44336" }}
            onClick={() => handleDeleteServices(index)}
          />
        </span>
      ),
    },
  ];

  const onSelectProvider = (value, option) => {
    const selectedProvider =
      providerInfo && providerInfo?.find((item) => item.value === value);
    if (selectedProvider) {
      const provider_details = [
        {
          provider_name: value,
          license: selectedProvider?.npi,
          tax_id: selectedProvider?.tax_id,
          phone: selectedProvider?.phone,
          additional_data_1: selectedProvider?.additional_data_1,
          additional_data_2: selectedProvider?.additional_data_2,
          additional_data_3: selectedProvider?.additional_data_3,
        },
      ];
      setProviderDetails(provider_details);
    }
  };

  const onSelect = (value, option) => {
    const selectedDiagnosis = options.find((item) => item.value === value);
    if (selectedDiagnosis) {
      const newDiagnosis = {
        dx_link: generateLetter(diagnosisDetails?.length),
        dx: selectedDiagnosis.value,
        diagnosis_code: selectedDiagnosis.description,
      };
      // Check if diagnosisDetails is iterable
      if (
        diagnosisDetails &&
        typeof diagnosisDetails[Symbol.iterator] === "function"
      ) {
        setDiagnosisDetails([...diagnosisDetails, newDiagnosis]);
      } else {
        setDiagnosisDetails([newDiagnosis]);
      }
    }
    form.setFieldsValue({ diagnosisCode: undefined });
  };

  const handleDeleteDiagnosis = (index) => {
    setDiagnosisDetails(diagnosisDetails.filter((_, i) => i !== index));
  };

  const handleDeleteServices = (index) => {
    setServices(services?.filter((_, i) => i !== index));
  };

  const handleCPTCode = (value, option) => {
    const selectedCPTCode = CPTCodesOptions.find(
      (item) => item.value === value
    );
    if (selectedCPTCode) {
      setSelectedCPT({
        id: selectedCPTCode?.id,
        cptCode: selectedCPTCode?.value,
        serviceDescription: selectedCPTCode?.service_description,
        fee: selectedCPTCode?.fee,
      });
    }
  };

  const handleAddService = () => {
    form
      .validateFields([
        "pos",
        "procedureCodes",
        "dx_value",
        "service_type",
        "unit",
        "date"
      ])
      .then(() => {
        // Ensure `services` is an array before updating
        setServices((prevServices) => {
          if (!Array.isArray(prevServices)) {
            console.error("Invalid state: prevServices is not an array", prevServices);
            return [];
          }
          return [
            ...prevServices,
            {
              date: form.getFieldValue("date"),
              code: selectedCPT?.cptCode,
              serviceDescription: selectedCPT?.serviceDescription,
              fee: selectedCPT?.fee,
              paid: form.getFieldValue("paid"),
              service: form.getFieldValue("service_type"),
              unit: form.getFieldValue("unit"),
              modifier: form.getFieldValue("modifier"),
              dx: selectedDiagnosisIds,
              pos: form.getFieldValue("pos"),
            },
          ];
        });
  
        // Reset field values after adding the service
        form.resetFields([
          "procedureCodes",
          "dx_value",
          "service_type",
          "unit",
          "paid",
          "modifier",
        ]);
      })
      .catch((error) => {
        console.error("Validation Error:", error);
        Notification.error("Please fill in all required fields.");
      });
  };
  

  const handleSuperbill = async (values) => {
    // Check if diagnosisDetails or services are empty
    if (!diagnosisDetails || diagnosisDetails.length === 0) {
      Notification.error("Please choose a diagnosis code.");
      return;
    }
    if(!providerDetails){
      Notification.error("Please Choose a provider First")
      return;
    }
    if (!services || services.length === 0) {
      Notification.error("Please choose the required services.");
      return;
    }

    setSubmitLoading(true);
    const data = {
      prior_auth_number: form.getFieldValue("prior_auth_number"),
      provider_info: providerDetails && providerDetails,
      office_info: form.getFieldValue("office_info").replace(/\n/g, ", "),
      diagnosis_code: diagnosisDetails,
      details: services,
    };

    try {
      const response = await doPost(`/superbill/create-bill/${id}/`, data);
      if (response.status === 201) {
        Notification.success("Superbill Created Successfully");
        handleCancel();
        getSuperbill(id);
      }
    } catch (error) {
      Notification.error("Failed to create Superbill: " + error.message);
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      title="Create Superbill"
      footer={null}
      width={950}
      onCancel={handleCancel}
      maskClosable={false}
      closeIcon={
        <Button size="small" className="app-close-icon" shape="round">
          Close
        </Button>
      }
    >
      <Form form={form} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="Practice Information"
              name={"office_info"}
              initialValue={formattedPractiseInfo}
            >
              <Input.TextArea placeholder="Practice Information" rows={5} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Provider Information" name={"provider-info"}>
              <AutoComplete
                name="diagnosisCode"
                onSearch={handleSearchProviderInfo}
                options={providerInfo}
                allowClear
                onSelect={onSelectProvider}
                placeholder="Search Provider"
                maxLength={100}
                style={{ minWidth: "400px" }}
                defaultValue={null}
              />
            </Form.Item>
          </Col>
        </Row>
        <Card className="mb-4">
          <div className="flex justify-between">
            <div className="flex flex-col">
              <h4>Client Information</h4>
              <LabelDisplay
                title="Client Name"
                description={clientDetails?.clientName}
              />
              <LabelDisplay
                title="Phone"
                description={clientDetails?.patient_phone}
              />
              <LabelDisplay
                title="DOB"
                description={<FormatDate date={clientDetails?.date_of_birth} />}
              />

              <Form.Item
                name={"prior_auth_number"}
                label="Prior Authorization Number"
              >
                <Input
                  placeholder="Prior Authorization Number"
                  style={{ width: "250px" }}
                />
              </Form.Item>
            </div>

            {providerDetails && (
              <div className="p-2">
                <LabelDisplay
                  title="Provider Name"
                  description={providerDetails[0]?.provider_name}
                />
                <LabelDisplay
                  title="License"
                  description={providerDetails[0]?.license || "N/A"}
                />
                <LabelDisplay
                  title="Tax ID"
                  description={providerDetails[0]?.tax_id || "N/A"}
                />
                <LabelDisplay
                  title="Phone"
                  description={providerDetails[0]?.phone || "N/A"}
                />
              </div>
            )}
            {providerDetails && (
              <div>
                <LabelDisplay
                  title="Date l"
                  description={providerDetails[0]?.additional_data_1 || "N/A"}
                />
                <LabelDisplay
                  title="Data ll"
                  description={providerDetails[0]?.additional_data_2 || "N/A"}
                />
                <LabelDisplay
                  title="Data lll"
                  description={providerDetails[0]?.additional_data_3 || "N/A"}
                />
              </div>
            )}
          </div>
        </Card>

        <Card title="Add Diagnosis Codes">
          <div className="flex justify-between items-center">
            <Form.Item label="Search Patient Diagnosis" name="diagnosisCode">
              <AutoComplete
                name="diagnosisCode"
                onSearch={handleSearchDiagnosis}
                options={options}
                allowClear
                onSelect={onSelect}
                placeholder="Search Diagnosis Code"
                maxLength={100}
                style={{ minWidth: "400px" }}
                defaultValue={null}
              />
            </Form.Item>
          </div>
          <Table
            size="small"
            columns={diagnosisColumn}
            dataSource={diagnosisDetails}
            pagination={false}
          />
        </Card>

        <Card title="Add Services" className="mt-4">
          <Row gutter={[16, 16]}>
            <Col xs={24} md={6} lg={6}>
                <Form.Item label="Date of Service" name="date"
                 rules={[rules]}
                >
                    <BaseDatePicker/>
                </Form.Item>
            </Col>
            <Col xs={24} md={6} lg={6}> 
              <Form.Item
                label="Facility Code"
                name={"pos"}
                rules={[rules]}
                initialValue={facilty_code && facilty_code[0]}
              >
                <Select
                  placeholder="Select a facility"
                  showSearch
                  onSearch={handleSearchFacilityCodes}
                  filterOption={false}
                >
                  {memoizedFilteredOptions?.map((option) => (
                    <Option key={option.code} value={option.code}>
                      <span title={option.description}>
                        {option.code} - {option.service_name}
                      </span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={6} lg={6}>
              <Form.Item
                label="Search Procedure Code"
                name={"procedureCodes"}
                rules={[rules]}
              >
                <AutoComplete
                  onSearch={handleSearchCPTCodes}
                  options={CPTCodesOptions}
                  onChange={handleCPTCode}
                  placeholder="Search CPT Codes"
                  maxLength={100}
                  allowClear
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={6} lg={6}>
              <Form.Item label="DX" name={"dx_value"} rules={[rules]}>
                <Select
                  allowClear
                  mode="multiple"
                  placeholder="Select DX"
                  onChange={handleSelectDX}
                  value={selectedDiagnosisIds}
                >
                  {diagnosisDetails &&
                    diagnosisDetails.map((item) => (
                      <Select.Option key={item.dx_link} value={item.dx_link}>
                        {item.dx_link} - {item.dx}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={12} md={6} lg={6}>
              <Form.Item
                label="Search Service Type"
                name={"service_type"}
                rules={[rules]}
              >
                <AutoComplete
                  onSearch={handleSearchServices}
                  options={serviceTypeOptions}
                  //   onChange={handleCPTCode}
                  placeholder="Search Service Type"
                  maxLength={100}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <Form.Item
                label="Modifier"
                name={"modifier"}
                tooltip="Seprate each Modifier by pressing Return on your keyboard"
              >
                <Select
                  mode="tags"
                  style={{
                    width: "100%",
                  }}
                  onChange={handleChange}
                  tokenSeparators={[","]}
                />
              </Form.Item>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <Form.Item
                label="Unit"
                name={"unit"}
                rules={[rules, { validator: validateNumber }]}
              >
                <Input placeholder="Unit" maxLength={10} />
              </Form.Item>
            </Col>

            <Col xs={12} md={6} lg={6}>
              <Form.Item
                label="Paid"
                name={"paid"}
                rules={[{ validator: validatePrice }]}
              >
                <Input placeholder="Paid" maxLength={15} />
              </Form.Item>
            </Col>
          </Row>

          <div className="flex justify-end mb-2">
            <Button type="primary" onClick={handleAddService}>
              Add Service
            </Button>
          </div>
          <Table
            size="small"
            columns={servicesColumn}
            dataSource={services}
            pagination={false}
          />
        </Card>

        <div className="flex justify-between items-center mt-4">
          <Button onClick={handleCancel}>Cancel</Button>
          <div>
            <Button
              type="primary"
              className="ml-2"
              onClick={handleSuperbill}
              loading={submitLoading}
            >
              Create Superbill
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default SuperbillDrawer;
