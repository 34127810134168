import React from "react";
import { Button, Row } from "antd";
import RecentPatients from "./component/RecentPatients";
import RecentEncounters from "./component/RecentEncounters";
import { useNavigate } from "react-router-dom";
import { useStateGlobalContext } from "../../../contexts/GlobalContext";

const App = () => {
  document.title = "Charts | American EMR";
  const { profile } = useStateGlobalContext();

  const role = profile && profile?.user_info[0]?.role;

  const navigate = useNavigate();

  const handleRegisterPatient = () => {
    navigate(`/main/register_patient/`);
  };
  return (
    <div>
      <div className="flex justify-between">
        <h1 className="font-bold text-lg mb-4">Charts</h1>
        {role !== "biller" && (
          <Button type="primary" onClick={handleRegisterPatient}>
            Register New
          </Button>
        )}
      </div>
      <Row gutter={[16, 16]}>
        <RecentEncounters />
        <RecentPatients />
      </Row>
    </div>
  );
};

export default App;
