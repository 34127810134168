import React, { useState } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
  Card,
  Space,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import bgimage from "../../../../assets/Pad.png";
import { validateNumber } from "../../../../utils/validator";
import { doPost } from "../../../../API/apis";
import { useParams } from "react-router-dom";
import {
  useSearchDiagnosis,
  useSearchMedicines,
} from "../../../../API/apiUtils";
import BaseDatePicker from "../../../../components/BaseDatePicker/BaseDatePicker";
import Notification from "../../../../components/Notification/Notification";
import showConfirmModal from "../../../../components/ModalConfirm/ModalConfirm";

function CreateNewPrescription() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false)
  const [medicineList, setMedicineList] = useState([]);
  const [edit, setEdit] = useState(false);
  const [medicine, setMedicine] = useState();
  const [editingItemKey, setEditingItemKey] = useState(null);
  const { searchMedicine: options, handleSearchMedicines } =
    useSearchMedicines();
  const { diagnosisOptions, handleSearchDiagnosis } = useSearchDiagnosis(true);

  const onSelect = (value, option) => {
    const selectedMedicine = options.find((item) => item.value === value);
    if (selectedMedicine) {
      setMedicine(selectedMedicine.value);
    }
  };

  const createPrescription = (values) => {
    const newMedicine = {
      ...values,
      key: Date.now(),
    };
    setMedicineList([...medicineList, newMedicine]);

    form.resetFields();
    setEdit(false);
  };

  const handleEdit = (item) => {
    form.setFieldsValue({
      searched_medicine: item.searched_medicine,
      instructions: item.instructions,
      dispense_quantity: item.dispense_quantity,
      start_date: item.start_date,
      end_date: item.end_date,
      refills: item.refills,
      dispense_as_written: item.dispense_as_written,
      associated_diagnosis: item.associated_diagnosis,
    });

    setEdit(true);
    setEditingItemKey(item.key);
  };

  const handleUpdate = (values) => {
    const updatedMedicineList = medicineList.map((item) =>
      item.key === editingItemKey ? { ...item, ...values } : item
    );

    setMedicineList(updatedMedicineList);
    form.resetFields();
    setEdit(false);
    setEditingItemKey(null);
  };

  const handleDelete = (item) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        const updatedMedicineList = medicineList.filter(
          (med) => med.key !== item.key
        );
        setMedicineList(updatedMedicineList);
      },
    });
  };

  const handleClear = () => {
    showConfirmModal({
      title: "Are you sure you want to delete this item?",
      icon: <DeleteOutlined />,
      content: "This action cannot be undone.",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        setMedicineList([]);
      },
    });
  };

  const handleFormClear = () => {
    setEdit(false);
    form.resetFields();
  };

  const handlePrescription = async () => {
    setLoading(true)
    const data = {
      prescriptions: medicineList,
    };
    try {
      const response = await doPost(`/prescriptions/add/${id}/`, data);
      if (response.status === 201) {
        Notification.success("Prescription created successfully");
        setMedicineList([]);
      }
    } catch (error) {
      Notification.error("Something went Wrong");
    } finally{
      setLoading(false)
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col xl={12} lg={24} sm={24} md={24}>
        <Card className="shadow-class">
          <Form
            layout="vertical"
            name="create-prescription"
            form={form}
            onFinish={edit ? handleUpdate : createPrescription}
          >
            <Form.Item
              label="Search Medicine"
              name="searched_medicine"
              tooltip="B = Brand and G = Generic"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <AutoComplete
                options={options}
                allowClear={true}
                onSelect={onSelect}
                onSearch={handleSearchMedicines}
                placeholder="Search by brand name or add custom"
              />
            </Form.Item>

            <Form.Item name="instructions" label="Instructions">
              <Input.TextArea
                placeholder="Instructions"
                rows={3}
                maxLength={500}
                showCount
              />
            </Form.Item>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="dispense_quantity"
                  label="Dispense Quantity"
                  rules={[{ validator: validateNumber }]}
                >
                  <Input placeholder="Dispense Quantity" maxLength={5} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="refills"
                  label="Refills"
                  rules={[{ validator: validateNumber }]}
                >
                  <Input placeholder="Refills" width="200px" maxLength={5} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col>
                <Form.Item
                  name="start_date"
                  label="Start Date"
                  value={form.getFieldValue("start_date")}
                >
                  <BaseDatePicker />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="end_date" 
                  label="End Date"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const startDate = getFieldValue("start_date");
                        if (!value || !startDate || value >= startDate) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("End Date cannot be before than Start Date")
                        );
                      },
                    }),
                  ]}
                >
                  <BaseDatePicker />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="dispense_as_written" valuePropName="checked">
              <Checkbox>Dispense as Written</Checkbox>
            </Form.Item>
            <Form.Item name="associated_diagnosis" label="Associated Diagnosis">
              <AutoComplete
                onSearch={handleSearchDiagnosis}
                options={diagnosisOptions}
                placeholder="Search Associated Diagnosis"
                maxLength={300}
              />
            </Form.Item>

            <div className="flex justify-end">
              <Button onClick={handleFormClear} className="ml-2">
                Clear
              </Button>
              <Button
                type={
                  !form.getFieldValue("searched_medicine")
                    ? "default"
                    : "primary"
                }
                htmlType="submit"
                className="ml-2"
                // disabled={!form.getFieldValue("searched_medicine")}
              >
                {edit ? "Update Medicine" : "Add to List"}
              </Button>
            </div>
          </Form>
        </Card>
      </Col>

      <Col xl={12} lg={24} sm={24} md={24}>
        <Card
          className="shadow-class min-h-[610px]"
          title="Digital Prescription Pad"
          extra={
            <Space>
            {medicineList.length !== 0 && (
              <Button type="link" onClick={handleClear}>
                Clear All
              </Button>
            )}
          </Space>
          }
          style={{
            backgroundImage: `url(${bgimage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="flex justify-between">
            {medicineList.length !== 0 && (
              <Button type="link" onClick={handleClear}>
                Clear All
              </Button>
            )}
          </div>

          <div className="mb-2">
            <ul>
              {medicineList.map((item) => (
                <li key={item.key} className="flex justify-between">
                  <span>{item.searched_medicine}</span>
                  <div>
                    <EditOutlined
                      className="text-blue-400"
                      onClick={() => {
                        handleEdit(item);
                      }}
                    />
                    <DeleteOutlined
                      className="ml-2 mr-4 text-red-600"
                      onClick={() => {
                        handleDelete(item);
                      }}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div className="absolute right-6 bottom-6">
            <Button
              loading={loading}
              type={medicineList.length === 0 || loading ? "default" : "primary"}
              onClick={handlePrescription}
              disabled={medicineList.length === 0}
              className={medicineList.length === 0 ? "bg-gray-300" : ""}
            >
              Sign and Prescribe
            </Button>
          </div>
        </Card>
      </Col>
    </Row>
  );
}

export default CreateNewPrescription;
