import {  DeleteOutlined, EditOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, Spin, Row, Col } from "antd";
import { doPost, doGet, doDelete, doPatch } from "../../../../../API/apis";
import { useParams } from "react-router-dom";
import LabelDisplay from "../../../../../components/LabelDisplay/LabelDisplay";
import BaseDatePicker from "../../../../../components/BaseDatePicker/BaseDatePicker";
import MedicalCardsTitle from "../MedicalCardsTitle";
import showConfirmModal from "../../../../../components/ModalConfirm/ModalConfirm";
import Notification from "../../../../../components/Notification/Notification";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";
import FormatDate from "../../../../../components/FormatDate/FormatDate";
import { AddButton } from "../../../../../components/GlobalComponents/GlobalComponents";

function VaccinationHistory() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [vaccinationHistoryModalVisible, setVaccinationHistoryModalVisible] =
    useState(false);

  const disabledDate = (current) => {
    return current && current > new Date();
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const openModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
    setVaccinationHistoryModalVisible(false);
    setEditMode(false);
    form.resetFields();
  };

  const handleItemClick = (record) => {
    setSelectedItem(record);
    setVaccinationHistoryModalVisible(true);
  };

  const handleEdit = (record) => {
    setEditMode(true);
    setSelectedItem(record);
    form.setFieldsValue({
      vaccine: record.vaccination_history,
      vaccination_date: dayjs(record.vaccination_date),
    });
    setVisible(true);
  };

  const handleDelete = (record) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        deleteVaccinationHistory(record);
      },
    });
  };

  const deleteVaccinationHistory = async (record) => {
    try {
      const response = await doDelete(
        `/patient/delete-vaccination-history/${id}/${record}/`
      );
      if (response.status === 200) {
        Notification.success("Vaccination history deleted successfully");
        getVaccinationHistory();
      } else {
        Notification.error("Something went wrong");
      }
    } catch (error) {
      Notification.error("Something went wrong");
    }
  };

  const handleVaccinationHistory = async (values) => {
    setLoading(true);
    const data = {
      vaccination_history: values.vaccine,
      vaccination_date: values.vaccination_date
        ? values.vaccination_date
        : new Date(),
    };
    try {
      const response = editMode
        ? await doPatch(
            `/patient/update-vaccination-history/${id}/${selectedItem?.id}/`,
            data
          )
        : await doPost(`/patient/add-vaccination-history/${id}/`, data);
      if (response.status === 200 || response.status === 201) {
        const successMessage = editMode
          ? "Vaccination History updated successfully"
          : "Vaccination history added successfully";
        Notification.success(successMessage);
        getVaccinationHistory();
        handleCancel();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getVaccinationHistory = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/patient/get-vaccination-history/${id}/`);
      if (response.status === 200) {
        setData(response.data.reverse());
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getVaccinationHistory();
  }, []);

  return (
    <div>
      <div className="shadow-class p-4 border rounded-md  h-[180px]  max-h-[180px]">
        <div className="flex items-center justify-between">
          <MedicalCardsTitle title="Vaccination History" />
          <AddButton openModal={openModal}/>
        </div>
        {loading ? (
          <div className="flex justify-center items-center">
            <Spin size="small" />
          </div>
        ) : (
          <div className="flex max-h-[130px]  overflow-auto mt-1">
            <ul className="list-decimal">
              {data.map((item, index) => (
                <li
                  key={item.id}
                  className="text-[#1890ff] cursor-pointer flex justify-between items-center"
                  onMouseEnter={() => setHoveredItemId(item?.id)}
                  onMouseLeave={() => setHoveredItemId(null)}
                >
                  <span
                    onClick={() => {
                      handleItemClick(item);
                    }}
                  >
                    {index + 1}. {item?.vaccination_history?.slice(0, 26)}
                    {item.vaccination_history?.length > 26 ? "..." : ""}
                  </span>
                  {hoveredItemId === item.id && (
                    <div className="flex items-center ml-3">
                      <EditOutlined
                        className="text-[#1890ff] mr-2"
                        onClick={() => {
                          handleEdit(item);
                        }}
                      />
                      <DeleteOutlined
                        className="text-[#1890ff]"
                        onClick={() => {
                          handleDelete(item.id);
                        }}
                      />
                    </div>
                  )}
                </li>
              ))}
              {data.length === 0 && (
                <li className="flex justify-between items-center">
                  <span>No past vaccination history</span>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>

      <Modal
        title="Vaccination History"
        open={visible}
        onCancel={handleCancel}
        maskClosable={false}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
        footer={null}
        width={500}
      >
        <Form
          layout="vertical"
          name="vaccination-history"
          form={form}
          onFinish={handleVaccinationHistory}
        >
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item name="vaccination_date" label="Vaccination Date">
                <BaseDatePicker
                  value={
                    editMode ? selectedItem?.vaccination_date : selectedDate
                  }
                  onChange={handleDateChange}
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name="vaccine"
                label="Vaccine Name"
                rules={[
                  { required: true, message: "Please enter vaccine name" },
                ]}
              >
                <Input maxLength={30} placeholder="Vaccine Name" />
              </Form.Item>
            </Col>
          </Row>
          <div className="flex justify-end">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button
              type="primary"
              htmlType="submit"
              className="ml-2"
              loading={loading}
            >
              {editMode ? "Update" : "Add"}
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        title="Vaccination History"
        open={vaccinationHistoryModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={650}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
      >
        {selectedItem && (
          <>
            <LabelDisplay
              title="Vaccination Date"
              description={<FormatDate date={selectedItem?.vaccination_date} />}
            />
            <LabelDisplay
              title="Vaccine Name"
              description={selectedItem?.vaccination_history || "N/A"}
            />
            <LabelDisplay
              title="Added By"
              description={selectedItem?.added_by || "N/A"}
            />
            <LabelDisplay
              title="Date"
              description={
                <FormatDateTime
                  date={selectedItem?.date_time}
                  type="datetime"
                />
              }
            />
          </>
        )}
      </Modal>
    </div>
  );
}

export default VaccinationHistory;
