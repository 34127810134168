import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  AutoComplete,
  Popconfirm,
  Select,
  Empty,
  Row,
  Col,
  Card,
  Dropdown,
} from "antd";
import { EllipsisOutlined, WarningOutlined } from "@ant-design/icons";
import { doGet, doPost, doPatch, doDelete } from "../../../../../API/apis";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import "./patientmembership.css";
import Notification from "../../../../../components/Notification/Notification";
import showConfirmModal from "../../../../../components/ModalConfirm/ModalConfirm";
import FormatDate from "../../../../../components/FormatDate/FormatDate";
import Loader from "../../../../../components/Loader/Loader";
import ViewMembership from "./ViewMembership";
import { NumberFormatter } from "../../../../../utils/validator";

const { Option } = Select;

const items = [
  {
    key: 1,
    label: "View",
  },
  {
    key: 2,
    label: "Edit",
  },
  {
    key: 3,
    label: "Delete",
  },
];

function PatientMembership() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [editRecord, SetEditRecord] = useState(null);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadLinkMembership, setLoadLinkMembership] = useState(false);
  const [visible, setVsibile] = useState(false);
  const [membershipID, setMembershipID] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [view, setView] = useState(null);
  const { patientBasicInfo, getPatientData } = useStateGlobalContext();

  const [patientMembership, setPatientMembership] = useState({
    visits: "",
    type: "",
    plan: "",
    group_plan: "",
  });

  const handleMembershipEnrollment = () => {
    setVsibile(true);
  };

  const handleCancel = () => {
    setVsibile(false);
    setEdit(false);
    form.resetFields();
    setPatientMembership("");
    SetEditRecord(null);
  };

  const onSelect = (value, option) => {
    const selectedMembership = options.find((item) => item.value === value);
    if (selectedMembership) {
      setPatientMembership({
        visits: selectedMembership.visits,
        months: selectedMembership.months,
        price: selectedMembership.price,
        group_plan: selectedMembership.group_plan,
      });
    }
  };

  const handleSearch = async (searchText) => {
    try {
      const response = await doGet(
        `/membership/search-memberships/?q=${searchText}`
      );
      if (response.status === 200) {
        const uniqueOptions = response.data.map((item, idx) => ({
          id: item?.id,
          value: item?.plans,
          visits: item?.visits,
          price: item?.price,
          months: item?.months,
          group_plan: item?.group_plan,
        }));
        setOptions(uniqueOptions);
      } else {
        setOptions([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEdit = (record) => {
    setSelectedItem(record);
    setEdit(true);
    SetEditRecord(record);
    form.setFieldsValue({
      membership_plan: record?.plans,
      visits: record?.visits,
      months: record?.time_left,
      price: record?.price,
      secondary_member: record?.connection_MID,
      membership_notes: record?.notes,
    });
    setVsibile(true);
  };

  const handlePatientMembership = async (values) => {
    const data = {
      plans: values.membership_plan,
      dob: patientBasicInfo?.date_of_birth,
      visits: patientMembership.visits || editRecord?.visits,
      time_left: patientMembership.months || editRecord?.month,
      price: patientMembership.price || editRecord?.price,
      connection_MID: values.secondary_member || editRecord?.connection_MID,
      notes: values.membership_notes || editRecord?.notes,
      group_plan: patientMembership?.group_plan || editRecord?.group_plan,
    };
    try {
      const response = edit
        ? await doPatch(
            `/membership/update-patient-membership/${id}/${selectedItem?.id}/`,
            data
          )
        : await doPost(`/membership/create/${id}/`, data);
      if (response.status === 201 || response.status === 200) {
        const successMessage = edit
          ? "Patient Membership updated successfully"
          : "Patient Membership created successfully";
        Notification.success(successMessage);
        handleCancel();
        getPatientMembership();
      }
    } catch (error) {
      console.error("Something Went Wrong", error);
    }
  };

  const getPatientMembership = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/membership/get-patient-membership/${id}/`);
      if (response.status === 200) {
        const sortedMembership = response?.data?.sort(
          (a, b) => new Date(b.date_added) - new Date(a.date_added)
        );
        setData(sortedMembership);
      }
    } catch (error) {
      Notification.error("Something Went Wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleVisits = async (record) => {
    if (record.visits === 0) {
      return Notification.error("Patient Visits are Over");
    }
    const data = {
      visits: record?.visits - 1,
    };
    try {
      const response = await doPatch(
        `/membership/update-patient-membership/${id}/${record?.id}/`,
        data
      );
      if (response.status === 200) {
        Notification.success("Membership Utilized Successfully");
        getPatientMembership();
      }
    } catch (error) {
      Notification.error("Something Went Wrong");
    }
  };

  const handleDelete = (record) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        deleteMembership(record);
      },
    });
  };

  const deleteMembership = async (record) => {
    try {
      const response = await doDelete(
        `/membership/delete-patient-membership/${record?.id}/${id}/`
      );
      if (response.status === 200) {
        Notification.success("Payment Membership deleted successfully");
        getPatientMembership(id);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getMembershipID = async (value = "") => {
    setLoadLinkMembership(true);
    try {
      const response = await doGet(`/membership/get-mids/?search_on=${value}`);
      if (response.status === 200) {
        setMembershipID(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadLinkMembership(false);
    }
  };

  const handleMenuClick = (key, record) => {
    if (key === "1") {
      handleView(record);
    }
    if (key === "2") {
      handleEdit(record);
    } else if (key === "3") {
      handleDelete(record);
    }
  };

  const handleView = (record) => {
    setView({
      open: true,
      data: record,
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await Promise.all([getPatientMembership(), getPatientData(id)]);
      } catch (error) {
        Notification.error("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  if (loading) return <Loader />;

  return (
    <Card className="shadow-sm"
     title="Membership Plans and Packages"
     extra={
      <Button type="primary" onClick={handleMembershipEnrollment}>
      Add Membership
    </Button>
     }
    >
      {data && data?.length > 0 ? (
        <Row gutter={[16, 16]}>
          {data &&
            data?.map((membershipCard, idx) => (
              <Col xl={8} lg={12} sm={24} md={24} key={idx}>
                <Card
                  className="membership-card text-white bg-gradient-to-br from-green-400 via-green-600 to-green-700"
                  loading={loading}
                >
                  <div className="card-header">
                    <p className="font-medium">Membership Plan</p>
                    <div className="card-actions">
                      <Dropdown
                        menu={{
                          items:
                            membershipCard?.visits > 0 ? items : [items[1]],
                          onClick: ({ key }) =>
                            handleMenuClick(key, membershipCard),
                        }}
                        className="cursor-pointer"
                        placement="bottomRight"
                        trigger={["click"]}
                      >
                        <EllipsisOutlined />
                      </Dropdown>
                      {membershipCard && membershipCard?.visits > 0 && (
                        <>
                          <Popconfirm
                            title="Claim a Visit"
                            description="Are you sure you want to claim 1 Visit? You cannot undone this."
                            icon={<WarningOutlined />}
                            onConfirm={() => handleVisits(membershipCard)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button size="small" className="claim-button">
                              Claim
                            </Button>
                          </Popconfirm>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-[16px]">
                    <div>
                      <p>Plan: {membershipCard?.plans}</p>
                      <p>MID: {membershipCard?.MID_number}</p>
                      <p>Price: {<NumberFormatter value={membershipCard?.price}/>}</p>
                      <p>
                        Start Date:{" "}
                        {membershipCard?.start_date && (
                          <FormatDate date={membershipCard?.start_date} />
                        )}
                      </p>
                      <p>
                        End Date:{" "}
                        {membershipCard?.expiration_date && (
                          <FormatDate date={membershipCard?.expiration_date} />
                        )}
                      </p>
                      <p>
                        Status:{" "}
                        <span
                          className={`status-badge ${
                            membershipCard?.status ? "active" : "inactive"
                          }`}
                        >
                          {membershipCard?.status ? "Active" : "Inactive"}
                        </span>
                      </p>
                    </div>
                    <Card bordered={false} className="bg-green-300">
                      {/* <IoFootsteps size={36}/> */}
                      <p className="text-[42px] font-bold text-center mt-[-20px]">
                        {membershipCard?.visits}
                      </p>
                      <p className="text-center font-medium">Visits</p>
                      <p className="text-center font-medium">Left</p>
                    </Card>
                  </div>
                </Card>
              </Col>
            ))}
        </Row>
      ) : (
        <Empty description="No Membership Found" />
      )}

      <Modal
        width={476}
        open={visible}
        title={edit ? "Update Membership Enrollment" : "Membership Enrollment"}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
      >
        <Form layout="vertical" form={form} onFinish={handlePatientMembership}>
          <Form.Item
            name="membership_plan"
            label="Search Plans"
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <AutoComplete
              options={options}
              allowClear
              onSelect={onSelect}
              onSearch={handleSearch}
              placeholder="Search Membership Plan"
              disabled={edit}
            />
          </Form.Item>
          <div className="flex justify-between">
            <Form.Item label="Visits">
              <InputNumber
                placeholder="Visits"
                value={patientMembership.visits || editRecord?.visits}
                disabled
              />
            </Form.Item>
            <Form.Item label="Months">
              <InputNumber
                placeholder="Months"
                value={patientMembership.months || editRecord?.time_left}
                disabled
              />
            </Form.Item>
            <Form.Item label="Price">
              <InputNumber
                placeholder="Price"
                value={patientMembership.price || editRecord?.price}
                disabled
              />
            </Form.Item>
          </div>

          <Form.Item
            name="secondary_member"
            label="Link with Primary Member (MID)"
            tooltip="Primary Member is the one who pays for the membership"
          >
            <Select
              showSearch
              placeholder="Select MID"
              onSearch={getMembershipID}
              disabled={patientMembership?.group_plan === "No" || edit}
              allowClear
            >
              {membershipID &&
                membershipID?.map((item) => (
                  <Option key={item?.id} value={item?.MID_number}>
                    <p>{item?.MID_number}</p>
                    <p>{item?.name}</p>
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item name="membership_notes" label="Notes">
            <Input.TextArea
              placeholder="Notes"
              maxLength={300}
              rows={4}
              showCount
            />
          </Form.Item>
          <div className="flex justify-end">
            <Button className="mr-2" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              {edit ? "Update" : "Enroll"}
            </Button>
          </div>
        </Form>
      </Modal>

      {view?.open && (
        <ViewMembership
          open={view?.open}
          data={view?.data}
          onClose={()=>setView({
            open: false,
            data: null,
          })}
        />
      )}
    </Card>
  );
}

export default PatientMembership;
