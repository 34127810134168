import React from "react";
import { Card } from "antd";
import {
  DollarOutlined,
  WalletOutlined,
  CheckCircleOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";
import { NumberFormatter } from "../../utils/validator";

const ReportsCards = ({ rxData, title, stats }) => {

  const calculateTotalRevenue = (rxData) =>
    rxData
      ?.filter((item) => item.status.toLowerCase() === "paid")
      .reduce(
        (sum, item) =>
          sum +
          (title === "Services"
            ? item.grand_total_amount
            : item?.grand_total || 0),
        0
      );

  const calculateTotalItems = (rxData) => rxData?.length;
  const calculateTotalItemsPaid = (rxData) =>
    rxData?.filter((item) => item.status.toLowerCase() === "paid").length;

  const calculateTotalItemsUnpaid = (rxData) =>
    rxData?.filter((item) => item.status.toLowerCase() === "unpaid").length;

  const calculateSent = (stats) =>
    stats?.filter((item) => item.status === true).length;

  const calculateNotSent = (stats) =>
    stats?.filter((item) => item.status === false).length;

  const data =
    title?.toLowerCase() === "labs"
      ? [
          {
            title: "Total Revenue",
            value: <NumberFormatter value={calculateTotalRevenue(rxData)} />,
            icon: <DollarOutlined className="text-2xl text-white" />,
          },
          {
            title: "Total Labs",
            value: calculateTotalItems(rxData),
            icon: <AppstoreOutlined className="text-2xl text-white" />,
          },
          {
            title: "Total Labs Paid",
            value: calculateTotalItemsPaid(rxData),
            icon: <CheckCircleOutlined className="text-2xl text-white" />,
          },
          {
            title: "Total Labs Unpaid",
            value: calculateTotalItemsUnpaid(rxData),
            icon: <WalletOutlined className="text-2xl text-white" />,
          },
        ]
      : title?.toLowerCase() === "superbill"
      ? [
          {
            title: "Total Superbill Created",
            value: stats?.length,
          },
          {
            title: "Total Superbill Sent",
            value: calculateSent(stats),
          },
          {
            title: "Total Superbill Unsent",
            value: calculateNotSent(stats),
          },
        ]
      : [
          {
            title: "Total Revenue",
            value: <NumberFormatter value={calculateTotalRevenue(rxData)} />,
            icon: <DollarOutlined className="text-2xl text-white" />,
          },
          {
            title: "Total Services",
            value: calculateTotalItems(rxData),
            icon: <AppstoreOutlined className="text-2xl text-white" />,
          },
          {
            title: "Total Services Paid",
            value: calculateTotalItemsPaid(rxData),
            icon: <CheckCircleOutlined className="text-2xl text-white" />,
          },
          {
            title: "Total Services Unpaid",
            value: calculateTotalItemsUnpaid(rxData),
            icon: <WalletOutlined className="text-2xl text-white" />,
          },
        ];

  return (
    <div
      className={`grid grid-cols-1 sm:grid-cols-2 ${title?.toLowerCase() === "superbill" ? "lg:grid-cols-3" : "lg:grid-cols-4"} gap-4 mb-4 mt-4`}
    >
      {data.map((item, index) => (
        <Card
          key={index}
          className="shadow-md rounded-lg bg-gradient-to-r from-blue-200 via-blue-50 to-blue-100 text-neutral-900 p-1"
          hoverable
        >
          <div className="flex items-center space-x-4">
            <div>
              <h3 className="text-lg font-semibold">{item.title}</h3>
              <p className="text-2xl font-bold">{item.value}</p>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default ReportsCards;
