import React, { useState, useEffect } from "react";
import { Modal, Tabs, Table, Button, message } from "antd";
import { doGet } from "../../../../../API/apis";
import { useParams } from "react-router-dom";
import ViewGraph from "./ViewGraph";
import BasicVitals from "./BasicVitals";
import BMICalculator from "./BMICalculator";
import BodyFatCalculator from "./BodyFatCalculator";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import IdealWeightCalculator from "./IdealWeightCalculator";
import MedicalCardsTitle from "../MedicalCardsTitle";
import { basicVitalsColumn, BMIColumn, BodyFatColumn, IdealWeightColumn } from "../../../../../components/TableColumns/TableColumns";
import dayjs from "dayjs";

const { TabPane } = Tabs;
const TABLE_WIDTH = 800;

export const handleCopyVitals = (record) => {
  const dataToCopy = `
    Blood Pressure: ${record.blood_pressure || "N/A"}
    Body Temperature: ${record.body_temp || "N/A"}
    Height: ${record.height || "N/A"}
    Weight: ${record.weight || "N/A"}
    Heart Rate: ${record.heart_rate || "N/A"}
    O2 Saturation: ${record.oxygen || "N/A"}
    Respiratory Rate: ${record?.resp_rate || "N/A"}
    Pain Scale: ${record?.pain_scale || "N/A"}
  `;
  navigator.clipboard.writeText(dataToCopy)
    .then(() => {
       message.success("Copied to clipboard");
    })
    .catch((err) => {
      message.error("Failed to copy to clipboard");
    });
};

const CustomModal = ({ title, open, onCancel, data, columns, loading, width }) => {
  return (
    <Modal
      title={title}
      open={open}
      onCancel={onCancel}
      closeIcon={
        <Button size="small" className="app-close-icon" shape="round">
          Close
        </Button>
      }
      footer={null}
      width={width || 900}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab="Table" key="1">
          <Table
            loading={loading}
            size="small"
            dataSource={data}
            columns={columns}
            scroll={{ x: TABLE_WIDTH }}
            pagination={false}
          />
        </TabPane>
        <TabPane tab="Graph" key="2">
          <ViewGraph data={data} loading={loading} title={title} />
        </TabPane>
      </Tabs>
    </Modal>
  );
};

function VitalSigns() {
  const { id } = useParams();
  const [BMIData, setBMIData] = useState([]);
  const [visible, setVisible] = useState({
    open: false,
    modalOpen: null,
  });
  const [loading, setLoading] = useState(false);
  const [bodyFatData, setBodyFatData] = useState([]);
  const [idealWeightData, setIdealWeightData] = useState([]);
  const [basicVitalsData, setBasicVitalsData] = useState([]);
  const [isBMIModalVisible, setIsBMIModalVisible] = useState(false);
  const { officeSetting, getOfficeSettings } = useStateGlobalContext();
  const [isBasicModalVisible, setIsBasicModalVisible] = useState(false);
  const [isBodyFatModalVisible, setIsBodyFatModalVisible] = useState(false);
  const [isIdealWeightModalVisible, setIsIdealWeightModalVisible] =
    useState(false);
  const metric = officeSetting?.selected_unit === "metric" ? true : false;

  useEffect(() => {
    getOfficeSettings();
  }, []);

  const handleAdd = (vitalSign) => {
    setVisible({
      open: true,
      modalOpen: vitalSign,
    });
  };

  const fetchData = async (endpoint, setData, setModalVisible) => {
    setModalVisible(true);
    setLoading(true);
    try {
      const response = await doGet(endpoint);
      if (response.status === 200) {
        setData(response.data.reverse());
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  
  // Handlers using the reusable function
  const handleBasicModal = () => {
    fetchData(`/patient/get-vitals/${id}/`, setBasicVitalsData, setIsBasicModalVisible);
  };
  
  const handleBMIModal = () => {
    fetchData(`/patient/get-bmi/${id}/`, setBMIData, setIsBMIModalVisible);
  };
  
  const handleIdealWeightModal = () => {
    fetchData(`/patient/get-ideal-weight/${id}/`, setIdealWeightData, setIsIdealWeightModalVisible);
  };
  
  const getBodyFat = () => {
    fetchData(`/patient/get-bodyfat/${id}/`, setBodyFatData, setIsBodyFatModalVisible);
  };
  

  const handleCancel = () => {
    setVisible({
      open: false,
      modalOpen: null,
    });
  };

  const handleSave = () => {
    setVisible(false);
  };

  const VitalsButton = ({ vitalSign }) => (
    <Button
      type="primary"
      shape="round"
      size="small"
      className="ml-2"
      onClick={() => handleAdd(vitalSign)}
    >
      +
    </Button>
  );

  const ShowVital = ({ showDetails, title }) => (
    <p
      className="text-[#1890ff] cursor-pointer"
      onClick={showDetails}
      style={{ marginLeft: "8px" }}
    >
      {title}
    </p>
  );

  return (
    <div>
      <div className="shadow-class p-4 border rounded-md h-[180px]  max-h-[180px]">
        <div className="flex items-center justify-between">
          <MedicalCardsTitle title="Vital Signs" />
        </div>
        <div className="flex flex-col align-middle leading-8 mt-2 gap-4">
          <div className="flex justify-between">
            <div className="flex items-center">
              <ShowVital showDetails={handleBasicModal} title="Basic Vitals" />
              <VitalsButton vitalSign={"1"} />
            </div>
            <div className="flex items-center">
              <ShowVital showDetails={getBodyFat} title="Body Fat Calculator" />
              <VitalsButton vitalSign={"2"} />
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex items-center">
              <ShowVital showDetails={handleBMIModal} title="BMI Calculator" />
              <VitalsButton vitalSign={"3"} />
            </div>
            <div className="flex items-center">
              <ShowVital
                showDetails={handleIdealWeightModal}
                title="Ideal Weight Calculator"
              />
              <VitalsButton vitalSign={"4"} />
            </div>
          </div>
        </div>
        <Modal
          title={
            visible?.modalOpen === "1"
              ? "Basic Vitals"
              : visible?.modalOpen === "2"
              ? "Body Fat Calculator"
              : visible?.modalOpen === "3"
              ? "BMI Calculator"
              : "Ideal Weight Calculator"
          }
          open={visible?.open}
          onCancel={handleCancel}
          maskClosable={false}
          closeIcon={
            <Button size="small" className="app-close-icon" shape="round">
              Close
            </Button>
          }
          footer={null}
          width={750}
        >
          {visible?.modalOpen === "1" ? (
            <BasicVitals
              onSave={handleSave}
              onCancel={handleCancel}
              metric={metric}
            />
          ) : visible?.modalOpen === "2" ? (
            <BodyFatCalculator
              onSave={handleSave}
              onCancel={handleCancel}
              metric={metric}
            />
          ) : visible?.modalOpen === "3" ? (
            <BMICalculator
              onSave={handleSave}
              onCancel={handleCancel}
              metric={metric}
            />
          ) : (
            <IdealWeightCalculator
              onSave={handleSave}
              onCancel={handleCancel}
              metric={metric}
            />
          )}
        </Modal>
        <CustomModal
          title="Basic Vitals"
          open={isBasicModalVisible}
          onCancel={() => setIsBasicModalVisible(false)}
          data={basicVitalsData}
          columns={basicVitalsColumn}
          loading={loading}
          width={900}
        />
        <CustomModal
          title="BMI"
          open={isBMIModalVisible}
          onCancel={() => setIsBMIModalVisible(false)}
          data={BMIData}
          columns={BMIColumn}
          loading={loading}
        />
        <CustomModal
          title="Body Fat Percentage"
          open={isBodyFatModalVisible}
          onCancel={() => setIsBodyFatModalVisible(false)}
          data={bodyFatData}
          columns={BodyFatColumn}
          loading={loading}
        />
        <CustomModal
          title="Ideal Weight"
          open={isIdealWeightModalVisible}
          onCancel={() => setIsIdealWeightModalVisible(false)}
          data={idealWeightData}
          columns={IdealWeightColumn}
          loading={loading}
        />
      </div>
    </div>
  );
}

export default VitalSigns;
