
export const calculateGrandTotalSum = (data) => {
    return data?.reduce((sum, item) => {
      if (item.status === 'Paid') {
        return sum + (item?.price || item?.total_price || 0);
      }
      return sum;
    }, 0);
  }

  export const showProducts = (products) => {
    if (!products || products.length === 0) {
      return null;
    }
  
    const productNames = products.map((product) => product.items).join(", ");
    return productNames;
  };
  
 export  const showQuantity = (products) => {
    if (!products || products.length === 0) {
      return null;
    }
  
    const productQuantity = products
      .map((product) => product.quantity)
      .join(", ");
    return productQuantity;
  };
  
 export const showUnitPrice = (products) => {
    if (!products || products.length === 0) {
      return null;
    }
  
    const productQuantity = products
      .map((product) => product?.unit_price)
      .join(", ");
    return productQuantity;
  };