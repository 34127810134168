import React, { useEffect, useState } from "react";
import { Card, Tooltip } from "antd";
import WelcomeCard from "../../../components/WelcomeCard/WelcomeCard";
import { useStateGlobalContext } from "../../../contexts/GlobalContext";
import { doGet } from "../../../API/apis";

const BillerDashboard = () => {
  const { profile } = useStateGlobalContext();
  const [stats, setStats] = useState(null);
  const [selectedSuperbillOption, setSelectedSuperbillOption] =
    useState("today");
  const [selectedUnsentOption, setSelectedUnsentOption] = useState("today");
  const [loading, setLoading] = useState(false);
  const provider_name =
    profile &&
    `${profile?.user_info[0]?.first_name} ${profile?.user_info[0]?.last_name}`;

  const getSuperbillStats = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/reports/superbill-stats/`);
      if (response.status === 200) {
        setStats(response?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSuperbillSelectChange = (option) => {
    setSelectedSuperbillOption(option);
  };

  const handleUnsentSelectChange = (option) => {
    setSelectedUnsentOption(option);
  };

  useEffect(() => {
    getSuperbillStats();
  }, []);

  return (
    <>
      <WelcomeCard
        title={"Biller Dashboard"}
        color={"border-[#C4dBFA]"}
        name={`Welcome Back, ${provider_name}`}
        provider_name={provider_name}
        profile_pic={profile?.personel_settings[0]?.user_profile_picture}
        work={true}
      />
      <div className="min-h-[700px] bg-gradient-to-br from-blue-50 to-indigo-100 flex flex-col justify-center items-center py-12">
        <Card
          className="shadow-xl w-full max-w-4xl"
          bodyStyle={{ padding: "3rem" }}
          bordered={false}
        >
          <div className="text-center">
            <h1 className="text-4xl font-semibold text-indigo-800 mb-6">
              Welcome, Medical Biller!
            </h1>
            <p className="text-gray-700 mb-8 text-lg">
              Stay organized and manage your billing efficiently within EMR
              system. Access key metrics, Superbill and manage claims for your
              medical practice.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Total Superbill Card */}
            <Card
              className="shadow-md rounded-lg bg-gradient-to-r from-blue-200 via-blue-50 to-blue-100 text-neutral-900 p-1"
              hoverable={true}
              loading={loading}
            >
              <div className="flex items-center justify-between space-x-4">
                <div>
                  <h3 className="text-lg font-semibold">
                    Total Superbill Created
                  </h3>
                  <p className="text-2xl font-bold">
                    {selectedSuperbillOption === "today"
                      ? stats?.superbills_today
                      : stats?.superbills_this_month}
                  </p>
                </div>
                <div className="flex flex-col gap-2">
                  <Tooltip title="Today">
                    <button
                      className={`text-xs px-2 py-1 border-none ${
                        selectedSuperbillOption === "today"
                          ? "bg-blue-500 text-white shadow"
                          : "bg-gray-200 text-gray-600"
                      } hover:bg-blue-400 transition`}
                      onClick={() => handleSuperbillSelectChange("today")}
                    >
                      T
                    </button>
                  </Tooltip>
                  <Tooltip title="This Month" placement="bottom">
                    <button
                      className={`text-xs px-2 py-1 border-none ${
                        selectedSuperbillOption === "month"
                          ? "bg-blue-500 text-white shadow"
                          : "bg-gray-200 text-gray-600"
                      } hover:bg-blue-400 transition`}
                      onClick={() => handleSuperbillSelectChange("month")}
                    >
                      M
                    </button>
                  </Tooltip>
                </div>
              </div>
            </Card>

            {/* Unsent Superbill Card */}
            <Card
              className="shadow-md rounded-lg bg-gradient-to-r from-blue-200 via-blue-50 to-blue-100 text-neutral-900 p-1"
              hoverable={true}
              loading={loading}
            >
              <div className="flex items-center justify-between space-x-4">
                <div>
                  <h3 className="text-lg font-semibold">Unsent Superbill</h3>
                  <p className="text-2xl font-bold">
                    {selectedUnsentOption === "today"
                      ? stats?.unsent_superbills_today
                      : stats?.unsent_superbills_this_month}
                  </p>
                </div>
                <div className="flex flex-col gap-2">
                  <Tooltip title="Today">
                    <button
                      className={`text-xs px-2 py-1 border-none ${
                        selectedUnsentOption === "today"
                          ? "bg-blue-500 text-white shadow"
                          : "bg-gray-200 text-gray-600"
                      } hover:bg-blue-400 transition`}
                      onClick={() => handleUnsentSelectChange("today")}
                    >
                      T
                    </button>
                  </Tooltip>
                  <Tooltip title="This Month" placement="bottom">
                    <button
                      className={`text-xs px-2 py-1 border-none ${
                        selectedUnsentOption === "month"
                          ? "bg-blue-500 text-white shadow"
                          : "bg-gray-200 text-gray-600"
                      } hover:bg-blue-400 transition`}
                      onClick={() => handleUnsentSelectChange("month")}
                    >
                      M
                    </button>
                  </Tooltip>
                </div>
              </div>
            </Card>
          </div>
        </Card>
      </div>
    </>
  );
};

export default BillerDashboard;
