import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Modal,
  AutoComplete,
  Table,
  Row,
  Col,
  Card,
  Space,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { doPost, doGet } from "../../../../../API/apis";
import { useParams } from "react-router-dom";
import {
  useSearchAddedLabs,
  useSearchDiagnosis,
} from "../../../../../API/apiUtils";
import Notification from "../../../../../components/Notification/Notification";
import showConfirmModal from "../../../../../components/ModalConfirm/ModalConfirm";

function InhouseLabs() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [lab, setLab] = useState();
  const [edit, setEdit] = useState(false);
  const [labsList, setLabsList] = useState([]);
  const [editingItemKey, setEditingItemKey] = useState(null);
  const { options, handleSearchLabs } = useSearchAddedLabs();
  const { diagnosisOptions, handleSearchDiagnosis } = useSearchDiagnosis(true);

  const coloumn = [
    {
      title: "Test Name",
      dataIndex: "lab_name",
      key: "lab_name",
    },
    {
      title: "Group",
      dataIndex: "lab_group",
      key: "lab_group",
    },
    {
      title: "Type",
      dataIndex: "lab_type",
      key: "lab_type",
    },
    {
      title: "Price",
      dataIndex: "lab_price",
      key: "lab_price",
    },
    {
      title: "Actions",
      key: "actions",
      align: "right",
      render: (text, record) => (
        <span>
          <EditOutlined
            className="text-blue-400"
            onClick={() => {
              handleEdit(record);
            }}
          />
          <DeleteOutlined
            className="ml-2 mr-4 text-red-600"
            onClick={() => {
              handleDelete(record);
            }}
          />
        </span>
      ),
    },
  ];

  const onSelect = (value, option) => {
    const selectedLab = options.find((item) => item.value === value);
    if (selectedLab) {
      const labDetails = {
        lab_name: selectedLab?.lab_name,
        lab_group: selectedLab?.service_group,
        lab_type: selectedLab?.service_type,
        lab_price: parseFloat(selectedLab?.price),
      };
      setLab(labDetails);
    }
  };

  const handleInhouseLabs = (values) => {
    const newLabs = {
      ...lab,
      lab_instructions: values.lab_instructions,
      lab_diagnosis: values.lab_diagnosis,
      key: Date.now(),
    };
    setLabsList([...labsList, newLabs]);
    setLab();
    form.resetFields();
    setEdit(false);
  };

  const handleEdit = (item) => {
    form.setFieldsValue({
      lab_name: item.lab_name,
      lab_instructions: item.lab_instructions,
      lab_diagnosis: item.lab_diagnosis,
    });
    setEdit(true);
    setEditingItemKey(item.key);
  };

  const calcTotalPrice = (labs) => {
    return labs?.reduce((total, lab) => total + (lab.lab_price || 0), 0);
  };

  const handleUpdate = (values) => {
    const updatedLabsList = labsList.map((item) =>
      item.key === editingItemKey
        ? {
            ...item,
            ...values,
            lab: { ...lab },
          }
        : item
    );
    setLabsList(updatedLabsList);
    form.resetFields();
    setEdit(false);
    setEditingItemKey(null); // Reset the editing item key
  };

  const handleDelete = (item) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        const updatedLabsList = labsList.filter((lab) => lab.key !== item.key);
        setLabsList(updatedLabsList);
      },
    });
  };

  const handleFormClear = () => {
    setEdit(false);
    form.resetFields();
  };

  const handleClear = () => {
    showConfirmModal({
      title: "Are you sure you want to delete this?",
      icon: null,
      content: "This action cannot be undone.",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        setLabsList([]);
        setEdit(false);
      },
    });
  };

  const handleInhouseInvoice = async () => {
    const data = {
      lab_name: labsList,
      total_price: calcTotalPrice(labsList),
    };
    try {
      const response = await doPost(
        `/labs/images/create/${id}/?q=inhouse`,
        data
      );
      if (response.status === 201) {
        Notification.success("In-House Lab Orders Created Successfully");
        setLabsList([]);
      }
    } catch (error) {
      Notification.error("Something went Wrong");
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col xl={12} sm={24} md={24} lg={24}>
        <Card className="shadow-class" title="In-House Labs">
          <Form
            layout="vertical"
            name="inhouse-labs"
            form={form}
            onFinish={edit ? handleUpdate : handleInhouseLabs}
          >
            <Form.Item
              name="lab_name"
              label="Search In-house Labs"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Enter Lab name",
                },
              ]}
            >
              <AutoComplete
                options={options}
                allowClear={true}
                onSelect={onSelect}
                onSearch={handleSearchLabs}
                placeholder="Search Inhouse Labs Only"
              />
            </Form.Item>
            <Form.Item name="lab_instructions" label="Lab Instructions">
              <Input.TextArea
                placeholder="Enter Instructions here"
                rows={4}
                maxLength={500}
                showCount
              />
            </Form.Item>
            <Form.Item name="lab_diagnosis" label="Diagnosis">
              <AutoComplete
                onSearch={handleSearchDiagnosis}
                options={diagnosisOptions}
                placeholder="Search Associated Diagnosis"
                maxLength={300}
              />
            </Form.Item>
            <div className="flex justify-end">
              <Button onClick={handleFormClear}>Clear</Button>
              <Button className="ml-2" type="primary" htmlType="submit">
                {edit ? "Update Lab" : "Add to List"}
              </Button>
            </div>
          </Form>
        </Card>
      </Col>

      <Col xl={12} sm={24} md={24} lg={24}>
        <Card
          className="shadow-class"
          title="In-House Labs Pad"
          extra={
            <Space>
              {labsList.length !== 0 && (
                <Button type="link" onClick={handleClear}>
                  Clear All
                </Button>
              )}
            </Space>
          }
        >
          <Table
            size="small"
            dataSource={labsList}
            columns={coloumn}
            pagination={false}
          />

          <div className="mt-4 flex justify-end">
            <Button
              type={labsList.length === 0 ? "default" : "primary"}
              disabled={labsList.length === 0}
              onClick={handleInhouseInvoice}
            >
              Sign and Invoice
            </Button>
          </div>
        </Card>
      </Col>
    </Row>
  );
}

export default InhouseLabs;
