import React, { useEffect, useState } from "react";
import { Button, Card, Tag } from "antd";
import { doGet } from "../../../../../API/apis";
import { CSVLink } from "react-csv";
import { formatDate, formatTime } from "../../../../../utils/DateFormat";
import ViewLabInvoice from "../../../PatientSummary/InvoiceAndBilling/Invoices/component/Lab/component/ViewLabInvoice";
import { useOutletContext } from "react-router-dom";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import Notification from "../../../../../components/Notification/Notification";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";
import CustomTable from "../../../../../components/CustomTable/CustomTable";
import DateRangePickerCard from "../../../../../components/GlobalComponents/DateRangePickerCard";
import { calculateGrandTotalSum } from "../../../../../utils/utils";
import { currencyFormatter, NumberFormatter } from "../../../../../utils/validator";
import ReportsCards from "../../../../../components/GlobalComponents/ReportsCards";

const extractLab = (labData) => {
  return labData?.map((lab) => lab.lab_name).join(", ");
};

const extractServiceType = (labData) => {
  return labData?.map((lab) => lab.lab_type).join(", ");
};

const extractLabGroup = (labData) => {
  return labData?.map((lab) => lab.lab_group).join(", ");
};

const extractLabPrice = (labData) => {
  return labData?.map((lab) => lab.lab_price).join(", ");
};

const InhouseLabsReports = () => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [viewLabData, setViewLabData] = useState([]);
  const [patientList, setPatientList] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [searchClicked, setSearchClicked] = useState(false);
  const [officeDateFormat, officeTimeFormat, officeTimezone] =
    useOutletContext();
  const { profile, officeSetting } = useStateGlobalContext();

  const role = profile && profile?.user_info[0]?.role;

  const transformedData = (records) => {
    if (!records || !Array.isArray(records)) {
      return [];
    }

    return records.map((record) => ({
      ID: record?.id,
      "LAB ID": record?.inhouseUID,
      "MRN Number": record?.mrn_number,
      "First Name": record?.first_name,
      "Middle Name": record?.middle_name,
      "Last Name": record?.last_name,
      "Lab Name": extractLab(record?.lab_name),
      "Group": extractLabGroup(record?.lab_name),
      "Type": extractServiceType(record?.lab_name),
      "Lab Price": extractLabPrice(record?.lab_name),
      Discount: record?.discount,
      Tax: record?.tax,
      "Sub Total": currencyFormatter(record?.total_price , officeSetting?.local_currency || "USD"),
      "Grand Total:": currencyFormatter(record?.grand_total , officeSetting?.local_currency || "USD"),
      "Added By": record?.added_by,
      Status: record?.status,
      "Mark as Paid By:": record?.marks_as_paid_by && record?.marks_as_paid_by,
      "Mark as Paid Date:":
        record?.mark_as_paid_by_date &&
        formatDate(
          record?.mark_as_paid_by_date,
          officeDateFormat,
          officeTimezone
        ),
      "Mark as Paid Time:":
        record?.mark_as_paid_by_date &&
        formatTime(
          record?.mark_as_paid_by_date,
          officeTimeFormat,
          officeTimezone
        ),
      "Issue Date": formatDate(
        record?.date_added,
        officeDateFormat,
        officeTimezone
      ),
      "Issue Time": formatTime(
        record?.date_added,
        officeTimeFormat,
        officeTimezone
      ),
    }));
  };

  const viewInhouseLab = (record) => {
    setVisible(true);
    setViewLabData(record);
  };

  const onClose = () => {
    setVisible(false);
  };

  const coloumn = [
    {
      key: "labid",
      title: "Inhouse Lab ID",
      width: "15%",
      align: "center",
      dataIndex: "inhouseUID",
      searchable: true,
      render: (text, record) => (
        <Button type="link" onClick={() => viewInhouseLab(record)}>
          {text}
        </Button>
      ),
    },
    {
      key: "name",
      title: "Patient Name",
      width: "15%",
      dataIndex: "first_name",
      searchable: true,
      render: (_, record) => (
        <span>
          {record?.first_name} {record?.middle_name ? record?.middle_name : " "}{" "}
          {record?.last_name}
        </span>
      ),
    },

    {
      key: "lab_name",
      title: "Lab Name",
      width: "10%",
      align: "center",
      render: (_, record) => (
        <span>{record.lab_name.map((lab) => lab.lab_name).join(", ")}</span>
      ),
    },

    {
      key: "lab_type",
      title: "Service Type",
      align: "center",
      render: (_, record) => (
        <span>{record.lab_name.map((lab) => lab.lab_type).join(", ")}</span>
      ),
    },
    {
      key: "lab_group",
      title: "Group Name",
      align: "center",
      render: (_, record) => (
        <span>{record.lab_name.map((lab) => lab.lab_group).join(", ")}</span>
      ),
    },
    {
      key: 4,
      title: "Date",
      align: "center",
      dataIndex: "date_added",
      render: (text) => <FormatDateTime date={text} type="date" />,
    },
    {
      key: 5,
      title: "Time",
      align: "center",
      dataIndex: "date_added",
      render: (text) => <FormatDateTime date={text} type="time" />,
    },
    {
      key: 6,
      title: "Grand Total",
      align: "center",
      dataIndex:"grand_total",
      render:(text)=> <NumberFormatter value={text}/>
    },
    {
      key: "status",
      title: "Status",
      align: "center",
      dataIndex: "status",
      render: (status) =>
        status === "Paid" ? (
          <Tag className="bg-[#1890ff]  text-white  rounded-md">{status}</Tag>
        ) : status === "unpaid" ? (
          <Tag className="bg-gray-400  text-white  rounded-md">{status}</Tag>
        ) : (
          <Tag className="bg-red-500  text-white  rounded-md">{status}</Tag>
        ),
    },
  ];

  const handleDateChange = (dates) => {
    setSelectedDateRange(dates);
  };

  const handleReset = () => {
    setPatientList([]);
  };

  const handleSearch = () => {
    if (selectedDateRange && selectedDateRange.length === 2) {
      setSearchClicked(true);
      getPatientList();
    } else {
      Notification.warning("Please select a date range");
    }
  };

  const getPatientList = async () => {
    setLoading(true);
    try {
      if (selectedDateRange && selectedDateRange.length === 2) {
        const startDate = selectedDateRange[0].format("YYYY-MM-DD");
        const endDate = selectedDateRange[1].format("YYYY-MM-DD");

        const response = await doGet(
          `/reports/lab-invoices-reports/?start_date=${startDate}&end_date=${endDate}`
        );
        if (response.status === 200) {
          setPatientList(response.data.reverse());
          setTotalRevenue(calculateGrandTotalSum(response?.data));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPatientList();
  }, [searchClicked]);

  return (
    <div>
      <ReportsCards
        rxData={patientList}
        title="labs"
      />
      <Card className="shadow-class">
        <DateRangePickerCard
          onDateChange={handleDateChange}
          onSearch={handleSearch}
          onReset={handleReset}
          totalGrandTotal={totalRevenue || ""}
        />
        <div className="flex justify-between items-center mb-2">
          <h1>Search Results</h1>
          {role !== "nurse" && role !== "doctor" && patientList?.length > 0 && (
            <Button type="primary">
              <CSVLink
                data={transformedData(patientList)}
                filename={"Inhouse_Labs.csv"}
                className="csv-link"
              >
                Download Report
              </CSVLink>
            </Button>
          )}
        </div>
        {patientList && (
          <CustomTable
            loading={loading}
            columns={coloumn}
            dataSource={patientList}
            scroll={{
              x: 1500,
            }}
            key="inhouse_lab_report"
          />
        )}
      </Card>
      <ViewLabInvoice
        visible={visible}
        onClose={onClose}
        rxData={viewLabData}
      />
    </div>
  );
};

export default InhouseLabsReports;
