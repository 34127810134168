import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

const NoPageFound = ({title="Sorry, the page you visited does not exist."}) => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-center items-center">
    <Result
      status="404"
      title="404"
      subTitle={title}
      extra={
        <Button type="primary" onClick={() => navigate(-1)}>
          Back Home
        </Button>
      }
    />
    </div>
  );
};

export default NoPageFound;
