import FormatDate from "../../../../components/FormatDate/FormatDate";
import FormatDateTime from "../../../../components/FormatDate/FormatDateTime";
import { DetailBox } from "../../../../components/GlobalComponents/GlobalComponents";
import LabelDisplay from "../../../../components/LabelDisplay/LabelDisplay";

function ViewPrescription({ viewPrescription }) {
  return (
    <div>
      <LabelDisplay title="Added By" description={viewPrescription?.added_by} />
      <LabelDisplay title="Date Added" description={ <FormatDateTime date={viewPrescription?.date_time} type="datetime" />} />


      {viewPrescription?.prescriptions?.map((item, index) => (
        <DetailBox key={index}>
          <div>
            <LabelDisplay
              title="Medicine Name"
              description={item?.searched_medicine}
            />
            <LabelDisplay
              title="Instructions"
              description={item?.instructions}
            />
            <LabelDisplay
              title="Quantity"
              description={item?.dispense_quantity}
            />
            <LabelDisplay title="Refills" description={item?.refills} />
            <LabelDisplay
              title="Dispense as Written"
              description={
                item?.dispense_as_written?.toString() === "true" ? "Yes" : "No"
              }
            />
            <LabelDisplay
              title="Start Date"
              description={
                item?.start_date ? <FormatDate
                  date={item?.start_date}
                /> : "No Date Entered"
              }
            />
           { <LabelDisplay
              title="End Date"
              description={
                item?.end_date ? <FormatDate
                  date={item?.end_date}
                /> : "No Date Entered"
              }
            />}
          </div>
          
          </DetailBox>
      ))}

      <LabelDisplay
        title="Action By"
        description={viewPrescription?.action_by}
      />
      <LabelDisplay
        title="Action Date"
        description={
          viewPrescription?.action_date && (
            <FormatDateTime
              date={viewPrescription?.action_date}
              type="datetime"
            />
          )
        }
      />
    </div>
  );
}

export default ViewPrescription;
