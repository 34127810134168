import { Col, Divider, Row, Typography } from "antd";
import React from "react";
import providerInfo from "../../../../../components/PrescriptionsPad/providerInfo";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";
import FormatDate from "../../../../../components/FormatDate/FormatDate";
import ClinicInformation from "../../../../../components/PrescriptionsPad/ClinicInformation";

const { Title, Paragraph } = Typography;

const Header = ({ officeInfo, patientInfo, date, providerDetails}) => {

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={11} className="text-right">
          <p className="font-semibold text-left">Office Information</p>
          {ClinicInformation(officeInfo)}
        </Col>
        <Col span={2}/>
        <Col span={11} className="text-right">
            {providerInfo(providerDetails)}
        </Col>
        <Divider />
      </Row>
      <div className="flex justify-between mt-4">
        <Paragraph className="font-medium">
          Date Signed: <FormatDateTime date={date} type="date"/>
        </Paragraph>
        <Paragraph className="font-medium">
          Time Signed: <FormatDateTime date={date} type="time"/>
        </Paragraph>
      </div>
      <Divider/>
      <Title className="text-center mt-4" level={5}>Patient</Title>
      <div className="flex justify-between">
        <Paragraph>Name: {patientInfo?.first_name}{" "}
            {patientInfo?.middle_name && patientInfo?.middle_name}{" "}
            {patientInfo?.last_name}</Paragraph>
        <Paragraph>Gender: {patientInfo?.sex === "other" ? patientInfo?.other_Gender : patientInfo?.gender}</Paragraph>
        <Paragraph>DOB: <FormatDate date={patientInfo?.date_of_birth}/></Paragraph>
        <Paragraph>Marital Status: {patientInfo?.marital_status}</Paragraph> 
      </div>
      <div className="flex justify-between">
        <Paragraph>Phone: {patientInfo?.patient_phone}</Paragraph>
        <Paragraph>Email: {patientInfo?.patient_email}</Paragraph>
      </div>
      <Paragraph>Address: {patientInfo?.current_address_line_1}</Paragraph>
      <Paragraph>Preferred Languages: {patientInfo?.languages}</Paragraph>
    </>
  );
};

export default Header;
