import { Button, Table, Form, Input, Modal,  Card, Col } from "antd";
import React, { useEffect, useState } from "react";
import { doPost, doGet, doPatch, doDelete } from "../../../../API/apis";
import Notification from "../../../../components/Notification/Notification";
import BasicButton from "../../../../components/BasicButton/BasicButton";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import showConfirmModal from "../../../../components/ModalConfirm/ModalConfirm";
import { getGroupColumn } from "../../../../components/TableColumns/TableColumns";
import CustomTable from "../../../../components/CustomTable/CustomTable";


function Groups() {
  const [form] = Form.useForm();
  const [groupData, setGroupData] = useState([]);
  const [editGroup, setEditGroup] = useState(null);
  const [groupLoading, setGroupLoading] = useState(false);
  const [isGroupModalVisble, setIsGroupModalVisble] = useState(false);
  const {submitLoading, setSubmitLoading, profile } = useStateGlobalContext()
  const role = profile && profile?.user_info[0]?.role;


  const handleEdit = (record) => {
    setEditGroup(record);
    setIsGroupModalVisble(true);
    form.setFieldsValue({
      group_name: record.group,
    });
  };

  const handleDelete = (record) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk: () => {
        deleteGroup(record);
      },
    });
  };

  const deleteGroup = async (record) => {
    try {
      const response = await doDelete(
        `/services/service-group/delete/${record?.id}/`
      );
      if (response.status === 200) {
        Notification.success("Group deleted successfully");
        getGroup();
      }
    } catch (error) {
      console.log(error);
      Notification.error("Something went wrong");
    }
  };

  const showGroupModal = () => {
    setIsGroupModalVisble(true);
  };

  useEffect(() => {
    getGroup();
  }, []);

  const handleSubmit = async (values) => {
    setSubmitLoading(true);
    const data = {
      group: values.group_name,
    };
    try {
      const response = editGroup
        ? await doPatch(
            `/services/service-group/update/${editGroup.id}/`,
            data
          )
        : await doPost(`/services/service-group/add/`, data);
      if (response.status === 200 || response.status === 201) {
        const successMessage = editGroup
          ? "Group updated successfully"
          : "Group added successfully";
        Notification.success(successMessage);
        getGroup();
        closeGroupModal();
      }else{
        Notification.error("This Service Group already exists")
      }
    } catch (error) {
      Notification.error("This Service Group already exists");
    } finally{
      setSubmitLoading(false);
    }
  };

  const closeGroupModal = () => {
    setIsGroupModalVisble(false);
    setEditGroup(null);
    form.resetFields();
  };

  const getGroup = async () => {
    setGroupLoading(true);
    try {
      const response = await doGet(`/services/service-group/get/`);
      if (response.status === 200) {
        setGroupData(response.data);
      }
    } catch (error) {
      Notification.error("Something went wrong");
    } finally {
      setGroupLoading(false);
    }
  };

  return (
    <Col xs={24} md={24} lg={12} xl={12} className="mb-4">
    <Card>
      <div className="flex justify-between items-center mb-4">
        <h1 className="font-bold text-lg">Groups</h1>
        <Button type="primary" className="float-right" onClick={showGroupModal}>
          Add Group
        </Button>
      </div>
      <CustomTable
      columns={getGroupColumn({handleEdit, handleDelete, role})}
      dataSource={groupData}
      rowKey={"service_grup"}
      loading={groupLoading}
      />

      <Modal
        title={editGroup ? "Edit Group" : "Add Group"}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">Close</Button>
        }
        onCancel={closeGroupModal}
        open={isGroupModalVisble}
        footer={null}
      >
        <Form
          name="service-group"
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Form.Item
            name="group_name"
            label="Group"
            rules={[{ required: true, message: "Enter group name" }]}
          >
            <Input placeholder="Enter group name" maxLength={50} />
          </Form.Item>
          <div className="flex justify-end">
            <Button onClick={closeGroupModal} className="mr-4">
              Cancel
            </Button>
            <BasicButton loading={submitLoading} saving={!editGroup} title={editGroup ? "Update" : "Save"}/>
          </div>
        </Form>
      </Modal>
    </Card>
    </Col>
  );
}

export default Groups;
