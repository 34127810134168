import { Layout, Menu } from 'antd'
import React from 'react'
import { Outlet, useNavigate } from "react-router-dom";
import { useStateGlobalContext } from '../../../contexts/GlobalContext';
import UnauthorizedPage from '../../../components/UnauthorizedPage/UnauthorizedPage';

const { Content } = Layout;


const items = [
  { label: "Basic Information", key: "" , role:["clinic", "manager"] },
  { label: "Billing Information", key: "billing_information" ,role:["clinic", "manager"] },
  { label: "Rooms and Discounts", key: "rooms_discounts" ,role:["clinic", "manager"] },
  { label: "Account Payment Status", key: "account_payment_status", role:["clinic"] },
  { label: "User Management", key: "user_management", role:["clinic", "manager"] },
];

const Setting = () => {
  const navigate = useNavigate();
  const {profile} = useStateGlobalContext()

  document.title = "Office Settings | American EMR";

  const role = profile && profile?.user_info[0]?.role;

  const filteredMenuItems = items.filter(item => item.role.includes(role));

  return (
    <div>
    {(role === "clinic" || role === "manager" ) ? <Layout style={{ backgroundColor: "transparent" }}>
    <Menu
      onClick={({ key }) => navigate(key)}
      mode="horizontal"
      defaultSelectedKeys={[""]}
      items={filteredMenuItems}
      className="summary-menu mb-4 bg-white font-medium tracking-[0.2px]"
    />
    <Content
      className="layout"
      style={{
        backgroundColor: "white",
      }}
    >
      <Outlet/>
    </Content>
  </Layout> : <UnauthorizedPage/>}
  </div>
  )
}

export default Setting