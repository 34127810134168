import {
  Button,
  Card,
  Drawer,
  Select,
  Form,
  Input,
  Row,
  Col,
  Dropdown,
  AutoComplete,
} from "antd";
import React, { useState, useEffect } from "react";
import "./insuranceclaims.css";
import dayjs from "dayjs"; // Import dayjs
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import { doDelete, doGet, doPatch, doPost } from "../../../../../API/apis";
import { useOutletContext, useParams } from "react-router-dom";
import {  EllipsisOutlined } from "@ant-design/icons";
import InsuranceClaimsView from "./component/InsuranceClaimsView";
import BaseDatePicker from "../../../../../components/BaseDatePicker/BaseDatePicker";
import { formatDate } from "../../../../../utils/DateFormat";
import {
  emailValidator,
  NumberFormatter,
  phoneValidator,
  validatePrice,
} from "../../../../../utils/validator";
import { useSearchCPT, useSearchDiagnosis, useSearchServices } from "../../../../../API/apiUtils";
import Notification from "../../../../../components/Notification/Notification";
import showConfirmModal from "../../../../../components/ModalConfirm/ModalConfirm";
import Loader from "../../../../../components/Loader/Loader";
import { actionItems } from "../../../../../utils/Data";
import { InsuranceClaimStatus } from "../../../../../components/GlobalComponents/StatusLabels";
import CustomTable from "../../../../../components/CustomTable/CustomTable";



const { Option } = Select;


const InsuranceClaims = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [view, setView] = useState(false);
  const [viewRecord, setViewRecord] = useState(null);
  const [loading, setLoading] = useState(false);
  const [insuranceLoading, setInsuranceLoading] = useState(false);
  const [officeDateFormat] = useOutletContext();
  const [visible, setVisible] = useState(false);
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedClaim, setSelectedClaim] = useState(null);
  const [insuranceInfo, setInsuranceInfo] = useState({});
  const [selectedDate, setSelectedDate] = useState();
  const { providers, getProviders } = useStateGlobalContext();

  const { options: serviceTypeOptions, handleSearchServices } =
  useSearchServices();

  const { diagnosisOptions: options, handleSearchDiagnosis } =
    useSearchDiagnosis();
  const { CPTCodesOptions, handleSearchCPTCodes } = useSearchCPT();

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const isSaveButtonDisabled = () => {
    return !insuranceInfo?.insurance_company || !insuranceInfo?.insured_person_first_name || !insuranceInfo?.members_id;
  };

  const handleProviderSearch = (value) => {
    const filteredSuggestions =
      providers &&
      providers?.filter((suggestion) =>
        suggestion.name.toLowerCase().includes(value.toLowerCase())
      );
    setSuggestions(filteredSuggestions);
  };

  const columns = [
    {
      key: 3,
      title: "Insurance Company",
      dataIndex: "insurance_company_name",
      width: "10%",
      align: "center",
    },
    {
      key: 4,
      title: "Primary Insurer Full Name",
      dataIndex: "primary_account_full_name",
      width: "10%",
      align: "center",
    },
    {
      key: 5,
      title: "Members ID",
      dataIndex: "members_id",
      width: "10%",
      align: "center",
    },
    {
      key: 6,
      title: "Patient's Co-Pay",
      dataIndex: "patient_copay",
      width: "7%",
      align: "center",
    },
    {
      key: 7,
      title: "Claim Date",
      dataIndex: "claim_date",
      align: "center",
      render: (text) => formatDate(text, officeDateFormat),
    },
    {
      key: 8,
      title: "Claim #",
      dataIndex: "claim_tracking_number",
      align: "center",
      widht: "5%",
    },
    {
      key: 9,
      title: "Billed to Insurance",
      dataIndex: "billed",
      align: "center",
      width: "8%",
      render: (text) => <NumberFormatter value={text} />,
    },
    {
      key: 10,
      title: "Payment Received",
      dataIndex: "paid",
      align: "center",
      width: "8%",
      render: (text) => <NumberFormatter value={text} />,
    },
    {
      key: 11,
      title: "Outstanding Balance",
      dataIndex: "outstanding",
      width: "10%",
      align: "center",
      render: (text) => <NumberFormatter value={text} />,
    },
    {
      key: 12,
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (status) => <InsuranceClaimStatus status={status}/>
    },
    {
      key:13,
      align:'center',
      width:"3%",
      // render:(_, record)=>{
      //   return (
      //     <Dropdown
      //     menu={{insuranceClaimsStatus}}
      //     trigger={["click"]}
      //   >
      //     <DownOutlined className="cursor-pointer" />
      //   </Dropdown>
      //   )
      // }
    },
    {
      title: "Action",
      key: "action",
      width: "7%",
      align: "center",
      fixed: "right",
      render: (_, record) => {
        const availableItems = actionItems;
        return (
          <Dropdown
            menu={{
              items: availableItems.map((item) => ({
                ...item,
                onClick: () => {
                  if (item.key === 1) {
                    handleView(record);
                  } else if (item.key === 2) {
                    handleEdit(record);
                  } else if (item.key === 3) {
                    handleDelete(record);
                  }
                },
              })),
            }}
            trigger={["click"]}
          >
            <EllipsisOutlined className="cursor-pointer" />
          </Dropdown>
        );
      },
    },
  ];

  useEffect(() => {
    if (edit && selectedClaim) {
      // Use the selectedClaim data in edit mode
      setInsuranceInfo({
        insurance_company: selectedClaim.insurance_company_name,
        insured_person_first_name:
          selectedClaim.primary_account_full_name.split(" ")[0],
        insured_person_middle_name:
          selectedClaim.primary_account_full_name.split(" ")[1] || "",
        insured_person_last_name:
          selectedClaim.primary_account_full_name.split(" ")[2] || "",
        members_id: selectedClaim.members_id,
      });
    } else if (visible) {
      setSelectedDate(dayjs());
    }
  }, [edit, selectedClaim, visible]);

  const handleView = (record) => {
    setView(true);
    setViewRecord(record);
  };

  const onCloseView = () => {
    setView(false);
    setViewRecord(null);
  };

  const handleEdit = (record) => {
    setEdit(true);
    setVisible(true);
    setSelectedClaim(record);
    if (record) {
      form.setFieldsValue({
        insuranceType: record?.insurance_type,
        claim_date: dayjs(record?.claim_date),
        claim_officer_full_name: record?.claim_officer_full_name,
        claim_officer_phone_number: record?.claim_officer_phone_number,
        claim_tracking_number: record?.claim_tracking_number,
        diagnosis_code: record?.diagnosis_code,
        procedure_codes: record?.procedure_codes,
        service_provider: record?.service_provider,
        service_description: record?.service_description,
        patient_copay: record?.patient_copay,
        claim_officer_fax: record?.claim_officer_fax,
        claim_officer_email: record?.claim_officer_email,
        billed_amount: record?.billed,
        paid_amount: record?.paid,
        notes: record?.notes,
        status: record?.status,
      });
    }
  };



  useEffect(() => {
    if (visible) {
      setSelectedDate(dayjs());
    }
  }, [visible]);



  const handleInsuranceClaims = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);

    setTimeout(() => {
      setEdit(false);
      form.resetFields();
      setEdit(false);
      setSelectedClaim(null);
      setInsuranceInfo(null);
    }, 200);
  };

  const getInsuranceInfo = async (insurance_type) => {
    setInsuranceLoading(true);
    try {
      const response = await doGet(
        `/insurance-claims/insurance-info/${id}/${insurance_type}/`
      );
      if (response.status === 200) {
        setInsuranceInfo({
          insurance_company:
            response?.data[0]?.insurance_company?.insurance_company_name,
          insured_person_first_name:
            response?.data[0]?.insured_person_info?.first_name,
          insured_person_middle_name:
            response?.data[0]?.insured_person_info?.middle_name,
          insured_person_last_name:
            response?.data[0]?.insured_person_info?.last_name,
          members_id: response?.data[0]?.insured_person_info?.insured_id,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setInsuranceLoading(false);
    }
  };

  const submitInsuranceClaim = async (values) => {
    const fullName = `${
      insuranceInfo?.insured_person_first_name ||
      selectedClaim?.primary_account_full_name
    } ${insuranceInfo?.insured_person_middle_name || ""} ${
      insuranceInfo?.insured_person_last_name || ""
    }`.trim();
    const data = {
      insurance_type: values?.insuranceType,
      insurance_company_name:
        insuranceInfo?.insurance_company ||
        selectedClaim?.insurance_company_name,
      primary_account_full_name:
        fullName || selectedClaim?.primary_account_full_name,
      members_id: insuranceInfo?.members_id || selectedClaim?.members_id,
      claim_date: values?.claim_date,
      status: values?.status || "Draft",
      claim_tracking_number: values?.claim_tracking_number,
      claim_officer_full_name: values?.claim_officer_full_name,
      claim_officer_phone_number: values?.claim_officer_phone_number,
      claim_officer_fax: values?.claim_officer_fax,
      claim_officer_email: values?.claim_officer_email,
      diagnosis_code: values?.diagnosis_code,
      procedure_codes: values?.procedure_codes,
      patient_copay: values?.patient_copay,
      service_provider: values?.service_provider,
      service_description: values?.service_description,
      billed: values?.billed_amount,
      paid: values?.paid_amount,
      outstanding:
        values?.billed_amount && values?.paid_amount
          ? values.billed_amount - values?.paid_amount
          : 0,
      notes: values?.notes,
    };
    try {
      const response = edit
        ? await doPatch(
            `/insurance-claims/update/${id}/${selectedClaim?.id}/`,
            data
          )
        : await doPost(`/insurance-claims/add/${id}/`, data);
      if (response.status === 201 || response.status === 200) {
        edit
          ? Notification.success("Insurance Claim Updated Successfully")
          : Notification.success("Insurance Claim Saved Successfully");
        getInsuranceClaims();
        onClose();
      } else {
        Notification.error(response.message);
      }
    } catch (error) {
      Notification.error(error);
    }
  };

  const getInsuranceClaims = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/insurance-claims/get/${id}/`);
      if (response.status === 200) {
        setData(response.data.reverse());
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (record) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        deleteInsuranceClaim(record?.id);
      },
    });
  };

  const deleteInsuranceClaim = async (record) => {
    try {
      const response = await doDelete(
        `/insurance-claims/delete/${id}/${record}/`
      );
      if (response.status === 200) {
        Notification.success("Insurance Claim Deleted Successfully");
        onClose();
        getInsuranceClaims();
      }
    } catch (error) {
      Notification.error("Something went wrong");
    }
  };



  useEffect(() => {
    getInsuranceClaims();
    getProviders();
  }, []);

  return (
    <>
      <Card className="shadow-sm" title="Insurance Claims Tracking"
       extra={
        <Button type="primary" onClick={handleInsuranceClaims}>
        New Insurance Claims
      </Button>
       }
      >

        <CustomTable
          loading={loading}
          columns={columns}
          size="small"
          dataSource={data}

        />
      </Card>

      <Drawer
        open={visible}
        title={edit ? "Update Insurance Claims" : "Enter Insurance Claim"}
        onClose={onClose}
        maskClosable={false}
        width={575}
        closeIcon={
          <Button size="small" className="drawer-close-icon" shape="round">
            Close
          </Button>
        }
      >
        <Form layout="vertical" form={form} onFinish={submitInsuranceClaim}>
          <Form.Item
            name="insuranceType"
            label="Choose Insurance Type"
            rules={[
              { required: true, message: "Please select insurance type" },
            ]}
          >
            <Select
              placeholder="Choose Insurance Type"
              allowClear
              onChange={(value) => getInsuranceInfo(value)}
            >
              <Option value="primary">Primary</Option>
              <Option value="secondary">Secondary</Option>
              <Option value="tertiary">Tertiary</Option>
              <Option value="auto">Auto</Option>
              <Option value="other">Other</Option>
            </Select>
          </Form.Item>

          <div className="bg-gray-100 p-4 rounded-md selected-membership">
            <p className="font-semibold">Selected Insurance Information</p>
            {insuranceLoading ? <Loader/> : (
            <div>
            <p>
              <span className="font-medium">Insurance Company Name: </span>
              {insuranceInfo?.insurance_company}
            </p>
            <p>
              <span className="font-medium">Primary Insurer Name:</span>{" "}
              {insuranceInfo?.insured_person_first_name}{" "}
              {insuranceInfo?.insured_person_middle_name &&
                insuranceInfo?.insured_person_middle_name}{" "}
              {insuranceInfo?.insured_person_last_name}
            </p>
            <p>
              <span className="font-medium">Members ID:</span>{" "}
              {insuranceInfo?.members_id}
            </p>
          </div>
            )}

          </div>

          <div className="mt-4">
            <Row gutter={[16, 16]}>
              <Col sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  name="claim_date"
                  label="Date of Service"
                  rules={[{ required: true, message: "Please select Date" }]}
                >
                  <BaseDatePicker
                    // defaultValue={dayjs()}
                    value={edit ? selectedClaim?.claim_date : selectedDate}
                    onChange={handleDateChange}
                    // format={dateFormat}
                  />
                </Form.Item>
              </Col>
              <Col sm={12} md={12} lg={12} xl={12}>
                {edit && (
                  <Form.Item name="status" label="Current Status">
                    <Select placeholder="Current Status" defaultValue="draft">
                      <Option value="Draft">Draft</Option>
                      <Option value="Submitted">Submitted</Option>
                      <Option value="on Hold">On Hold</Option>
                      <Option value="Denied">Denied</Option>
                      <Option value="Approved">Approved</Option>
                      <Option value="Paid">Paid</Option>
                    </Select>
                  </Form.Item>
                )}
              </Col>
            </Row>

            <Form.Item
              name="claim_tracking_number"
              label="Claim Tracking Number"
            >
              <Input placeholder="Claim Tracking Number" maxLength={30} />
            </Form.Item>
            <p className="mb-2 font-semibold">Claim Officer Information</p>
            <Row gutter={[16, 16]}>
              <Col xl={12} sm={12} lg={12} md={12}>
                <Form.Item name="claim_officer_full_name" label="Full Name">
                  <Input placeholder="Full Name" maxLength={30} />
                </Form.Item>
              </Col>
              <Col xl={12} sm={12} lg={12} md={12}>
                <Form.Item
                  name="claim_officer_phone_number"
                  label="Phone Number"
                  rules={[{ validator: phoneValidator }]}
                >
                  <Input placeholder="Phone Number" maxLength={20} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col xl={12} sm={12} lg={12} md={12}>
                <Form.Item name="claim_officer_fax" label="Fax">
                  <Input placeholder="Fax" maxLength={20} />
                </Form.Item>
              </Col>
              <Col xl={12} sm={12} lg={12} md={12}>
                <Form.Item
                  name="claim_officer_email"
                  label="Email"
                  rules={[{ validator: emailValidator }]}
                >
                  <Input placeholder="Email" maxLength={30} />
                </Form.Item>
              </Col>
            </Row>

            <p className="mb-2 font-semibold">Service Information</p>
            <Row gutter={[16, 16]}>
              <Col xl={12} sm={12} lg={12} md={12}>
                <Form.Item
                  name="service_description"
                  label="Service Name"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Service Name",
                    },
                  ]}
                >
                  <AutoComplete
                    onSearch={handleSearchServices}
                    options={serviceTypeOptions}
                    placeholder="Search Service Name"
                    maxLength={100}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col xl={12} sm={12} lg={12} md={12}>
                <Form.Item
                  name="patient_copay"
                  label="Patient's Co-Pay"
                  rules={[{ validator: validatePrice }]}
                >
                  <Input placeholder="Amount Patient Paid" maxLength={15} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col xl={12} sm={12} lg={12} md={12}>
                <Form.Item name="service_provider" label="Service Provider">
                  <Select
                    showSearch
                    placeholder="Search for doctors"
                    style={{ width: 200 }}
                    onSearch={handleProviderSearch}
                    // onSelect={handleSelect}
                    optionFilterProp="children"
                    filterOption={false}
                  >
                    {suggestions?.map((doctor) => (
                      <Option key={doctor.id} value={doctor.name}>
                        {doctor.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={12} sm={12} lg={12} md={12}>
                <Form.Item name="procedure_codes" label="Procedure Codes">
                  <AutoComplete
                    onSearch={handleSearchCPTCodes}
                    options={CPTCodesOptions}
                    placeholder="Search CPT Codes"
                    maxLength={100}
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name="diagnosis_code" label="Diagnosis Code">
              <AutoComplete
                onSearch={handleSearchDiagnosis}
                options={options}
                placeholder="Search Diagnosis Code"
                maxLength={100}
              />
            </Form.Item>

            <p className="mb-2 font-semibold">Payment Information</p>
            <Row gutter={[16, 16]}>
              <Col xl={12} sm={12} lg={12} md={12}>
                <Form.Item
                  name="billed_amount"
                  label="Billed Amount"
                  rules={[
                    { required: true, message: "Please Enter Billed Amount" },
                    {
                      validator: validatePrice
                    }
                  ]}
                >
                  <Input placeholder="Billed Amount" maxLength={15} />
                </Form.Item>
              </Col>
              <Col xl={12} sm={12} lg={12} md={12}>
                <Form.Item name="paid_amount" label="Paid Amount"
                  rules={[                    {
                    validator: validatePrice
                  }]}
                >
                  <Input placeholder="Paid Amount" maxLength={15} />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name="notes" label="Additional Notes">
              <Input.TextArea
                placeholder="Claim Notes"
                maxLength={500}
                showCount
              />
            </Form.Item>
          </div>

          <div className="flex justify-between">
            {edit ? (
              <Button
                className="danger__button"
                onClick={() => handleDelete(selectedClaim)}
              >
                Delete
              </Button>
            ) : (
              <span></span>
            )}
          <Button 
            type={isSaveButtonDisabled() ? "default": 'primary'}
            htmlType="submit"
            disabled={isSaveButtonDisabled()} // Add the disabled prop here
          >
            {edit ? "Update" : "Save"}
          </Button>
          </div>
        </Form>
      </Drawer>

      <InsuranceClaimsView
        visible={view}
        data={viewRecord}
        onClose={onCloseView}
      />
    </>
  );
};

export default InsuranceClaims;
