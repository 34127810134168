import { Button, Modal } from "antd";
import LabelDisplay from "../../../../../components/LabelDisplay/LabelDisplay";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";
import { DetailBox } from "../../../../../components/GlobalComponents/GlobalComponents";
import FormatDate from "../../../../../components/FormatDate/FormatDate";
import { NumberFormatter } from "../../../../../utils/validator";

const ViewMembership = ({ open, data, onClose }) => {
  return (
    <Modal
      open={open}
      title="Membership Details"
      footer={null}
      onCancel={onClose}
      closeIcon={
        <Button size="small" className="app-close-icon" shape="round">
          Close
        </Button>
      }
    >
      <LabelDisplay title="Added By" description={data?.added_by} />
      <LabelDisplay
        title="Date Added"
        description={<FormatDateTime date={data?.date_added} type="datetime" />}
      />
      <LabelDisplay title="MID Number" description={data?.MID_number} />
      <DetailBox>
        <LabelDisplay title="Plan" description={data?.plans} />
        <LabelDisplay title="Visits" description={data?.visits} />
        <LabelDisplay
          title="Status"
          description={data?.status ? "Active" : "Inactive"}
        />
        <LabelDisplay title="Name" description={data?.name} />
        <LabelDisplay
          title="Primary Member"
          description={data?.primary_member}
        />
        <LabelDisplay title="Notes" description={data?.notes || "None"} />
        <LabelDisplay title="Price" description={<NumberFormatter value={data?.price}/>} />
        <LabelDisplay
          title="Start Date"
          description={<FormatDate date={data?.start_date}/>}
        />
        <LabelDisplay
          title="Expiration Date"
          description={
            <FormatDate date={data?.expiration_date}/>
          }
        />
        <LabelDisplay
          title="Group Plan"
          description={data?.group_plan ? "Yes" : "No"}
        />
      </DetailBox>
    </Modal>
  );
};

export default ViewMembership;
