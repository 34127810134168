import React from 'react'
import PastSurgicalHistory from '../../MedicalCards/PastSurgicalHistory/PatientSurgicalHistory'
import FamilyHistory from '../../MedicalCards/FamilyHistory/FamilyHistory'
import PMH from '../../MedicalCards/PastMedicalHistory/PMH'
import SocialHistory from '../../MedicalCards/SocialHistory/SocialHistory'
import ICD10Codes from '../../MedicalCards/ICD10/ICD10Codes'
import Allergies from '../../MedicalCards/Allergies/Allergies'
import CurrentMedications from '../../MedicalCards/CurrentMedication/CurrentMedication'
import VitalSigns from '../../MedicalCards/VitalSigns/VitalSigns'

const RightPanelCards = () => {
  return (
    <div className="flex flex-col gap-4 h-[65vh] overflow-auto p-2">
    <VitalSigns/>
    <Allergies/>
    <ICD10Codes/>
    <PMH/>
    <PastSurgicalHistory/>
    <SocialHistory/>
    <FamilyHistory/>
    <CurrentMedications/>
    </div>
  )
}

export default RightPanelCards
