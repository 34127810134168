import React, { createContext, useContext, useState } from "react";
import { doGet, doPatch } from "../API/apis";
import Notification from "../components/Notification/Notification";
import { clearSession } from "../customHooks";

const GlobalContext = createContext();
export const GlobalContextProvider = ({ children }) => {
  const [data, setData] = useState(null);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState(null);
  const [deletedStaff, setDeletedStaff] = useState(null);
  const [userData, setUserData] = useState(null);
  const [invoices, setInvoices] = useState(null);
  const [providers, setProviders] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [waitingRoomData, setWaitingRoomData] = useState(null);

  const [currentMedication, setCurrentMedication] = useState(null);
  const [familyHistory, setFamilyHistory] = useState(null);
  const [vaccinationHistory, setVaccinationHistory] = useState(null);
  const [socialHistory, setSocialHistory] = useState(null);
  const [patientDiagnosis, setPatientDiagnosis] = useState(null);
  const [patientAllergies, setPatientAllergies] = useState(null);
  const [surgicalHistory, setSurgicalHistory] = useState(null);
  const [allMedications, setAllMedications] = useState(null);
  const [pastMedicalHistory, setPastMedicalHistory] = useState(null);
  const [patientGoals, setPatientGoals] = useState(null);
  const [secondaryInsurance, setSecondaryInsurance] = useState(null);

  const [orderedLabs, setOrderedLabs] = useState(null);
  const [creditMemoInfo, setCreditMemoInfo] = useState([]);
  const [officeSetting, setOfficeSetting] = useState(null);
  const [prescriptions, setPrescriptions] = useState(null);
  const [patientBasicInfo, setPatientBasicInfo] = useState(null);
  const [outstandingBillData, setOutstandingBillData] = useState(null);
  const [insuranceData, setInsuranceData] = useState(null);
  const [guestInvoicesData, setGuestInvoicesData] = useState(null);
  const [labInvoice, setLabInvoices] = useState(null);
  const [productData, setProductData] = useState([]);
  const [billingInformation, setBillingInformation] = useState(null);
  const [preferredPharmacy, setPreferredPharmacy] = useState(null);
  const [preferredLab, setPreferredLab] = useState(null);
  const [referralData, setReferralData] = useState(null);
  const [appointments, setAppointments] = useState(null);
  const [superbill, setSuperbill] = useState([]);
  const [appLogoData, setAppLogoData] = useState([]);
  const [bankInformation, setBankInformation] = useState([]);
  const [unpaidLabsCount, setUnpaidLabsCount] = useState([]);
  const [providerDetails, setProviderDetails] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [unpaidInvoicesCount, setUnpaidInvoicesCount] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleApiError = (error) => {
    console.log(error, "ERROR");
    Notification.error("Something went wrong");
    console.error(error);
  };

  const getTemplates = async ({ showAll = false, showLabs = false }) => {
    setLoading(true);
    try {
      const response = await doGet("/templates/get/");
      if (response.status === 200) {
        let templates = response.data?.reverse() || [];

        // Apply filters based on `showAll` and `showLabs`
        if (!showAll) {
          templates = templates.filter((template) =>
            showLabs ? template.is_lab === true : template.is_lab === false
          );
        }

        setTemplates(templates);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const getPrescriptions = async (id) => {
    setLoading(true);
    try {
      const response = await doGet(`/prescriptions/get/${id}/`);
      if (response.status === 200) {
        setPrescriptions(response?.data.reverse());
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const getDeletedStaff = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/user/get-deleted-staff/`);
      if (response.status === 200) {
        setDeletedStaff(response?.data);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const getLabOrders = async (id) => {
    setLoading(true);
    try {
      const response = await doGet(`/labs/images/get-ordered-labs/${id}/`);
      if (response.status === 200) {
        const sortedOrderedLabs = response.data.sort((a, b) => {
          return new Date(b.date_added) - new Date(a.date_added);
        });
        setOrderedLabs(sortedOrderedLabs);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const getHistoricalMedication = async (id) => {
    setLoading(true);
    try {
      const response = await doGet(`/prescriptions/get-history/${id}/`);
      if (response.status === 200) {
        setData(response.data.reverse());
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const getPatientData = async (id) => {
    setLoading(true);
    try {
      const response = await doGet(`/patient/patient-details/${id}/?q=detail`);
      if (response.status === 200) {
        setPatientBasicInfo(response?.data[0]);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const getAllergies = async (id) => {
    setLoading(true);
    try {
      const response = await doGet(`/patient/get-allergies/${id}/`);
      if (response.status === 200) {
        setPatientAllergies(response?.data);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const getVaccinationHistory = async (id) => {
    setLoading(true);
    try {
      const response = await doGet(`/patient/get-vaccination-history/${id}/`);
      if (response.status === 200) {
        setVaccinationHistory(response.data.reverse());
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const getFamilyHistory = async (id) => {
    setLoading(true);
    try {
      const response = await doGet(`/patient/get-family-history/${id}/`);
      if (response.status === 200) {
        setFamilyHistory(response?.data.reverse());
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const getAllMedications = async (id) => {
    setLoading(true);
    try {
      const response = await doGet(`/patient/get-current-medications/${id}/`);
      if (response.status === 200) {
        setAllMedications(response.data.reverse());
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const getPastMedicalHistory = async (id) => {
    setLoading(true);
    try {
      const response = await doGet(`/patient/add-past-medical-history/${id}/`);
      if (response.status === 200) {
        setPastMedicalHistory(response.data.reverse());
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const getPatientGoals = async (id) => {
    setLoading(true);
    try {
      const response = await doGet(`/patient/get-patient-goals/${id}/`);
      if (response.status === 200) {
        setPatientGoals(response?.data.reverse());
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const getSocialHistory = async (id) => {
    setLoading(true);
    try {
      const response = await doGet(`/patient/get-social-history/${id}/`);
      if (response?.status === 200) {
        setSocialHistory(response?.data);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const getPatientSurgicalHistory = async (id) => {
    setLoading(true);
    try {
      const response = await doGet(`/patient/get-past-surgical-history/${id}/`);
      if (response.status === 200) {
        setSurgicalHistory(response.data.reverse());
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const getPatientICD10codes = async (id) => {
    setLoading(true);
    try {
      const response = await doGet(`/patient/get-icd-10-codes/${id}/`);
      if (response.status === 200) {
        setPatientDiagnosis(response.data.reverse());
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getProfile = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/settings/get-personal-info/`);
      if (response.status === 200) {
        setProfile(response.data);
      } else if (response.status === 401) {
        clearSession();
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const getOfficeSettings = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/user/office-settings/?q=basic/`);
      if (response?.status === 200) {
        setOfficeSetting(response?.data);
        setLoading(false);
      } else if (response.status === 401) {
        clearSession();
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  const getBillingInformation = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/user/office-settings/?q=billing/`);
      if (response?.status === 200) {
        setBillingInformation(response?.data);
        setLoading(false);
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  const getStaff = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/user/get-staff/`);
      if (response.status === 200) {
        setUsers(response?.data?.data);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const getProviders = async () => {
    setLoading(true);
    try {
      const response = await doGet("/tasks/get-all-users/");
      if (response.status === 200) {
        setProviders(response?.data);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  //Invoices Global APIS
  const getInvoices = async (id, isWaitingRoom = false) => {
    setLoading(true);
    try {
      const response = await doGet(`/invoice/invoice-get/${id}/`);
      if (response.status === 200) {
        const sortedServiceInvoices = response.data.sort((a, b) => {
          return new Date(b.issue_date) - new Date(a.issue_date);
        });
        setInvoices(sortedServiceInvoices);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const getOutstandingBillInfo = async (id) => {
    setLoading(true);
    try {
      const response = await doGet(`/bill/outstanding-bill/${id}/`);
      if (response?.status === 200) {
        setOutstandingBillData(response.data);
      }
    } catch (error) {
      handleApiError("Something Went Wrong");
    } finally {
      setLoading(false);
    }
  };

  const getCreditMemoInfo = async (id) => {
    setLoading(true);
    try {
      const response = await doGet(`/bill/outstanding-credit-memo/${id}/`);
      if (response?.status === 200) {
        setCreditMemoInfo(response.data);
      }
    } catch (error) {
      handleApiError("Something Went Wrong");
    } finally {
      setLoading(false);
    }
  };

  const getInsuranceData = async (id, type) => {
    setLoading(true);
    try {
      const response = await doGet(`/invoice/insurance-get/${id}/?q=${type}`);
      if (response.status === 200) {
        setInsuranceData(response.data);
      }
    } catch (error) {
      handleApiError("Something Went Wrong");
    } finally {
      setLoading(false);
    }
  };

  const getSecondaryInsurance = async (id) => {
    setLoading(true);
    try {
      const response = await doGet(`/invoice/insurance-get/${id}/?q=secondary`);
      if (response.status === 200) {
        setSecondaryInsurance(response.data);
      }
    } catch (error) {
      handleApiError("Something Went Wrong");
    } finally {
      setLoading(false);
    }
  };

  const getGuestInvoices = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/inventory/get-guest/`);
      if (response.status === 200) {
        const sortedGuestInvoices = response.data.sort((a, b) => {
          return new Date(b.issue_date) - new Date(a.issue_date);
        });
        setGuestInvoicesData(sortedGuestInvoices);
      }
    } catch (error) {
      handleApiError("Something Went Wrong");
    } finally {
      setLoading(false);
    }
  };

  const getLabInvoices = async (id) => {
    setLoading(true);
    try {
      const response = await doGet(`/labs/images/get/${id}/?q=inhouse`);
      if (response.status === 200) {
        const sortedLabInvoices = response.data.sort((a, b) => {
          return new Date(b.date_added) - new Date(a.date_added);
        });
        setLabInvoices(sortedLabInvoices);
      }
    } catch (error) {
      handleApiError("Something Went Wrong");
    } finally {
      setLoading(false);
    }
  };

  const getProductInvoices = async (id) => {
    setLoading(true);
    try {
      const response = await doGet(`/inventory/get-prod-invoice/${id}/`);
      if (response.status === 200) {
        const sortedProductInvoices = response.data.sort((a, b) => {
          return new Date(b.issue_date) - new Date(a.issue_date);
        });
        setProductData(sortedProductInvoices);
      }
    } catch (error) {
      handleApiError("Something Went Wrong");
    } finally {
      setLoading(false);
    }
  };

  const getCurrentMedications = async (id) => {
    setLoading(true);
    try {
      const response = await doGet(`/patient/get-current-medications/${id}/`);
      if (response.status === 200) {
        setCurrentMedication(response.data.reverse());
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const getPreferredPharmacy = async (id) => {
    setLoading(true);
    try {
      const response = await doGet(`/patient/get-prefered-pharmacies/${id}/`);
      if (response.status === 200) {
        setPreferredPharmacy(response.data);
      }
    } catch (error) {
      handleApiError("Something Went Wrong");
    } finally {
      setLoading(false);
    }
  };

  const getPreferredLab = async (id) => {
    setLoading(true);
    try {
      const response = await doGet(`/patient/get-prefered-labs/${id}/`);
      if (response.status === 200) {
        setPreferredLab(response.data);
      }
    } catch (error) {
      handleApiError("Something Went Wrong");
    } finally {
      setLoading(false);
    }
  };

  const getReferral = async (id) => {
    setLoading(true);
    try {
      const response = await doGet(`/referral/referral-get/${id}/`);
      if (response.status === 200) {
        const sortedReferralData = response.data.sort((a, b) => {
          return new Date(b.date_time) - new Date(a.date_time);
        });
        setReferralData(sortedReferralData);
      }
    } catch (error) {
      handleApiError("Something Went Wrong");
    } finally {
      setLoading(false);
    }
  };

  const getTasks = async (check) => {
    setLoading(true);
    try {
      const url = check ? `/tasks/get/?q=${check}` : "/tasks/get/";
      const response = await doGet(url);
      if (response.status === 200) {
        const sortedTasks = response.data.sort(
          (a, b) => new Date(b.entered_date) - new Date(a.entered_date)
        );
        setTasks(sortedTasks);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  //

  const getMembershipsMonthLeft = async (check) => {
    setLoading(true);
    try {
      const response = await doPatch("/membership/time-left/");
      if (response.status === 200) {
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const getAppointments = async () => {
    setLoading(true);
    try {
      const response = await doGet("/appointments/get/");
      if (response.status === 200) {
        setAppointments(response.data);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const getSuperbill = async (id) => {
    setLoading(true);
    try {
      const response = await doGet(`/superbill/view-bill/${id}/`);
      if (response.status === 200) {
        const sortedSuperbill = response.data.sort(
          (a, b) => new Date(b.issue_date) - new Date(a.issue_date)
        );
        setSuperbill(sortedSuperbill);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const getAppLogo = async () => {
    setLoading(true);
    try {
      const response = await doGet(
        "/administration/retrieve-application-images/"
      );
      if (response.status === 200) {
        setAppLogoData(response.data.data);
      } else if (response.status === 400) {
        setAppLogoData(null);
      }
    } catch (error) {
      handleApiError();
    } finally {
      setLoading(false);
    }
  };

  const getBankInformation = async () => {
    setLoading(true);
    try {
      const response = await doGet(
        `/administration/retrieve-super-admin-note/`
      );
      if (response.status === 200) {
        setBankInformation(response?.data?.data);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const getUserInfo = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/settings/personal-settings-get/`);
      if (response.status === 200) {
        setUserData(response.data[0]);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  // /labs/images/unpaid_invoice_inhouse_labs/1/

  const getUnpaidLabs = async (id) => {
    setLoading(true);
    try {
      const response = await doGet(
        `/labs/images/unpaid_invoice_inhouse_labs/${id}/`
      );
      if (response.status === 200) {
        setUnpaidLabsCount(response.data);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const getProviderDetails = async (id) => {
    setLoading(true);
    try {
      const response = await doGet(`/settings/settings-detail/${id}/`);
      if (response.status === 200) {
        setProviderDetails(response.data);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const getUnpaidInvoicesCount = async (id) => {
    setLoading(true);
    try {
      const response = await doGet(
        `/invoice/clinic-unpaid-invoice-count/${id}/`
      );
      if (response?.status === 200) {
        setUnpaidInvoicesCount(response?.data?.unpaid_count);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const getReasonForVisit = async (id) => {
    setLoading(true);
    try {
      const response = await doGet(`/visits/get/${id}/`);
      if (response.status === 200) {
        const sortedWaitingRoom = response.data.sort((a, b) => {
          return new Date(b?.checkin_time) - new Date(a?.checkin_time);
        });
        setWaitingRoomData(sortedWaitingRoom);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <GlobalContext.Provider
      value={{
        loading,
        setLoading,
        prescriptions,
        getPrescriptions,
        data,
        getHistoricalMedication,
        orderedLabs,
        getLabOrders,
        patientBasicInfo,
        getPatientData,
        patientAllergies,
        getAllergies,
        officeSetting,
        getOfficeSettings,
        billingInformation,
        getBillingInformation,
        profile,
        getProfile,
        users,
        getStaff,
        deletedStaff,
        getDeletedStaff,
        providers,
        getProviders,
        invoices,
        getInvoices,
        waitingRoomData,
        getReasonForVisit,
        outstandingBillData,
        getOutstandingBillInfo,
        creditMemoInfo,
        getCreditMemoInfo,
        insuranceData,
        getInsuranceData,
        guestInvoicesData,
        getGuestInvoices,
        labInvoice,
        getLabInvoices,
        preferredPharmacy,
        getPreferredPharmacy,
        preferredLab,
        getPreferredLab,
        productData,
        getProductInvoices,
        referralData,
        getReferral,
        tasks,
        getTasks,
        appointments,
        getAppointments,
        superbill,
        getSuperbill,
        appLogoData,
        getAppLogo,
        bankInformation,
        getBankInformation,
        userData,
        getUserInfo,
        unpaidLabsCount,
        getUnpaidLabs,
        providerDetails,
        getProviderDetails,
        getMembershipsMonthLeft,
        familyHistory,
        getFamilyHistory,
        vaccinationHistory,
        getVaccinationHistory,
        patientDiagnosis,
        getPatientICD10codes,
        socialHistory,
        getSocialHistory,
        currentMedication,
        getCurrentMedications,
        surgicalHistory,
        getPatientSurgicalHistory,
        allMedications,
        getAllMedications,
        pastMedicalHistory,
        getPastMedicalHistory,
        patientGoals,
        getPatientGoals,
        secondaryInsurance,
        getSecondaryInsurance,
        submitLoading,
        setSubmitLoading,
        templates,
        getTemplates,
        getUnpaidInvoicesCount,
        unpaidInvoicesCount,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
export const useStateGlobalContext = () => useContext(GlobalContext);
