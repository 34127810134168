import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { doGet } from "../../../../../API/apis";
import { CSVLink } from "react-csv";
import Notification from "../../../../../components/Notification/Notification";
import CustomTable from "../../../../../components/CustomTable/CustomTable";
import DateRangePickerCard from "../../../../../components/GlobalComponents/DateRangePickerCard";
import { DetailCard } from "../../../../../components/GlobalComponents/GlobalComponents";

const coloumn = [
  {
    key: 1,
    title: "MRN Number",
    align: "center",
    dataIndex: "mrn_number",
    searchable: true,
  },
  {
    key: 2,
    title: "Patient Name",
    dataIndex: "first_name",
    searchable: true,

    render: (_, record) => (
      <span>
        {record?.first_name} {record?.middle_name ? record?.middle_name : " "}{" "}
        {record?.last_name}
      </span>
    ),
  },
  {
    key: 3,
    title: "Total Visits",
    align: "center",
    dataIndex: "total_visits",
    sorter: (a, b) => a.total_visits - b.total_visits,
    showSorterTooltip: false,
  },
];

const transformedData = (records) => {
  if (!records || !Array.isArray(records)) {
    return [];
  }

  return records.map((record) => ({
    // ID: record?.id,
    "First Name": record?.first_name,
    "Middle Name": record?.middle_name,
    "Last Name": record?.last_name,
    "MRN Number": record.mrn_number,
    "Total Visits": record?.total_visits,
  }));
};

const MostVisited = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [searchClicked, setSearchClicked] = useState(false);

  const handleDateChange = (dates) => {
    setSelectedDateRange(dates);
  };

  const handleReset = () => {
    setData([]);
  };

  const handleSearch = () => {
    if (selectedDateRange && selectedDateRange.length === 2) {
      setSearchClicked(true);
      getMostVisits();
    } else {
      Notification.warning("Please select a date range");
    }
  };

  const getMostVisits = async () => {
    setLoading(true);
    try {
      if (selectedDateRange && selectedDateRange.length === 2) {
        const startDate = selectedDateRange[0].format("YYYY-MM-DD");
        const endDate = selectedDateRange[1].format("YYYY-MM-DD");

        const response = await doGet(
          `/reports/most-visits/?start_date=${startDate}&end_date=${endDate}`
        );
        if (response.status === 200) {
          const mostVisits = response.data.sort((a, b) => {
            return b.total_visits - a.total_visits;
          });
          setData(mostVisits);
        }
      } else {
        Notification.warning("Please select a valid date range");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchClicked) {
      getMostVisits();
      setSearchClicked(false);
    }
  }, [searchClicked, selectedDateRange]);

  return (
    <DetailCard title="Most Visits Report">
      <DateRangePickerCard
        onDateChange={handleDateChange}
        onSearch={handleSearch}
        onReset={handleReset}
      />
      <div className="flex justify-between items-center mb-2">
        <h1>Search Results</h1>
        {data?.length > 0 && (
          <Button type="primary">
            <CSVLink
              data={transformedData(data)}
              filename={"Most-visits-report.csv"}
              className="csv-link"
            >
              Download Report
            </CSVLink>
          </Button>
        )}
      </div>
      <CustomTable
        loading={loading}
        columns={coloumn}
        dataSource={data}
        key="most_visited"
      />
    </DetailCard>
  );
};

export default MostVisited;
