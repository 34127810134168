import React, { useState } from "react";
import { Tabs } from "antd";
import PatientList from "./PatientReports/PatientList";
import PatientHealthRecord from "./PatientReports/PatientHealthRecord";
import MostVisited from "./PatientReports/MostVisited";
import Referral from "./PatientReports/Referral";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";

const allItems = [
  { label: "Patient's List Report", key: "1", children: <PatientList /> },
  { label: "Patient Health Record", key: "2", children: <PatientHealthRecord /> },
  { label: "Most Visited Report", key: "3", children: <MostVisited /> },
  { label: "Referral From Report", key: "5", children: <Referral /> },
];

const PatientReports = () => {
  const { profile } = useStateGlobalContext();
  const role = profile && profile?.user_info[0]?.role;
  const [activeKey, setActiveKey] = useState("1");

  // Filter items to show only key 1 if the role is "biller"
  const filteredItems = role === "biller" ? allItems.filter((item) => item.key === "1") : allItems;

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  return (
    <Tabs
      items={filteredItems}
      activeKey={activeKey}
      type="card"
      size="large"
      onTabClick={handleTabChange}
    />
  );
};

export default PatientReports;
