import React, { useEffect } from "react";
import VitalSigns from "../MedicalCards/VitalSigns/VitalSigns";
import PMH from "../MedicalCards/PastMedicalHistory/PMH";
import ImportantNotes from "./ImportantNotes/ImportantNotes";
import PastSurgicalHistory from "./PastSurgicalHistory/PatientSurgicalHistory";
import SocialHistory from "./SocialHistory/SocialHistory";
import FamilyHistory from "./FamilyHistory/FamilyHistory";
import Allergies from "./Allergies/Allergies";
import VaccinationHistory from "./VaccinationHistory/VaccinationHistory";
import PatientGoals from "./PatientGoals/PatientGoals";
import Medications from "./Medication/PatientMedication";
import OrderedLabs from "./OrderedLabs/OrderedLabs";
import PreferredPharmacy from "./PreferredPharmacy/PreferredPharmacy";
import PrefferedLab from "./PrefferedLab/PrefferedLab";
import AdvanceDirectives from "./AdvanceDirectives/AdvanceDirectives";
import ICD10Codes from "./ICD10/ICD10Codes";
import ImplantedDevice from "./ImplantedDevice/ImplantedDevice";
import CurrentMedications from "./CurrentMedication/CurrentMedication";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";

function Summary() {
const {profile}  = useStateGlobalContext()
const role = profile && profile?.user_info[0]?.role;


  return (
    <div>
      <h1 className="mt-2 mb-4">Patient's Medical Cards</h1>
    <div className="mb-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-8">
      <VitalSigns/>

      <ImportantNotes/>
      <CurrentMedications/>
      <ICD10Codes/>

      <Allergies/>

      <Medications/>

      <PMH/>
      <VaccinationHistory/>

      <PreferredPharmacy/>
      <PastSurgicalHistory/>
      <ImplantedDevice/>
      <OrderedLabs/>

      <SocialHistory/>
      <PatientGoals/>
      <PrefferedLab/>
      <FamilyHistory/>
      <AdvanceDirectives/>
    </div>
    </div>
  );
}

export default Summary;
