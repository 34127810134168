import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Modal,
  Table,
  Form,
  Input,
  Upload,
  AutoComplete,
  Dropdown,
  Tooltip,
  Select,
  Typography,
  Card,
  Space,
} from "antd";
import { InboxOutlined, EllipsisOutlined } from "@ant-design/icons";
import { doGet, doPost, doDelete, doPatch } from "../../../../../API/apis";
import { useParams } from "react-router-dom";
import ViewLabResults from "./ViewLabResults";
import Notification from "../../../../../components/Notification/Notification";
import showConfirmModal from "../../../../../components/ModalConfirm/ModalConfirm";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";
import { debounce } from "../../../../../API/apiUtils";
import CustomTable from "../../../../../components/CustomTable/CustomTable";
import { actionItems, LabResultsActions } from "../../../../../utils/Data";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import ReactQuill from "react-quill";
import { modules } from "../../../../../components/GlobalComponents/GlobalComponents";
import LabResultsPrescription from "../LabPrescription/LabResultsPrescription";
import NotesHeader from "../../GeneralNotes/NotesHeader";

const { Dragger } = Upload;

const MAX_FILE_SIZE = 5 * 1024 * 1024;

const headers = {
  "Content-Type": "multipart/form-data",
};

function Results() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [edit, setEdit] = useState(false);
  const [editRecord, setEditRecord] = useState([]);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [labID, setLabID] = useState(null);
  const [visible, setVisible] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [fileLoading, setFileLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [labResults, setLabResults] = useState([]);
  const [view, setView] = useState(null);
  const [description, setDescription] = useState("");
  const { templates, getTemplates, patientBasicInfo, getPatientData } =
    useStateGlobalContext();
  const [printData, setPrintData] = useState(null);

  const handleTemplateSelect = (value) => {
    const selectedTemplate = templates.find(
      (template) => template.title === value
    );
    form.setFieldsValue({
      description: selectedTemplate ? selectedTemplate.template : "",
    });
    setDescription(selectedTemplate ? selectedTemplate.template : "");
  };

  const handlePrint = (record) => {
    setPrintData({
      visible: true,
      data: record,
    });
  };

  const coloumns = [
    {
      key: 1,
      title: "Lab Order ID",
      dataIndex: "lab_order_id",
      width: "15%",
      render: (text) => (
        <Tooltip title="Click to copy Lab Order ID">
          <Typography.Text
            className="cursor-pointer text-[#1890ff]"
            onClick={() => {
              navigator.clipboard.writeText(text);
              Notification.success("Lab Order ID copied to clipboard");
            }}
          >
            {text}
          </Typography.Text>
        </Tooltip>
      ),
    },
    {
      key: 2,
      title: "Lab Name",
      dataIndex: "service_name",
      render: (text, record) => (
        <Button type="link" onClick={() => handleView(record)}>
          {text}
        </Button>
      ),
    },
    // {
    //   key: 3,
    //   title: "Description",
    //   dataIndex: "description",
    //   ellipsis: true,
    // },
    {
      key: 4,
      title: "Uploaded By",
      dataIndex: "added_by",
    },
    {
      key: 5,
      title: "Date",
      dataIndex: "date_time",
      render: (text) => (
        <Tooltip title={<FormatDateTime date={text} type="time" />}>
          <span>{text ? <FormatDateTime date={text} type="date" /> : ""}</span>
        </Tooltip>
      ),
    },
    {
      key: 5,
      title: "Time",
      dataIndex: "date_time",
      render: (text) => (
        <Tooltip title={<FormatDateTime date={text} type="time" />}>
          <span>{text ? <FormatDateTime date={text} type="time" /> : ""}</span>
        </Tooltip>
      ),
    },
    {
      key: 6,
      title: "Action",
      align: "center",
      width: "10%",
      render: (text, record) => (
        <Dropdown
          menu={{
            items: LabResultsActions.map((item) => ({
              ...item,
              onClick: () => {
                if (item.key === 1) {
                  handleView(record);
                } else if (item.key === 2) {
                  handleUpdate(record);
                } else if (item.key === 3) {
                  handlePrint(record);
                } else if (item.key === 4) {
                  handleDelete(record);
                }
              },
            })),
          }}
          trigger={["click"]}
        >
          <EllipsisOutlined className="cursor-pointer" />
        </Dropdown>
      ),
    },
  ];

  useEffect(() => {
    getTemplates({ showLabs: true });
  }, []);

  const handleView = async (record) => {
    await handleFile(record?.uploaded_file_encrypted_name);
    setView({
      viewState: true,
      viewData: record,
    });
  };

  const handleUpdate = (record) => {
    setEdit(true);
    setEditRecord(record);
    setVisible(true);
    form.setFieldsValue({
      lab_order_id: record?.lab_order_id,
      service_name: record?.service_name,
      description: record?.description,
    });
  };

  const closeView = () => {
    setView(null);
  };

  const handleFile = async (record) => {
    setFileLoading(true);
    try {
      const response = await doGet(`/upload/media/retrieve/${record}/`);
      if (response.status === 200) {
        const fileContent = response.data;
        setFileData(fileContent);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFileLoading(false);
    }
  };

  const handleCancel = () => {
    showConfirmModal({
      title: "Confirm Close?",
      content: "Are you sure? Current Data will be Lost",
      okText: "Close",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk: () => {
        setVisible(false);
        form.resetFields();
        setEdit(false);
        setEditRecord([]);
      },
    });
  };

  const handleReset = () => {
    showConfirmModal({
      title: "Confirm Clear?",
      content: "Are you sure? Current Data will be Lost",
      okText: "Clear",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk: () => {
        form.resetFields();
      },
    });
  };

  const onSelect = (value, option) => {
    const selectedLabID = options.find((item) => item.value === value);
    if (selectedLabID) {
      setLabID(selectedLabID.value);
    }
  };

  const handleSearch = useCallback(
    debounce(async (value) => {
      if (value) {
        try {
          const response = await doGet(`/labs/images/get-labIds/${id}/`);
          if (response.status === 200) {
            const filteredOptions = response.data?.luids?.map((lab, idx) => ({
              value: lab?.externalUID ? lab.externalUID : lab.inhouseUID,
              key: idx,
              label: (
                <div key={idx} className="flex justify-between">
                  <span>
                    {lab?.externalUID ? lab.externalUID : lab.inhouseUID}{" "}
                  </span>
                </div>
              ),
            }));
            setOptions(filteredOptions);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }, 300),
    []
  );

  const beforeUpload = (file) => {
    if (file.size > MAX_FILE_SIZE) {
      Notification.error("File size exceeds the maximum limit (5MB)");
      return false;
    }

    if (fileList.length === 1) {
      Notification.warning("You can only upload one file.");
      return false;
    }

    setFileList([file]);
    return false;
  };

  const onRemove = (file) => {
    const index = fileList.indexOf(file);
    const newFileList = [...fileList];
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const handleLabResults = async (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("lab_order_id", values.lab_order_id);
    formData.append("service_name", values.service_name);
    formData.append("description", values?.description || "");
    fileList.forEach((file) => {
      formData.append("file", file);
    });

    const data = {
      lab_order_id: values.lab_order_id,
      service_name: values.service_name,
      description: values.description,
    };

    try {
      const url = edit
        ? `/patient/update-lab-results/${id}/${editRecord?.id}/`
        : `/patient/add-lab-results/${id}/`;
      const response = edit
        ? await doPatch(url, data)
        : await doPost(url, formData, headers);
      if (response.status === 201 || response.status === 200) {
        edit
          ? Notification.success("Patient File(s) Record Updated Successfully")
          : Notification.success("Patient File(s) Uploaded Successfully");
        getLabResults();
        setVisible(false);
        setFileList([]);
        form.resetFields();
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setLoading(false);
    }
  };

  const getLabResults = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/patient/get-lab-results/${id}/`);
      if (response.status === 200) {
        const sortedLabResults = response.data.sort((a, b) => {
          return new Date(b.date_time) - new Date(a.date_time);
        });
        setLabResults(sortedLabResults);
      }
    } catch (error) {
      Notification.error("Something Went Wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (record) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        deleteUploadedFile(record);
      },
    });
  };

  const deleteUploadedFile = async (record) => {
    setLoading(true);
    try {
      const response = await doDelete(
        `/patient/delete-lab-results/${id}/${record.id}/`
      );
      if (response.status === 200) {
        Notification.success("File Deleted Successfully");
        getLabResults();
      }
    } catch (error) {
      console.error("Error deleting file:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLabResults();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await Promise.all([getPatientData(id)]);
      } catch (error) {
        Notification.error("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  return (
    <Card
      className="shadow-class"
      title="Results"
      extra={
        <Space>
          <Button
            type="primary"
            onClick={() => {
              setVisible(true);
            }}
          >
            Upload File & Results
          </Button>
        </Space>
      }
    >
      <CustomTable
        loading={loading}
        columns={coloumns}
        dataSource={labResults}
        key="lab_results"
      />

      <Modal
        title={edit ? "Update Lab Results" : "Upload Lab Result"}
        open={visible}
        maskClosable={false}
        footer={null}
        width={900}
        closeIcon={
          <Button
            size="small"
            className="app-close-icon"
            shape="round"
            onClick={handleCancel}
          >
            Close
          </Button>
        }
      >
        <NotesHeader />
        <Form layout="vertical" form={form} onFinish={handleLabResults}>
          <Form.Item
            label="Lab Order ID"
            name="lab_order_id"
            rules={[{ required: true, message: "Lab Order ID is Required" }]}
          >
            <AutoComplete
              options={options}
              allowClear={true}
              onSelect={onSelect}
              onSearch={handleSearch}
              placeholder="Search Ordered Labs here"
            />
          </Form.Item>
          <Form.Item
            label="Lab Name"
            name="service_name"
            tooltip="Enter each lab with comma ,"
          >
            <Input placeholder="Enter Lab Name" size="medium" maxLength={30} />
          </Form.Item>
          <Form.Item name="template" label="Choose Lab Template">
            <Select
              placeholder="Choose Lab Template"
              onSelect={handleTemplateSelect}
            >
              {templates &&
                templates?.map((item) => (
                  <Select.Option key={item.id} value={item.title}>
                    {item.title}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label="Lab Results & Description" name="description">
            <ReactQuill
              theme="snow"
              modules={modules}
              value={description}
              style={{ height: "300px", marginBottom: "1.5rem" }}
            />
          </Form.Item>
          {!edit && (
            <Form.Item name="file" label="File">
              <Dragger
                fileList={fileList}
                beforeUpload={beforeUpload}
                onRemove={onRemove}
                accept=".pdf, .jpg, .jpeg, .png"
                multiple={false}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined className="upload__icon" />
                </p>
                <p className="ant-upload-text">
                  Click or drag pdf, jpg, jpeg files to this area to upload
                </p>
                <p className="ant-upload-hint">Maximum size: 5MB.</p>
              </Dragger>
            </Form.Item>
          )}

          <div className="flex justify-end">
            <Button onClick={handleReset}>Clear</Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className="ml-2"
            >
              {edit ? "Update" : "Save"}
            </Button>
          </div>
        </Form>
      </Modal>
      <ViewLabResults
        visible={view?.viewState}
        onClose={closeView}
        data={view?.viewData}
        fileData={fileData}
        loading={fileLoading}
      />

      <LabResultsPrescription
        visible={printData?.visible}
        onClose={() =>
          setPrintData({
            visible: false,
            data: null,
          })
        }
        data={printData?.data}
        patient={patientBasicInfo}
      />
    </Card>
  );
}

export default Results;
