import React from "react";
import "react-quill/dist/quill.snow.css";
import { Button, Modal } from "antd";
import ViewTemplate from "../../Templates/components/ViewTemplate";
import FormatDateTime from "../../../../components/FormatDate/FormatDateTime";
import LabelDisplay from '../../../../components/LabelDisplay/LabelDisplay'

const ViewEncounter = ({ open, onClose, selectedEncounterData }) => {


  return (
    <Modal
      title={selectedEncounterData?.note_type}
      open={open}
      onCancel={onClose}
      width={1200}
      footer={null}
      closeIcon={
        <Button size="small" className="app-close-icon" shape="round">
          Close
        </Button>
      }
    >
      <div>
        <LabelDisplay title="Started By" description={selectedEncounterData?.provider_name}/>
        <LabelDisplay title="Encounter Start Date" description={<FormatDateTime date={selectedEncounterData?.encounter_start_date} type="datetime"/>}/>
        <LabelDisplay title="Note Type" description={selectedEncounterData?.note_type}/>
        <LabelDisplay title="Service Name" description={selectedEncounterData?.service_name}/>
        <LabelDisplay title="Reason for Visit" description={selectedEncounterData?.reason_of_visit}/>
        <LabelDisplay title="Signed By" description={selectedEncounterData?.signed_by}/>
        <LabelDisplay title="Signed Date" description={selectedEncounterData?.date_time && <FormatDateTime date={selectedEncounterData?.date_time} type="datetime"/>}/>
        <LabelDisplay title="Encounter Details" description={selectedEncounterData?.notes}/>
        <ViewTemplate template={selectedEncounterData?.encounter_details}/>
      </div>
    </Modal>
  ); 
};

export default ViewEncounter;
