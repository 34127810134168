import { Card } from 'antd'

const UsefullInformation = () => {
  return (
    <Card>
     <p className="text-lg font-semibold mb-4">Useful Information</p>
    <p>
      Welcome to your software User Management page. Here, you can assign
      Admin, Provider, and Nurse roles within this application.
    </p>
    <p className="font-semibold mt-4">
      User Management and Billing Information
    </p>
    <p>
      <span className="font-medium">Adding Users:</span> When you add a
      new user during a calendar month, a fee of $5 will be applied for
      that user within the same month.
    </p>
    <p>
      <span className="font-medium">Removing Users:</span> If a user is
      removed during the calendar month, the charge for that user will
      be removed starting from the following month.
    </p>
    <p className="font-semibold mt-4">Remove a User</p>
    <p>
      You can remove a user by clicking the three-dot action button and
      selecting "remove." When you remove a user, they will be moved to
      the Historical user data table. This ensures that user names
      remain visible throughout the application for accurate data
      management and patient record-keeping.
    </p>
    <p>
      This policy ensures transparent and fair billing practices while
      maintaining the integrity of your data and records.
    </p>
    <p>
      For more detailed information, please refer to our{" "}
      <a
        href="https://www.americanemr.com/terms--conditions.html"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms and Conditions
      </a>
      .
    </p>
  </Card>
  )
}

export default UsefullInformation