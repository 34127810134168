import React, { useEffect, useState } from "react";
import { Table, Button } from "antd";
import { doGet } from "../../../../../API/apis";
import { formatDate } from "../../../../../utils/DateFormat";
import { CSVLink } from "react-csv";
import Notification from "../../../../../components/Notification/Notification";
import FormatDate from "../../../../../components/FormatDate/FormatDate";
import DateRangePickerCard from "../../../../../components/GlobalComponents/DateRangePickerCard";
import { DetailCard } from "../../../../../components/GlobalComponents/GlobalComponents";


const columns = [
  { title: 'ID', dataIndex: 'id', key: 'id' },
  { title: 'MRN Number', dataIndex: 'mrn_number', key: 'mrn_number' },
  { title: 'First Name', dataIndex: 'first_name', key: 'first_name' },
  { title: 'Middle Name', dataIndex: 'middle_name', key: 'middle_name' },
  { title: 'Last Name', dataIndex: 'last_name', key: 'last_name' },
  { title: 'Date of Birth', dataIndex: 'date_of_birth', key: 'date_of_birth', render:(text)=> <FormatDate date={text}/> },
  { title: 'Gender', dataIndex: 'gender', key: 'gender' },
  { title: 'Sexual Orientation', dataIndex: 'sexual_orientation', key: 'sexual_orientation' },
  { title: 'Marital Status', dataIndex: 'marital_status', key: 'marital_status' },
  { title: 'Employment Status', dataIndex: 'employment', key: 'employment' },
  { title: 'Guardian First Name', dataIndex: 'guardian_first_name', key: 'guardian_first_name' },
  { title: 'Guardian Middle Name', dataIndex: 'guardian_middle_name', key: 'guardian_middle_name' },
  { title: 'Guardian Last Name', dataIndex: 'guardian_last_name', key: 'guardian_last_name' },
  { title: 'Guardian Phone Number', dataIndex: 'guardian_phone_number', key: 'guardian_phone_number' },
  { title: 'Guardian Email', dataIndex: 'guardian_email', key: 'guardian_email' },
  { title: 'Basic Information Notes', dataIndex: 'basic_information_notes', key: 'basic_information_notes' },
  { title: 'Patient Phone', dataIndex: 'patient_phone', key: 'patient_phone' },
  { title: 'Patient Email', dataIndex: 'patient_email', key: 'patient_email' },
  { title: 'Phone Decline', dataIndex: 'phone_decline', key: 'phone_decline' },
  { title: 'Email Decline', dataIndex: 'email_decline', key: 'email_decline' },
  { title: 'Patient Home', dataIndex: 'patient_home', key: 'patient_home' },
  { title: 'Patient Work', dataIndex: 'patient_work', key: 'patient_work' },
  { title: 'Extension', dataIndex: 'extension', key: 'extension' },
  { title: 'Patient Contact Notes', dataIndex: 'patient_contact_notes', key: 'patient_contact_notes' },
  { title: 'Emergency First Name', dataIndex: 'emergency_first_name', key: 'emergency_first_name' },
  { title: 'Emergency Last Name', dataIndex: 'emergency_last_name', key: 'emergency_last_name' },
  { title: 'Emergency Phone Number', dataIndex: 'emergency_phone_number', key: 'emergency_phone_number' },
  { title: 'Emergency Relationship', dataIndex: 'emergency_relationship', key: 'emergency_relationship' },
  { title: 'Emergency Notes', dataIndex: 'emergency_notes', key: 'emergency_notes' },
  { title: 'Current Address Line 1', dataIndex: 'current_address_line_1', key: 'current_address_line_1' },
  { title: 'Current Address Line 2', dataIndex: 'current_address_line_2', key: 'current_address_line_2' },
  { title: 'Current State', dataIndex: 'current_state', key: 'current_state' },
  { title: 'Current Zipcode', dataIndex: 'current_zipcode', key: 'current_zipcode' },
  { title: 'Current Country', dataIndex: 'current_country', key: 'current_country' },
  { title: 'Current Address Notes', dataIndex: 'current_address_notes', key: 'current_address_notes' },
  { title: 'Current City', dataIndex: 'current_city', key: 'current_city' },
  { title: 'Mailing Address Line 1', dataIndex: 'mailing_address_line_1', key: 'mailing_address_line_1' },
  { title: 'Mailing Address Line 2', dataIndex: 'mailing_address_line_2', key: 'mailing_address_line_2' },
  { title: 'Mailing City', dataIndex: 'mailing_city', key: 'mailing_city' },
  { title: 'Mailing State', dataIndex: 'mailing_state', key: 'mailing_state' },
  { title: 'Mailing Zipcode', dataIndex: 'mailing_zipcode', key: 'mailing_zipcode' },
  { title: 'Mailing Country', dataIndex: 'mailing_country', key: 'mailing_country' },
  { title: 'Mailing Address Notes', dataIndex: 'mailing_address_notes', key: 'mailing_address_notes' },
  { title: 'Race', dataIndex: 'race', key: 'race' },
  { title: 'Religion', dataIndex: 'religion', key: 'religion' },
  { title: 'Ethnicity', dataIndex: 'ethnicity', key: 'ethnicity' },
  { title: 'Languages', dataIndex: 'languages', key: 'languages' },
  { title: 'Demographics Notes', dataIndex: 'demographics_notes', key: 'demographics_notes' },
  { title: 'Referral Full Name', dataIndex: 'referal_full_name', key: 'referal_full_name' },
  { title: 'Referral Specialty', dataIndex: 'referal_speciality', key: 'referal_speciality' },
  { title: 'Referral Phone', dataIndex: 'referal_phone', key: 'referal_phone' },
  { title: 'Referral Email', dataIndex: 'referal_email', key: 'referal_email' },
  { title: 'Referral Fax', dataIndex: 'referal_fax', key: 'referal_fax' },
  { title: 'Referral Organization Name', dataIndex: 'referal_organization_name', key: 'referal_organization_name' },
  { title: 'Referral Group Name', dataIndex: 'referal_group_name', key: 'referal_group_name' },
  { title: 'WDYHAU', dataIndex: 'wdyhau', key: 'wdyhau' },
  { title: 'Referral Notes', dataIndex: 'referal_notes', key: 'referal_notes' },
  { title: 'Gov ID', dataIndex: 'govId', key: 'govId' },
  { title: 'Passport', dataIndex: 'passport', key: 'passport' },
  { title: 'License', dataIndex: 'license', key: 'license' },
  { title: 'SSN', dataIndex: 'ssn', key: 'ssn' },
  { title: 'ID Notes', dataIndex: 'id_notes', key: 'id_notes' },
  { title: 'Payment Services', dataIndex: 'payment_services', key: 'payment_services' },
  { title: 'Payment Services Notes', dataIndex: 'payment_services_notes', key: 'payment_services_notes' },
  { title: 'Registration Date', dataIndex: 'registration_date', key: 'registration_date' },
  { title: 'Insurance Card', dataIndex: 'insurance_card', key: 'insurance_card' },
];

const PatientList = () => {
  const [loading, setLoading] = useState(false);
  const [patientList, setPatientList] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [searchClicked, setSearchClicked] = useState(false);

  const handleDateChange = (dates) => {
    setSelectedDateRange(dates);
  };

  const handleSearch = () => {
    if (selectedDateRange && selectedDateRange.length === 2) {
      setSearchClicked(true);
      getPatientList();
    } else {
      Notification.warning("Please select a date range");
    }
  };

  const getPatientList = async () => {
    setLoading(true);
    try {
      if (selectedDateRange && selectedDateRange.length === 2) {
        const startDate = selectedDateRange[0].format("YYYY-MM-DD");
        const endDate = selectedDateRange[1].format("YYYY-MM-DD");

        const response = await doGet(
          `/patient/patients/filter/?from_date=${startDate}&till_date=${endDate}`
        );

        if (response.status === 200) {
          setPatientList(response.data);
        }
      } else {
        Notification.warning("Please select a valid date range");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const transformAppointmentsForCSV = (data) => {
    if (!data) {
      return [];
    }

    return data?.map((patient) => ({
      "First Name": patient?.first_name,
      "Middle Name": patient?.middle_name,
      "Last Name": patient?.last_name,
      "DOB": formatDate(patient?.date_of_birth),
      "Gender": patient?.gender,
      "Sexual Orientation": patient?.sexual_orientation,
      "Marital Status": patient?.marital_status,
      "Guardian First Name": patient?.guardian_first_name,
      "Guardian Middle Name": patient?.guardian_middle_name,
      "Guardian Last Name": patient?.guardian_last_name,
      "Guardian Phone Number": patient?.guardian_phone_number,
      "Guardian Email": patient?.guardian_email,
      "Basic Information Notes": patient?.basic_information_notes,
      "Patient Phone": patient?.patient_phone,
      "Patient Email": patient?.patient_email,
      "Phone Decline": patient?.phone_decline,
      "Email Decline": patient?.email_decline,
      "Patient Home": patient?.patient_home,
      "Patient Work": patient?.patient_work,
      "Extension": patient?.extension,
      "Patient Contact Notes": patient?.patient_contact_notes,
      "Emergency First Name": patient?.emergency_first_name,
      "Emergency Last Name": patient?.emergency_last_name,
      "Emergency Phone Number": patient?.emergency_phone_number,
      "Emergency Relationship": patient?.emergency_relationship,
      "Emergency Notes": patient?.emergency_notes,
      "Current Address Line 1": patient?.current_address_line_1,
      "Current Address Line 2": patient?.current_address_line_2,
      "Current State": patient?.current_state,
      "Current Zipcode": patient?.current_zipcode,
      "Current Country": patient?.current_country,
      "Current Address Notes": patient?.current_address_notes,
      "Current City": patient?.current_city,
      "Mailing Address Line 1": patient?.mailing_address_line_1,
      "Mailing Address Line 2": patient?.mailing_address_line_2,
      "Mailing City": patient?.mailing_city,
      "Mailing State": patient?.mailing_state,
      "Mailing Zipcode": patient?.mailing_zipcode,
      "Mailing Country": patient?.mailing_country,
      "Mailing Address Notes": patient?.mailing_address_notes,
      "Race": patient?.race,
      "Religion": patient?.religion,
      "Ethnicity": patient?.ethnicity,
      "Languages": patient?.languages,
      "Demographics Notes": patient?.demographics_notes,
      "Referral Full Name": patient?.referal_full_name,
      "Referral Speciality": patient?.referal_speciality,
      "Referral Phone": patient?.referal_phone,
      "Referral Email": patient?.referal_email,
      "Referral Fax": patient?.referal_fax,
      "Referral Organization Name": patient?.referal_organization_name,
      "Referral Group Name": patient?.referal_group_name,
      "How Did You Hear About Us": patient?.wdyhau,
      "Referral Notes": patient?.referal_notes,
      "Government ID": patient?.govId,
      "Passport": patient?.passport,
      "License": patient?.license,
      "SSN": patient?.ssn,
      "ID Notes": patient?.id_notes,
      "Payment Services": patient?.payment_services,
      "Payment Services Notes": patient?.payment_services_notes,
      "MRN Number": patient?.mrn_number,
      "Registration Date": formatDate(patient?.registration_date),
      "Insurance Card": patient?.insurance_card,
      "Employment": patient?.employment,
      "Clinic": patient?.clinic,
      "Doctor": patient?.doctor,
    }));
  };

  const handleReset = ()=>{
    setPatientList([])
  }

  useEffect(() => {
    if (searchClicked) {
      getPatientList();
      setSearchClicked(false);
    }
  }, [searchClicked, selectedDateRange]);

  return (


      <DetailCard title="Patients List">
      <DateRangePickerCard
        onDateChange={handleDateChange}
        onSearch={handleSearch}
        onReset={handleReset}
      />
        <div className="flex justify-between items-center mb-2">
          <h1>Search Results</h1>
          {patientList?.length > 0 && (
            <Button type="primary">
              <CSVLink
                data={transformAppointmentsForCSV(patientList)}
                filename={"Patient_list.csv"}
                className="csv-link"
              >
                Download Report
              </CSVLink>
            </Button>
          )}
        </div>
        <Table
          loading={loading}
          columns={columns}
          dataSource={patientList}
          size="small"
          scroll={{
            x: "max-content",
          }}
        />
      </DetailCard>
  );
};

export default PatientList;
