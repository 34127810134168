import React, { useState, useEffect, useContext } from "react";
import {
  PlusOutlined,
  UserOutlined,
  UserAddOutlined,
  CalendarOutlined,
  LogoutOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import FullScreenButton from "../Fullscreen/Fullscreen";
import Searchbar from "../Searchbar/Searchbar";
import { Avatar, Dropdown } from "antd";
import { Link } from "react-router-dom";
import CalenderModal from "../CalenderModal/CalenderModal";
import { setSession, getSession, clearSession } from "../../customHooks/index";
import { useStateGlobalContext } from "../../contexts/GlobalContext";
import ChangePasswordModal from "../ChangePasswordModal/ChangePasswordModal";
import GuestCheckoutDrawer from "../GuestCheckout/GuestCheckout";
// import ThemeContext from "../../contexts/ThemeContext";
import { IoHelpBuoyOutline } from "react-icons/io5";


function Header({ collapsed, toggleSidebar }) {
  const [visible, setVisible] = useState(false);
  const [guestCheckoutVisible, setGuestCheckoutVisible] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [changePasswordModalVisible, setChangePasswordModalVisible] = useState(
    getSession("changePassword")
  );
  const {
    officeSetting,
    profile,
    userData,
    getProfile,
    getUserInfo
  } = useStateGlobalContext();

  useEffect(()=>{
    getUserInfo()
    getProfile()
  },[])

  const role = profile && profile?.user_info[0]?.role;


  const closeChangePasswordModal = () => {
    setChangePasswordModalVisible(false);
    setSession({ changePassword: false });
  };


  const handleGuestCheckout = () => {
    setGuestCheckoutVisible(true);
  };

  const closeGuestCheckoutVisible = () => {
    setGuestCheckoutVisible(false);
  };

  const closeAppointmentModal = () => {
    setVisible(false);
  };


  const items = [
    {
      key: "1",
      icon: <UserOutlined />,
      label: (
        <Link to="/main/profile">
          <span>Provider Settings</span>
        </Link>
      ),
    },
 
    {
      key:"4",
      icon: <IoHelpBuoyOutline size={18} className="ml-[-2px]"/>,
      label: (
        <a href="https://www.americanemr.com/support.html" target="_blank" rel="noopener noreferrer">
          Support
        </a>
      )
    },
    {
      key: "3",
      icon: <LogoutOutlined />,
      label: (
        <span
          onClick={() => {
            clearSession();
          }}
        >
          Logout
        </span>
      ),
      onClick: () => clearSession(), 
    },

  ];

  const plusOutlinedMenu = [
    {
      key: "4",
      icon: <UserAddOutlined />,
      label: (
        <Link to="/main/register_patient">
          <div>Register Patient</div>
        </Link>
      ),
    },
    // {
    //   key: "5",
    //   icon: <CalendarOutlined />,
    //   label: <span onClick={handleAppointentModal}>Make Appointment</span>,
    // },
    {
      key: "6",
      icon: <CalendarOutlined />,
      label: <div onClick={handleGuestCheckout}>Guest Checkout</div>,
    },
  ];

  const user_full_name = `${profile?.user_info[0]?.first_name} ${profile?.user_info[0]?.last_name}`

  return (
    <>
      {profile && (
        <div className="flex justify-between items-center mt-1 px-2 text-black shadow-sm">
          <div className="flex">
            {collapsed && (
              <div className="cursor-pointer" onClick={toggleSidebar}>
                <MenuOutlined />
              </div>
            )}
            {!collapsed && (
              <div className="cursor-pointer" onClick={toggleSidebar}>
                <MenuOutlined />
              </div>
            )}
            <div className="ml-2 flex items-center">
              {officeSetting && officeSetting?.user_office_logo && (
                <img
                  src={officeSetting?.user_office_logo}
                  width="50px"
                  height={"50px"}
                  alt="clinic_logo"
                />
              )}
              <h1 className="ml-2">
                {officeSetting && officeSetting?.facility_name[1]}
              </h1>
            </div>
          </div>

          <div className="flex justify-end items-center h-full px-3 py-2">
            <div className="flex items-center">
              <Searchbar search_width="200px" navigate_to={true} role={role}/>
            </div>
            <span className="mr-2">
              <FullScreenButton />
            </span>

           {role !== "biller" &&  <Dropdown
              menu={{ items: plusOutlinedMenu }}
              placement="bottomRight"
              trigger={["click"]}
            >
              <span className="mr-2">
                <PlusOutlined
                  className="p-1 bg-[#1890ff] rounded-full cursor-pointer"
                  style={{
                    // fontSize: "20px",
                    color: "#FFF",
                  }}
                />
              </span>
            </Dropdown>}

            <Dropdown
              menu={{ items }}
              open={isDropdownVisible}
              onOpenChange={setDropdownVisible}
            >
              <div className="flex items-center px-3 py-2 rounded-full cursor-pointer">
                <div className="mr-2">
                  <p className="text-sm font-semibold">{user_full_name}</p>
                  <p className="text-xs">
                    {role === "clinic"
                      ? "Owner"
                      : role === 'doctor' ? "Provider" : role === 'manager' ? 'Admin' : role === "biller"? "Biller" : 'Nurse'}
                  </p>
                </div>
                <Avatar shape="circle" src={userData?.user_profile_picture?.length > 0 && userData?.user_profile_picture }>
                  {userData?.user_profile_picture?.length === 0 &&
                    profile?.user_info[0]?.first_name?.charAt(0).toUpperCase()}
                </Avatar>
              </div>
            </Dropdown>
          </div>

          <CalenderModal visible={visible} onClose={closeAppointmentModal} />
          <GuestCheckoutDrawer
            visible={guestCheckoutVisible}
            onClose={closeGuestCheckoutVisible}
          />
        </div>
      )}

      {changePasswordModalVisible && (
        <ChangePasswordModal
          showLogout={true}
          open={changePasswordModalVisible}
          onClose={closeChangePasswordModal}
        />
      )}
    </>
  );
}

export default Header;
