import React, { useState, lazy, Suspense } from "react";
import { Tabs } from "antd";
import Title from "../../../components/Title/Title";
import { useStateGlobalContext } from "../../../contexts/GlobalContext";

const PersonalSetting = lazy(() => import("./components/PersonalSetting"));
const WorkSchedule = lazy(() => import("./components/WorkSchedule"));
const MedicalIdentfiers = lazy(() => import("./components/MedicalIdentifiers"));

const tabPanes = [
  { label: "Personal Settings", key: "1", component: <PersonalSetting /> },
  { label: "Work Schedule", key: "2", component: <WorkSchedule /> },
  { label: "Medical Identifiers", key: "3", component: <MedicalIdentfiers /> },
];

function UserSetting() {
  const [activeKey, setActiveKey] = useState("1");
  const { profile } = useStateGlobalContext();

  const role = profile && profile?.user_info[0]?.role;

  // Filter out the "Medical Identifiers" tab if the role is "biller"
  const filteredTabPanes =
    role === "biller"
      ? tabPanes.filter((pane) => pane.key !== "3")
      : tabPanes;

  const handleTabClick = (key) => {
    setActiveKey(key);
  };

  document.title = "Personal / Provider Profile Setting | American EMR";

  return (
    <div>
      <Title title="Personal / Provider Profile Setting" />
      <Tabs
        activeKey={activeKey}
        defaultActiveKey="1"
        onTabClick={handleTabClick}
      >
        {filteredTabPanes.map((pane) => (
          <Tabs.TabPane tab={pane.label} key={pane.key}>
            {activeKey === pane.key && <Suspense>{pane.component}</Suspense>}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
}

export default UserSetting;
