import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card, Collapse } from "antd";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import FormatDate from "../../../../components/FormatDate/FormatDate";
import LabelDisplay from "../../../../components/LabelDisplay/LabelDisplay";
const { Panel } = Collapse;

const HistoricalMedication = () => {
  const { id } = useParams();
  const { data, getHistoricalMedication } = useStateGlobalContext();

  useEffect(() => {
    getHistoricalMedication(id);
  }, [id]);

  const hasHistoricalMedication = data && data.length > 0;

  if (!hasHistoricalMedication) {
    return null;
  }

  return (
    <Card className="mt-3" title="Historical Prescribed Medication">
      <Collapse accordion className="max-h-[150px] overflow-auto">
        {data?.map((item, index) => (
          <Panel
            header={`${item?.historical_medication?.searched_medicine} (${
              item?.historical_medication?.end_date ? (
                <FormatDate date={item?.historical_medication?.end_date} />
              ) : (
                "No Date Entered"
              )
            })`}
            key={index}
          >
            <div>
              <LabelDisplay
                title={"Instructions"}
                description={item?.historical_medication?.instructions}
              />
              <LabelDisplay
                title={"Refills"}
                description={item?.historical_medication?.refills}
              />
              <LabelDisplay
                title={"Dispense Quantity"}
                description={item?.historical_medication?.dispense_quantity}
              />
              <LabelDisplay
                title={"Start Date"}
                description={
                  item?.historical_medication?.start_date ? (
                    <FormatDate
                      date={item?.historical_medication?.start_date}
                    />
                  ) : (
                    "No Date Entered"
                  )
                }
              />
              <LabelDisplay
                title={"End Date"}
                description={
                  item?.historical_medication?.end_date ? (
                    <FormatDate date={item?.historical_medication?.end_date} />
                  ) : (
                    "No Date Entered"
                  )
                }
              />
              <LabelDisplay
                title={"Dispense as Written"}
                description={
                  item?.historical_medication?.dispense_as_written?.toString() ===
                  "true"
                    ? "Yes"
                    : "No"
                }
              />
              <LabelDisplay
                title={"Associated Diagnosis"}
                description={item?.historical_medication?.associated_diagnosis}
              />
            </div>
          </Panel>
        ))}
      </Collapse>
    </Card>
  );
};

export default HistoricalMedication;
