import React from "react";
import { Menu, Layout } from "antd";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";

const { Content } = Layout;

const items = [
  {
    label: "Summary",
    key: "",
    role: ["clinic", "manager", "nurse", "doctor"],
  },
  {
    label: "Visits",
    key: "visits",
    role: ["clinic", "manager", "nurse", "doctor", "biller"],
  },
  {
    label: "General Notes",
    key: "general-notes",
    role: ["clinic", "manager", "nurse", "doctor", "biller"],
  },
  {
    label: "Medications",
    key: "medications",
    role: ["clinic", "manager", "nurse", "doctor", "biller"],
  },
  {
    label: "Lab & Imaging",
    key: "labs&imaging",
    role: ["clinic", "manager", "nurse", "doctor", "biller"],
  },
  {
    label: "Invoice & Billing",
    key: "invoice&billing",
    role: ["clinic", "manager", "nurse", "doctor", "biller"],
  },
  {
    label: "Memberships",
    key: "memberships",
    role: ["clinic", "manager", "nurse", "doctor"],
  },
  {
    label: "Patient Portal",
    key: "patient-portal",
    role: ["clinic", "manager", "nurse", "doctor"],
  },
  {
    label: "Referral",
    key: "patient-referral",
    role: ["clinic", "manager", "nurse", "doctor", "biller"],
  },
  {
    label: "Files",
    key: "patient-files",
    role: ["clinic", "manager", "nurse", "doctor", "biller"],
  },
  {
    label: "Insurances",
    key: "insurances",
    role: ["clinic", "manager", "nurse", "doctor", "biller"],
  },
  {
    label: "Profile",
    key: "profile",
    role: ["clinic", "manager", "nurse", "doctor", "biller"],
  },
];

const PatientTabs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { officeSetting, profile } = useStateGlobalContext();

  const officeDateFormat = officeSetting?.date_format;
  const officeTimeFormat = officeSetting?.time_format;
  const officeTimezone = officeSetting?.facility_time_zone;
  const role = profile && profile?.user_info[0]?.role;

  const filteredMenuItems = items.filter(item => item.role.includes(role));

  // Extract the last segment of the URL
  const lastPathSegment = location.pathname.split("/").filter(Boolean).pop();

  return (
    <Layout style={{ backgroundColor: "transparent" }}>
      <Menu
        onClick={({ key }) => navigate(key)}
        mode="horizontal"
        selectedKeys={[lastPathSegment]} // Set the last path segment as the selected key
        items={filteredMenuItems}
        className="summary-menu mb-4 bg-white font-medium tracking-[0.2px]"
      />
      <Content
        className="layout"
        style={{
          backgroundColor: "white",
        }}
      >
        <Outlet
          context={[officeDateFormat, officeTimeFormat, officeTimezone]}
        />
      </Content>
    </Layout>
  );
};

export default PatientTabs;
