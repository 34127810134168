import { useEffect, useState, useContext, useRef } from "react";
import { Layout, theme, Spin } from "antd";
import AppHeader from "../../components/Header/Header";
import { useMediaQuery } from "react-responsive";
import { Outlet } from "react-router-dom";
import "./layout.css";
import { useStateGlobalContext } from "../../contexts/GlobalContext";
import ThemeContext from "../../contexts/ThemeContext";
import SideNav from "./SideNav";
import HeaderNav from "./HeaderNav";

export const COLOR = {
  50: "#e0f1ff",
  100: "#b0d2ff",
  200: "#7fb0ff",
  300: "#4d8bff",
  400: "#1e79fe",
  500: "#076ee5",
  600: "#0062b3",
  700: "#004f81",
  800: "#003650",
  900: "#001620",
  borderColor: "#E7EAF3B2",
};

const { Content } = Layout;
const ApplicationLayout = () => {
  const [collapsed, setCollapsed] = useState(true);
  const { theme: appTheme } = useContext(ThemeContext);
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const [navFill, setNavFill] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const autoCloseTimerRef = useRef(null); // Ref to track timeout

  const {
    officeSetting,
    getOfficeSettings,
    appLogoData,
    getAppLogo,
    profile,
    getProfile,
  } = useStateGlobalContext();

  const role = profile?.user_info?.[0]?.role;

  const {
    token: { borderRadius },
  } = theme.useToken();

  useEffect(() => {
    const fetchData = async () => {
      await getAppLogo();
      await getOfficeSettings();
      await getProfile();
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const officeDateFormat = officeSetting?.date_format;
  const officeTimeFormat = officeSetting?.time_format;
  const officeTimezone = officeSetting?.facility_time_zone;

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const toggleSidebar = ()=>{
    setCollapsed(!collapsed)
  }

  const handleMouseEnter = () => {
    if (autoCloseTimerRef.current) {
      clearTimeout(autoCloseTimerRef.current); // Clear existing auto-close timer
    }
    setCollapsed(false); // Open the sidebar
  };

  const handleMouseLeave = () => {
    autoCloseTimerRef.current = setTimeout(() => {
      setCollapsed(true); 
    }, 300);
  };

  useEffect(() => {
    setCollapsed(isMobile);
  }, [isMobile]);

  useEffect(() => {
    const handleScroll = () => {
      setNavFill(window.scrollY > 5);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    return () => {
      if (autoCloseTimerRef.current) {
        clearTimeout(autoCloseTimerRef.current);
      }
    };
  }, []);

  return (
    <Layout
      style={{
        background: appTheme === "dark" ? "#fff9d8" : colorBgContainer,
        minHeight: "100vh",
      }}
    >
      {isLoading ? (
        <div className="flex items-center justify-center h-screen">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <SideNav
            trigger={null}
            collapsible
            collapsed={collapsed}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{
              overflow: "auto",
              position: "fixed",
              left: 0,
              top: 0,
              bottom: 0,
              background: appTheme === "dark" ? "#001529" : "#f7f7f7",
              border: "none",
              transition: "all .2s",
            }}
            role={role}
            logo={appLogoData}
          />
          <Layout>
            <HeaderNav
              style={{
                marginLeft: collapsed ? "80px" : "220px",
                padding: 0,
                background: "#fff",
                position: "sticky",
                top: 0,
                zIndex: 999,
              }}
            >
              <AppHeader collapsed={collapsed} toggleSidebar={toggleSidebar}/>
            </HeaderNav>
            <Content
              style={{
                margin: `0 0 0 ${collapsed ? "80px" : "220px"}`,
                background: colorBgContainer,
                borderRadius: collapsed ? 0 : borderRadius,
                transition: "all .25s",
                padding: "24px 32px",
                minHeight: 360,
              }}
            >
              <Outlet
                context={[officeDateFormat, officeTimeFormat, officeTimezone]}
              />
            </Content>
          </Layout>
        </>
      )}
    </Layout>
  );
};

export default ApplicationLayout;
