import React, { useState, useEffect } from "react";
import UserTable from "../../../../components/UserRoles/UserRoles";
import {
  Button,
  Modal,
  Form,
  Input,
  Select,
  Row,
  Col,
  Tooltip,
  Card,
} from "antd";
import {
  PlusOutlined,
  QuestionCircleFilled,
  CheckCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import Title from "../../../../components/Title/Title";
import Loader from "../../../../components/Loader/Loader";
import {
  validateUsername,
  validatePassword,
  validateName,
} from "../../../../utils/validator";
import axios from "axios";
import { getSession } from "../../../../customHooks";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import UsefullInformation from "../../../../components/UserRoles/UsefullInformation";
import Notification from "../../../../components/Notification/Notification";
import RolesInfo from "./Datasets/RolesInfo";
const { Option } = Select;

document.title = "User Management | American EMR";

const UserManagment = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  // const [deletedStaff, setDeletedStaff] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { users, getStaff, deletedStaff, getDeletedStaff } =
    useStateGlobalContext();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const onFinish = async (values) => {
    setIsModalVisible(false);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/register/`,
        {
          username: values.username,
          email: values.email,
          role: values.role,
          first_name: values.first_name,
          last_name: values.last_name,
          password: values.password,
          confirm_password: values.confirm_password,
        },
        {
          headers: { Authorization: `Bearer ${getSession("token")}` },
        }
      );

      if (response?.status === 201) {
        form.resetFields();
        Notification.success("OTP sent successfully. Please check your email");
        getStaff();
        setIsModalVisible(false);
      } else {
        form.resetFields();
        Notification.error("Username already exists");
      }
    } catch (error) {
      Notification.error("An error occurred while processing your request");
    }
  };

  const handleCancel = () => {
    form.resetFields();

    setIsModalVisible(false);
  };

  const mergedArray = [
    ...(users?.register_doctors || []),
    ...(users?.register_nurses || []),
    ...(users?.register_managers || []),
    ...(users?.register_billers|| []),
    ...(users?.pending_doctors || []),
    ...(users?.pending_nurses || []),
    ...(users?.pending_managers || []),
    ...(users?.pending_billers || []),
  ];

  const mergeUserArrays = (usersObject) => {
    const roles = ["Doctor", "Nurse", "Manager", "Biller"]; // add more roles as needed
    let mergedArray = [];

    roles.forEach((role) => {
      const roleArray =
        usersObject && usersObject[role] ? usersObject[role] : [];
      roleArray.forEach((item) => {
        if (Array.isArray(item)) {
          mergedArray = [...mergedArray, ...item]; // Flattening nested arrays
        } else {
          mergedArray.push(item);
        }
      });
    });

    return mergedArray;
  };

  const historicalUsersArray = mergeUserArrays(deletedStaff);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      await getStaff();
      await getDeletedStaff();

      setLoading(false);
    };
    fetchData();
  }, []);

  if (loading) {
    <Loader />;
  }
  document.title = "User Management | American EMR";
  return (
    <div>
      <div className="flex justify-between mb-3 mt-2">
        <Title title="User Management" />
      </div>
      <Row gutter={[16, 16]}>
        <Col md={24} lg={12}>
          <Card className="mb-10">
            <div className="flex justify-between">
              <p className="mb-2 font-bold text-md">
                Active Users
                <span>
                  <Tooltip title="Active and Pending Users will be displayed below">
                    <QuestionCircleFilled className="text-[#3484F0] ml-2" />
                  </Tooltip>
                </span>
              </p>
              <Button
                type="primary"
                className="bg-[#3484F0] flex items-center mb-2"
                onClick={showModal}
                icon={<PlusOutlined />}
              >
                Add User
              </Button>
            </div>

            <UserTable
              users={mergedArray}
              loading={loading}
              clinicStaff={getStaff}
              showActionButton={true}
            />
          </Card>

          <Card className="mb-10">
            <div className="flex justify-between">
              <p className="mb-2 font-bold text-md">
                Historical Users
                <span>
                  <Tooltip title="Historical Users will be displayed below">
                    <QuestionCircleFilled className="text-[#3484F0] ml-2" />
                  </Tooltip>
                </span>
              </p>
            </div>

            <UserTable
              users={historicalUsersArray}
              loading={loading}
              clinicStaff={getStaff}
              showActionButton={false}
            />
          </Card>
        </Col>
        <Col md={24} lg={12}>
          <UsefullInformation />
        </Col>
      </Row>

      <Modal
        title="Add Staff"
        open={isModalVisible}
        width={1200}
        footer={null}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
        onCancel={handleCancel}
      >
        <Row gutter={[16, 16]}>
          <Col sm={24} md={24} lg={10} xl={10}>
            <Form form={form} layout="vertical" onFinish={onFinish}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="First Name"
                    name="first_name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your first name!",
                      },
                      { validator: validateName },
                    ]}
                  >
                    <Input placeholder="First Name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Last Name"
                    name="last_name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your last name!",
                      },
                      { validator: validateName },
                    ]}
                  >
                    <Input placeholder="Last Name" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name="username"
                label="Username"
                rules={[
                  { required: true, message: "Please enter the username" },
                  { validator: validateUsername },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: "Please enter the email" },
                  { type: "email", message: "Please enter a valid email" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="role"
                label="Role"
                rules={[{ required: true, message: "Please select the role" }]}
              >
                <Select>
                  <Option value="manager">Manager / Sub Admin</Option>
                  <Option value="doctor">Doctor / Provider</Option>
                  <Option value="nurse">Nurse / Frontdesk</Option>
                  <Option value="biller">Biller</Option>
                </Select>
              </Form.Item>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      { required: true, message: "Please enter the password" },
                      { validator: validatePassword },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="confirm_password"
                    label="Confirm Password"
                    hasFeedback
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "Please confirm the password",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </Col>
              </Row>
              <div className="flex justify-end">
                <Button className="mr-2" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  Add Staff
                </Button>
              </div>
            </Form>
          </Col>

          <Col sm={24} md={24} lg={14} xl={14}>
              <RolesInfo/>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default UserManagment;
