import React from "react";
import { Avatar, Button, Modal, Table, Tooltip } from "antd";
import Loader from "../../components/Loader/Loader";

const StaffRecord = ({ show, onClose, data, loading }) => {
  const contact = data?.personel_settings_info[0]?.contact || {};
  const workSchedule = data?.work_schedule_info[0] || {};
  const getInitial = (name) => (name ? name.charAt(0).toUpperCase() : "");

  const scheduleData = [
    { day: "Monday", schedule: workSchedule.monday, note: workSchedule.monday_note },
    { day: "Tuesday", schedule: workSchedule.tuesday, note: workSchedule.tuesday_note },
    { day: "Wednesday", schedule: workSchedule.wednesday, note: workSchedule.wednesday_note },
    { day: "Thursday", schedule: workSchedule.thursday, note: workSchedule.thursday_note },
    { day: "Friday", schedule: workSchedule.friday, note: workSchedule.friday_note },
    { day: "Saturday", schedule: workSchedule.saturday, note: workSchedule.saturday_note },
    { day: "Sunday", schedule: workSchedule.sunday, note: workSchedule.sunday_note },
  ].map(({ day, schedule, note }) => {
    const [startTime, endTime, isAvailable] = schedule || ["N/A", "N/A", false];
    return {
      key: day,
      day,
      time: startTime ? `${startTime} - ${endTime}` : "N/A",
      availability: isAvailable ? "Available" : "Not Available",
      note: note || "N/A",
    };
  });

  const columns = [
    {
      title: "Day",
      dataIndex: "day",
      key: "day",
      align: "center",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      align: "center",
    },
    {
      title: "Availability",
      dataIndex: "availability",
      key: "availability",
      align: "center",
      render: (availability) => (
        <span
          className={
            availability === "Available"
              ? "text-green-500 font-semibold"
              : "text-red-500 font-semibold"
          }
        >
          {availability}
        </span>
      ),
    },
    {
      title: "Notes",
      dataIndex: "note",
      key: "note",
      align: "center",
      ellipsis: true,
      render:(text)=> <Tooltip title={text}> {text}</Tooltip>
    },
  ];

  return (
    <Modal
      title={<h2 className="text-xl font-bold">Weekly Schedule</h2>}
      open={show}
      footer={null}
      width={750}
      onCancel={onClose}
      closeIcon={
        <Button
          size="small"
          className="app-close-icon bg-red-500 text-white rounded-full"
          shape="round"
        >
          Close
        </Button>
      }
    >
      {loading ? (
        <Loader />
      ) : (
        <div className="p-4">
          <div className="flex items-center gap-1 mb-6">
            <Avatar
              size={64}
              src={data?.personel_settings_info[0]?.user_profile_picture}
              className="border-2 border-gray-300"
            >
              {!data?.personel_settings_info[0]?.user_profile_picture &&
                data &&
                getInitial(data.firstname)}
            </Avatar>
            <div className="flex-1 border-l-2 border-gray-200 pl-4">
              <p className="text-lg font-semibold">
                {data?.firstname} {data?.personel_settings_info[0]?.middle_name || ""} {data?.lastname}
              </p>
            </div>
          </div>

          <Table
            dataSource={scheduleData}
            columns={columns}
            pagination={false}
            size="small"
            bordered
            className="mb-6"
          />

          <div>
            <h3 className="text-lg font-medium mb-2">Personal Information</h3>
            <div className="text-gray-600">
              <p className="mb-2">Mobile Number: {contact.phone_number || "N/A"}</p>
              <p>Work Number: {contact.work_phone || "N/A"}</p>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default StaffRecord;
