import React from "react";
import {  Menu, Layout } from "antd";
import { Outlet, useNavigate } from "react-router-dom";
import { useStateGlobalContext } from "../../../contexts/GlobalContext";
import UnauthorizedPage from "../../../components/UnauthorizedPage/UnauthorizedPage";

const { Content } = Layout;

const items = [
  { label: "Templates", key: "" },
  { label: "Referring Providers", key: "referring-providers" },
];

document.title = "Templates | American EMR";

const Templates =()=> {
  
  const navigate = useNavigate();
  const { officeSetting, profile } = useStateGlobalContext()
  const role = profile?.user_info?.[0]?.role;


  const officeDateFormat = officeSetting && officeSetting?.date_format
  const officeTimeFormat = officeSetting && officeSetting?.time_format
  const officeTimezone = officeSetting && officeSetting?.facility_time_zone 


  return (
    <>
    {(role === "clinic" || role === "manager" || role === "doctor" )? (<Layout style={{ backgroundColor: "transparent" }}>
      <Menu
        onClick={({ key }) => navigate(key)}
        mode="horizontal"
        defaultSelectedKeys={[""]}
        items={items}
        className="summary-menu mb-4 bg-white text-[16px] font-medium tracking-[0.2px]"
      />
      <Content
        className="layout"
        style={{
          backgroundColor: "white", 
        }}
      >
        <Outlet context={[officeDateFormat, officeTimeFormat, officeTimezone]} />
      </Content>
    </Layout>): <UnauthorizedPage/>}
    </>
  );
}

export default Templates;
 