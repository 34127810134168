import React, { useEffect, useMemo } from "react";
import InvoicesCards from "./InvoicesCards";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Layout, Menu } from "antd";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import { InvoiceContextProvider } from "../../../../contexts/InvoiceContext";

const { Content } = Layout;

function InvoiceAndBilling() {
  const navigate = useNavigate();
  const location = useLocation();
  const { officeSetting, profile } = useStateGlobalContext();
  const role = profile?.user_info?.[0]?.role;

  const menuItems = [
    { label: "Service Invoices", key: "service-invoices", role: ["clinic", "manager", "nurse", "doctor", "biller"] },
    { label: "Lab Invoices", key: "lab-invoices", role: ["clinic", "manager", "nurse", "doctor", "biller"] },
    { label: "Product Invoices", key: "product-bills", role: ["clinic", "manager", "nurse", "doctor", "biller"] },
    { label: "Outstanding Bills", key: "outstanding-bills", role: ["clinic", "manager", "nurse", "doctor", "biller"] },
    { label: "Credit Memo", key: "credit-memo", role: ["clinic", "manager", "nurse", "doctor", "biller"] },
    { label: "Insurance Claims", key: "insurance-claims", role: ["clinic", "manager", "nurse", "doctor", "biller"] },
    { label: "Superbill", key: "superbill", role: ["clinic", "manager", "nurse", "doctor", "biller"] },
    { label: "Payment Method", key: "payment-method", role: ["clinic", "manager", "nurse", "doctor"] },
  ];

  const officeDateFormat = officeSetting?.date_format;
  const officeTimeFormat = officeSetting?.time_format;
  const officeTimezone = officeSetting?.facility_time_zone;

  const filteredMenuItems = useMemo(() => menuItems.filter(item => item.role.includes(role)), [role]);

  const activeMenuItem = useMemo(
    () => menuItems.find(item => location.pathname.includes(item.key)),
    [location.pathname]
  );

  useEffect(() => {
    if (!activeMenuItem) {
      navigate("service-invoices");
    }
  }, [activeMenuItem, navigate]);

  return (
    <div>
      <InvoiceContextProvider>
        <div className="mt-2 mb-2">
          <InvoicesCards />
        </div>
        <Layout style={{ backgroundColor: "transparent" }}>
          <Menu
            onClick={({ key }) => navigate(key)}
            mode="horizontal"
            selectedKeys={activeMenuItem ? [activeMenuItem.key] : []}
            items={filteredMenuItems}
            className="summary-menu mb-4 bg-white font-medium tracking-[0.2px]"
          />
          <Content className="layout" style={{ backgroundColor: "white" }}>
            <Outlet context={[officeDateFormat, officeTimeFormat, officeTimezone]} />
          </Content>
        </Layout>
      </InvoiceContextProvider>
    </div>
  );
}

export default InvoiceAndBilling;
