import React, { useEffect, useState } from "react";
import { Card, Row, Col, Select, Tooltip, Button } from "antd";
import { doGet } from "../../../../API/apis";

const { Option } = Select;

const DashboardCard = ({
  title,
  totalNumber,
  monthlyData,
  loading,
  onChange,
  tooltipText,
}) => {
  const [selectedOption, setSelectedOption] = useState("today");

  const handleSelectChange = (value) => {
    setSelectedOption(value);
    onChange(value);
  };

  return (
    <Col lg={4} sm={12} md={6} xl={4}>
      <Card hoverable loading={loading}>
        <div className="flex flex-col gap-2">
          {/* Top Section: Title and Tooltip */}
          <div className="flex justify-between">
            <div>
              <Tooltip title={tooltipText}>
                <p className="text-gray-600 font-semibold text-sm">{title}</p>
              </Tooltip>
              <div>
                <h1 className="text-2xl font-bold text-gray-600">
                  {selectedOption === "month" && monthlyData
                    ? monthlyData
                    : totalNumber}
                </h1>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <Tooltip title="Today">
                <button
                  className={`text-xs px-2 py-1 border-none ${
                    selectedOption === "today"
                      ? "bg-blue-500 text-white shadow"
                      : "bg-gray-200 text-gray-600"
                  } hover:bg-blue-400 transition`}
                  onClick={() => handleSelectChange("today")}
                >
                  T
                </button>
              </Tooltip>
              <Tooltip title="Month" placement="bottom">
                <button
                  className={`text-xs px-2 py-1 border-none ${
                    selectedOption === "month"
                      ? "bg-blue-500 text-white shadow"
                      : "bg-gray-200 text-gray-600"
                  } hover:bg-blue-400 transition`}
                  onClick={() => handleSelectChange("month")}
                >
                  M
                </button>
              </Tooltip>
            </div>
          </div>

          {/* Bottom Section: Description or Action */}
          {/* <div className="text-left">
      <span className="text-xs text-gray-500 italic">
        {selectedOption === "today" ? "Today's Data" : "Monthly Data"}
      </span>
    </div> */}
        </div>
      </Card>
    </Col>
  );
};

const DashboardCards = () => {
  const [loading, setLoading] = useState(false);
  const [TotalPatients, setTotalPatients] = useState(null);
  const [TotalAppointments, setTotalAppointments] = useState(null);
  const [TotalLabsAndImages, setTotalLabsAndImages] = useState(null);
  const [TotalProductInvoice, setTotalProductsInvoice] = useState(null);
  const [servicesCount, setServicesCount] = useState(null);
  const fetchData = async (endpoint, setData) => {
    setLoading(true);
    try {
      const response = await doGet(endpoint);
      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData("/reports/patient-detail/", setTotalPatients);
    fetchData("/reports/appointments-card/", setTotalAppointments);
    fetchData("/reports/labs-images-detail/", setTotalLabsAndImages);
    fetchData("/reports/product-invoices-widgets/", setTotalProductsInvoice);
    fetchData("/reports/patient-invoices-widgets/", setServicesCount);
  }, []);

  const handleCardChange = () => {};

  return (
    <Row gutter={[16, 16]} className="mt-2">
      <DashboardCard
        title="Patients"
        loading={loading}
        totalNumber={TotalPatients?.today_patients}
        monthlyData={TotalPatients?.monthly_patients}
        onChange={handleCardChange}
        tooltipText="Total number for New Patients registered"
      />
      <DashboardCard
        title="Appointments"
        loading={loading}
        totalNumber={TotalAppointments?.today_appointments_count}
        monthlyData={TotalAppointments?.monthly_appointments_count}
        onChange={handleCardChange}
        tooltipText="Total number for New Appointments created"
      />

      <DashboardCard
        title="Services"
        loading={loading}
        totalNumber={servicesCount?.today_invoices_count}
        monthlyData={servicesCount?.monthly_invoices_count}
        onChange={handleCardChange}
        tooltipText="Total number for New Services offered"
      />
      <DashboardCard
        title="Inhouse Labs"
        loading={loading}
        totalNumber={TotalLabsAndImages?.today_inhouse_labs_count}
        monthlyData={TotalLabsAndImages?.monthly_inhouse_labs_count}
        onChange={handleCardChange}
        tooltipText="Total number for In-house Labs ordered"
      />
      <DashboardCard
        title="External Labs"
        loading={loading}
        totalNumber={TotalLabsAndImages?.today_external_labs_count}
        monthlyData={TotalLabsAndImages?.monthly_external_labs_count}
        onChange={handleCardChange}
        tooltipText="Total number for External Labs ordered"
      />
      <DashboardCard
        title="Products"
        loading={loading}
        totalNumber={TotalProductInvoice?.today_invoices_count}
        monthlyData={TotalProductInvoice?.monthly_invoices_count}
        onChange={handleCardChange}
        tooltipText="Total number of products invoices by registered patients"
      />
    </Row>
  );
};

export default DashboardCards;
