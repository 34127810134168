import React from "react";
import LabelDisplay from "../../../../../../../../components/LabelDisplay/LabelDisplay";
import { NumberFormatter } from "../../../../../../../../utils/validator";
import FormatDateTime from "../../../../../../../../components/FormatDate/FormatDateTime";
import { Card } from "antd";
import InvoiceFooter from "../../InvoiceFooter/InvoiceFooter";

const SingleInvoiceComponent = ({ rxData }) => {
  return (
    <>
      <LabelDisplay title="Created By" description={rxData?.created_by} />
      <LabelDisplay
        title="Issue Date"
        description={
          <FormatDateTime date={rxData?.issue_date} type="datetime" />
        }
      />
      <LabelDisplay title="Patient Name" description={rxData?.patient_name} />
      <LabelDisplay title="Invoice ID" description={rxData?.invoice_id} />
      <LabelDisplay title="Status" description={rxData?.status} />
      <LabelDisplay title="Payment Method" description={rxData?.payment_method} />


      <Card className="mb-2">
        <LabelDisplay
          title="Reason for Visit"
          description={rxData?.visit_reason}
        />
        <LabelDisplay title="Service Name" description={rxData?.service_name} />
        <LabelDisplay title="Service Type" description={rxData?.service_type} />
        {rxData?.service_group && (
          <LabelDisplay
            title="Service Group"
            description={rxData?.service_group}
          />
        )}
        <LabelDisplay title="Quantity" description={rxData?.quantity} />
        <LabelDisplay
          title="Service Price"
          description={<NumberFormatter value={rxData?.price} />}
        />
        {rxData?.notes && (
          <LabelDisplay title="Service Notes" description={rxData?.notes} />
        )}
      </Card>
      <InvoiceFooter
        subTotal={rxData?.sub_total === 0 ? rxData?.price : rxData?.sub_total}
        discount={rxData?.discount}
        discountAmount={rxData?.discounted_amount}
        tax={rxData?.tax}
        grandTotal={rxData?.grand_total_amount}
      />
    </>
  );
};

export default SingleInvoiceComponent;
