import logo from "../../assets/logo.png";
import logo1 from "../../assets/star.png";

const Logo = ({ ...others }) => {
  return (
    <div
      className="flex justify-center brand"
      style={{
        transition: "margin-left 0.2s",
      }}
    >
      {others?.others?.collapsed ? (
        <img src={logo1} alt="Logo" className="w-[2rem] my-5 logo" />
      ) : (
        <img
          src={others?.others?.logo?.icon || logo}
          alt="Logo"
          className="w-[10rem] my-5"
        />
      )}
    </div>
  );
};

export default Logo;
