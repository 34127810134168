import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal, Form, Input, AutoComplete, Card, Space } from "antd";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import { useParams } from "react-router-dom";
import { doPost, doGet, doPatch, doDelete } from "../../../../API/apis";
import ViewNotes from "./ViewNotes";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import NotesHeader from "./NotesHeader";
import GeneralNotesPad from "./component/GeneralNotesPad";
import Notification from "../../../../components/Notification/Notification";
import showConfirmModal from "../../../../components/ModalConfirm/ModalConfirm";
import { modules } from "../../../../components/GlobalComponents/GlobalComponents";
import { getGeneralNotesColumn } from "../../../../components/TableColumns/TableColumns";
import { debounce } from "../../../../API/apiUtils";
import CustomTable from "../../../../components/CustomTable/CustomTable";
import { encounter_Status, generalNotesItems } from "../../../../utils/Data";

function GeneralNotes() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [data, setData] = useState(null);
  const [edit, setEdit] = useState(false);
  const [view, setView] = useState(false);
  const [options, setOptions] = useState([]);
  const [viewNotes, setViewNotes] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFormReady, setIsFormReady] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [draftLoading, setDraftLoading] = useState(false);
  const { profile, submitLoading, setSubmitLoading } = useStateGlobalContext();

  const role = profile && profile?.user_info[0]?.role;

  const [print, setPrint] = useState({
    show: false,
    data: null,
  });

  const handlePrint = (record) => {
    setPrint({
      show: true,
      data: record,
    });
  };

  const hidePrint = () => {
    setPrint({
      show: false,
      data: null,
    });
  };

  const cancelGeneralNotes = (record) => {
    showConfirmModal({
      title: "Confirm Cancel?",
      content: "Are you sure you want to Cancel this?",
      okText: "Cancel Notes",
      okType: "danger",
      cancelText: "Keep",
      className: "custom-modal",
      onOk: () => handleCancelGeneralNotes(record),
    });
  };

  const handleCancelGeneralNotes = async (record) => {
    let data = {};
    data = {
      status: "Cancelled",
    };
    try {
      const response = await doPatch(
        `/notes/update/${id}/${record?.id}/`,
        data
      );
      if (response.status === 200) {
        Notification.success("General Notes Cancelled Successfully");
        getNotes();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = useCallback(
    debounce(async (value) => {
      if (value) {
        try {
          const response = await doGet(`/services/search-services/?q=${value}`);
          if (response.status === 200) {
            const filteredOptions = response.data?.map((service, index) => {
              const { id, service_names } = service;
              return {
                id,
                value: `${service_names}`,
                label: (
                  <div className="flex justify-between">
                    <span>{service?.service_names}</span>
                    <span>{service?.service_type}</span>
                    <span>{service?.service_group_name}</span>
                    <span>{service?.price}</span>
                  </div>
                ),
              };
            });
            setOptions(filteredOptions);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }, 300),
    []
  );

  const handleNotes = () => {
    setVisible(true);
  };

  const handleViewNotes = (record) => {
    setViewNotes(record);
    setView(true);
  };

  const openModal = (record, isEdit) => {
    setSelectedItem(record);
    setEdit(isEdit);
    setVisible(true);

    setTimeout(() => {
      form.setFieldsValue({
        service_title: record?.service_type,
        service_description: record?.description,
        service_reason: record?.reason,
        service_note: record?.notes,
      });
      setIsFormReady(true);
    }, 0);
  };

  const closeGengeralNotes = () => {
    setEdit(false);
    setVisible(false);
    form.resetFields();
  };
  const handleGeneralNotes = async (values) => {
    setSubmitLoading(true);
    const data = {
      service_type: values.service_title,
      description: values.service_description,
      reason: values.service_reason,
      notes: values.service_note,
      status: "Signed",
    };
    try {
      const response = edit
        ? await doPatch(
            `/notes/update/${id}/${selectedItem?.id}/?q=sign/`,
            data
          )
        : await doPost(`/notes/sign/${id}/`, data);
      if (response.status === 200 || response.status === 201) {
        const successMessage = edit
          ? "General Notes Signed Successfully"
          : "General Notes Added Successfully";
        Notification.success(successMessage);

        closeGengeralNotes();
        getNotes();
      }
    } catch (error) {
      Notification.error("Something went wrong");
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleDraft = async () => {
    setDraftLoading(true);
    const serviceTitle = form.getFieldValue("service_title");
    const service_reason = form.getFieldValue("service_reason");
    const service_notes = form.getFieldValue("service_note");

    if (!serviceTitle || !service_reason || !service_notes) {
      Notification.error("All Fields are required");
      return;
    }

    const data = {
      service_type: form.getFieldValue("service_title"),
      description: form.getFieldValue("service_description"),
      reason: form.getFieldValue("service_reason"),
      notes: form.getFieldValue("service_note"),
    };
    try {
      const response = edit
        ? await doPatch(`/notes/update/${id}/${selectedItem?.id}/`, data)
        : await doPost(`/notes/draft/${id}/`, data);

      if (response.status === 200 || response.status === 201) {
        const successMessage = edit
          ? "General Notes Updated and Saved as Draft Successfully"
          : "General Notes Saved as Draft Successfully";
        Notification.success(successMessage);
        closeGengeralNotes();
        getNotes();
      }
    } catch (error) {
      Notification.error("Something went wrong while saving as draft");
    } finally {
      setDraftLoading(false);
    }
  };

  const getNotes = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/notes/view/${id}/`);
      if (response.status === 200) {
        const sortedTasks = response.data.sort(
          (a, b) => new Date(b.date_time) - new Date(a.date_time)
        );
        setData(sortedTasks);
      }
    } catch (error) {
      Notification.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (record) => {
    showConfirmModal({
      title: "Confirm Delete?",
      content: "Are you sure you want to delete this?",
      okText: "Delete Draft",
      okType: "danger",
      cancelText: "Keep",
      className: "custom-modal",
      onOk: () => deleteGeneralNotes(record),
    });
  };

  const handleCancel = () => {
    if (!edit) {
      showConfirmModal({
        title: "Confirm Close?",
        icon: null,
        content: "Are you sure? Current Data will be Lost",
        okText: "Close",
        okType: "danger",
        cancelText: "Cancel",
        className: "custom-modal",
        onOk() {
          setEdit(false);
          setVisible(false);
          form.resetFields();
        },
      });
    } else {
      setEdit(false);
      setVisible(false);
      form.resetFields();
    }
  };

  const deleteGeneralNotes = async (record) => {
    try {
      const response = await doDelete(`/notes/delete/${id}/${record}/`);
      if (response.status === 200) {
        Notification.success("General Notes Deleted Successfully");
        getNotes();
      }
    } catch (error) {
      Notification.error("Something went wrong");
    }
  };

  const handleClear = () => {
    showConfirmModal({
      title: "Confirm Clear?",
      icon: null,
      content:
        "Are you sure you want to Clear all fields? This Action will not be undone",
      okText: "Clear",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        form.resetFields();
      },
    });
  };

  const columns = getGeneralNotesColumn({
    encounter_Status,
    role,
    generalNotesItems,
    handleDelete,
    handleViewNotes,
    openModal,
    cancelGeneralNotes,
    handlePrint,
  });

  useEffect(() => {
    getNotes();
  }, [id]);

  return (
    <Card
      title="General Notes"
      extra={
        <Space>
          {role !== "biller" && (
            <Button type="primary" onClick={handleNotes}>
              Create Notes
            </Button>
          )}
        </Space>
      }
    >
      <CustomTable
        columns={columns}
        loading={loading}
        dataSource={data}
        key="general_notes"
      />

      <Modal
        title={edit ? "Update Note" : "Create Note"}
        open={visible}
        closeIcon={
          <Button
            size="small"
            className="app-close-icon"
            shape="round"
            onClick={handleCancel}
          >
            Close
          </Button>
        }
        maskClosable={false}
        footer={null}
        width={1000}
      >
        <NotesHeader />
        <Form
          layout="vertical"
          name="general-notes"
          onFinish={handleGeneralNotes}
          form={form}
        >
          <Form.Item
            label="Service Name"
            name="service_title"
            tooltip="Service Name, Type, Group , Price"
            rules={[
              {
                required: true,
                message: "Please Enter Service Title",
              },
            ]}
          >
            <AutoComplete
              options={options}
              allowClear={true}
              // onSelect={onSelect}
              onSearch={handleSearch}
              placeholder="Search Service"
            />
          </Form.Item>
          <Form.Item
            name="service_description"
            label="Note Title / Description"
          >
            <Input placeholder="Note Title / Description" maxLength={100} />
          </Form.Item>
          <Form.Item
            name="service_reason"
            label="Reason for Visit"
            rules={[
              {
                required: true,
                message: "Please Enter Reason",
              },
            ]}
          >
            <Input placeholder="Chief Complaint" maxLength={100} />
          </Form.Item>
          <Form.Item
            name="service_note"
            label="Add Note"
            rules={[
              {
                required: true,
                message: "Please Enter Notes",
              },
            ]}
          >
            <ReactQuill
              theme="snow"
              modules={modules}
              style={{ height: "300px" }}
            />
          </Form.Item>
          <div className="flex justify-between mt-14">
            <Button onClick={handleClear}>Clear</Button>
            <p className="text-gray-400">Maximum Attachment Size is 1MB</p>
            <div>
              <Button onClick={handleDraft} loading={draftLoading}>
                Save as Draft
              </Button>
              {role !== "nurse" && (
                <Button
                  className="ml-2"
                  type="primary"
                  htmlType="submit"
                  loading={submitLoading}
                >
                  Sign
                </Button>
              )}
            </div>
          </div>
        </Form>
      </Modal>

      <ViewNotes
        open={view}
        onCancel={() => setView(false)}
        viewGeneralNotes={viewNotes}
      />
      <GeneralNotesPad
        visible={print?.show}
        data={print?.data}
        onClose={hidePrint}
      />
    </Card>
  );
}

export default GeneralNotes;
