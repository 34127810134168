import React, { useEffect, useState } from "react";
import { Row } from "antd";
import DashboardCards from "./components/DashboardCards";
import DashboardChart from "./components/DashboardChart";
import ClinicStaff from "./components/ClinicStaff";
import OutOfStockMedicines from "./components/OutOfStockMedicines";
import { useStateGlobalContext } from "../../../contexts/GlobalContext";
import Loader from "../../../components/Loader/Loader";
import { doGet } from "../../../API/apis";
import WelcomeCard from "../../../components/WelcomeCard/WelcomeCard";
import UnauthorizedPage from "../../../components/UnauthorizedPage/UnauthorizedPage";

const AdminDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [patientsGraphData, setPatientsGraphData] = useState(null);
  const { officeSetting,  profile } = useStateGlobalContext();

  const role = profile?.user_info?.[0]?.role;

  const graphData = async () => {
    setLoading(true);
    try {
      const response = await doGet("/patient/patients/graphs/");
      if (response.status === 200) {
        setPatientsGraphData(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (role === "clinic" || role === "manager") {
      graphData();
    }
  }, [role]);


  document.title = "Admin Dashboard | American EMR";

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {(role === "clinic" || role === "manager" )? (
        <div>
          <WelcomeCard
            name={
              (officeSetting && officeSetting?.facility_name[0]) ||
              "Clinic Name"
            }
            title="Admin Dashboard"
            color="border-[#757575]"
            logo={officeSetting?.user_office_logo}
            admin={true}
          />
          <DashboardCards />
          <Row gutter={[16, 16]}>
            <DashboardChart graph={patientsGraphData} loading={loading} />
            <ClinicStaff />
          </Row>

          <OutOfStockMedicines />
        </div>
      ) : (
        <UnauthorizedPage/>
      )}
    </>
  );
};

export default AdminDashboard;
