import React, { useEffect, useState } from "react";
import { Card,  Col } from "antd";
import { doGet } from "../../../../API/apis";
import { useNavigate } from "react-router-dom";
import Notification from "../../../../components/Notification/Notification";
import { RecentPatientsColumns } from "../../../../components/TableColumns/TableColumns";
import CustomTable from "../../../../components/CustomTable/CustomTable";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";


const RecentPatients = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [filterData, setFilterData] = useState([]);
  const { profile } = useStateGlobalContext();

  const role = profile && profile?.user_info[0]?.role;

  const getPatients = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/patient/patient-details/?q=recent`);
      if (response.status === 200) {
        const sortedData = response.data.sort((a, b) => {
          return new Date(b.registration_date) - new Date(a.registration_date);
        });
        setData(sortedData);
        // setFilterData(sortedData);
      }
    } catch (err) {
      Notification.error("Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPatients();
  }, []);


  return (
    <Col xs={24} md={24} lg={8} xl={8} className="mb-4">
      <Card className="shadow-md"
      title="Recently Added"
      >
        <CustomTable
          columns={RecentPatientsColumns(navigate, role)}
          dataSource={data}
          loading={loading}
          key="recent_patients"
          indexColWidth="20%"
        />
      </Card>
    </Col>
  );
};

export default RecentPatients;
