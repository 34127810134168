import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Avatar, Button } from "antd";
import SummaryTabs from "./MedicalCards/PatientTabs";
import { useStateGlobalContext } from "../../../contexts/GlobalContext";
import { ageCalculator } from "../../../utils/ageCalculator";
import BuyService from "./BuyService/BuyService";
import Loader from "../../../components/Loader/Loader";
import AddToWatingRoom from "./AddToWaitingRoom/AddToWatingRoom";
import ProfileAppointment from "../../../components/ProfileAppointment/ProfileAppointment";
import BuyProducts from "../../../components/BuyProducts/BuyProducts";
import FaceSheet from "../../../components/FaceSheet/FaceSheet";
import FormatDate from "../../../components/FormatDate/FormatDate";
import GlobalBreadcrumb from "../../../components/BreadCrumbs/BreadCrumbs";
import NoPageFound from "../../../components/NoPageFound/NoPageFound";

function DisplayLabel({
  title,
  description,
  className = "flex items-center mt-[2px]",
}) {
  return (
    <div className={`gap-1 py-1 ${className}`}>
      <div className="font-light flex flex-col leading-none  text-neutral-900">
        <div className="font-semibold">{title}:</div>
      </div>
      <div className="flex-1 shrink leading-5 basis-0">
        {description ?? "N/A"}
      </div>
    </div>
  );
}

const DisplayAllergies = ({ allergies }) => {
  const displayedAllergies = allergies
    ?.slice(0, 4)
    ?.map((a) => a?.allergy_value);
  const remainingAllergies = allergies?.length - 4;
  return (
    <span className="text-red-600 font-medium">
      {displayedAllergies?.join(", ")}
      {remainingAllergies > 0 && ` ...and ${remainingAllergies} more`}
    </span>
  );
};


function PatientSummary() {
  const { id } = useParams();
  const [modals, setModals] = useState({
    calenderVisible: false,
    visible: false,
    waitingListModal: false,
    buyProductsVisible: false,
    faceSheet: false,
  });
  const [loading, setLoading] = useState(true);


  
  const { patientBasicInfo, getPatientData, patientAllergies, getAllergies, profile } = useStateGlobalContext();


  useEffect(() => {
    const fetchPatientData = async () => {
      setLoading(true);
      await Promise.all([getPatientData(id), getAllergies(id)]);
      setLoading(false);
    };
    fetchPatientData();
  }, [id]);

  if (loading) return <Loader />;

  if (!patientBasicInfo) {
    return (
      <NoPageFound title="Oops! No patient found with the provided details. Please check the information and try again." />
    );
  }

  const toggleModal = (modalName, value) => setModals((prev) => ({ ...prev, [modalName]: value }));

  const {
    mrn_number,
    first_name,
    middle_name,
    last_name,
    date_of_birth,
    sex,
    other_Gender,
    gender,
    employment,
    other_Employment_Status,
    marital_status,
    patient_phone,
    patient_email,
    profile_picture,
    languages,
    payment_services,
  } = patientBasicInfo;

  const fullName = `${first_name} ${
    middle_name ? middle_name : ""
  } ${last_name}`;


  const role = profile?.user_info?.[0]?.role;

  const breadcrumbRoutes = [
    { label: "Home", link: role === "biller" ? "/main/biller_dashboard" : "/main/work_dashboard" },
    { label: "Charts", link: "/main/charts" },
    { label: fullName },
  ];

  return (
    <div className="mx-auto p-4">
      <GlobalBreadcrumb routes={breadcrumbRoutes} />
      <div className="flex flex-col md:flex-row items-center bg-gradient-to-r from-blue-200 via-blue-50 to-blue-100 p-4 rounded-lg gap-4">
        <Avatar className="w-20 h-20 flex items-center" src={profile_picture} alt={fullName}>
          {!profile_picture && first_name?.[0]}
        </Avatar>
        <div className="flex flex-col md:flex-row w-full justify-between gap-4">
          <div>
            <DisplayLabel title="Name" description={fullName} />
            <DisplayLabel title="Marital Status" description={marital_status} />
            <DisplayLabel title="MRN" description={mrn_number} />
          </div>
          <div>
          <DisplayLabel
              title="Gender"
              description={
                <span>{sex === "other" ? other_Gender : gender} | {ageCalculator(date_of_birth)} y/o | <FormatDate date={date_of_birth} /></span>
              }
            />
            <DisplayLabel
              title="Employment"
              description={employment === "other" ? other_Employment_Status : employment}
            />
            <DisplayLabel title="Payment Method" description={payment_services || "N/A"} />
          </div>
          <div>
            <DisplayLabel title="Phone" description={patient_phone} />
            <DisplayLabel title="Email" description={patient_email?.length > 20 ? `${patient_email.slice(0, 20)}...` : patient_email} />
          </div>
          <div>
            <DisplayLabel title="Language" description={languages} />
            <DisplayLabel
              title="Allergies"
              description={<DisplayAllergies allergies={patientAllergies}/>}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row justify-end gap-2 mt-4">
        <Button
          type="primary"
          shape="round"
          size="medium"
          onClick={() => toggleModal("faceSheet", true)}
        >
          FaceSheet
        </Button>
       {role !=="biller" && <div className="flex gap-2">
        <Button
          type="primary"
          shape="round"
          size="medium"
          onClick={() => toggleModal("visible", true)}
        >
          Buy Services
        </Button>
        <Button
          type="primary"
          shape="round"
          size="medium"
          onClick={() => toggleModal("buyProductsVisible", true)}
        >
          Buy Products
        </Button>
        <Button
          shape="round"
          className="waiting-room-button"
          size="medium"
          onClick={() => toggleModal("waitingListModal", true)}
        >
          Add to Waiting Room
        </Button>
        </div>}
      </div>
      <div className="mt-4">
      <SummaryTabs />
      <ProfileAppointment visible={modals.calenderVisible} onClose={() => toggleModal("calenderVisible", false)} {...{ first_name, middle_name, last_name, mrn_number, id, date_of_birth, patient_phone, gender }} />
      <BuyService visible={modals.visible} onClose={() => toggleModal("visible", false)} fullName={fullName} date_of_birth={date_of_birth} />
      <AddToWatingRoom visible={modals.waitingListModal} onClose={() => toggleModal("waitingListModal", false)} fullName={fullName} date_of_birth={date_of_birth} />
      <BuyProducts visible={modals.buyProductsVisible} onClose={() => toggleModal("buyProductsVisible", false)} fullName={fullName} mrn_number={mrn_number} date_of_birth={date_of_birth} />
      <FaceSheet visible={modals.faceSheet} onClose={() => toggleModal("faceSheet", false)} />
      </div>
    </div>
  );
}

export default PatientSummary;
