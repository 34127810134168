import { Tag, Divider, Table, Typography, Button, Card, Row, Col } from "antd";
import React, { useEffect } from "react";
import { NumberFormatter } from "../../../../../../utils/validator";
import FormatDate from "../../../../../../components/FormatDate/FormatDate";
import { useStateGlobalContext } from "../../../../../../contexts/GlobalContext";
import SuperbillHeader from "./SuperbillHeader";
import { usePDF } from "react-to-pdf";
import PrintDrawer from "../../../../../../components/GlobalComponents/PrintDrawer";
import { providerDetails } from "./ViewSuperbill";
import LabelDisplay from "../../../../../../components/LabelDisplay/LabelDisplay";

const diagnosisColumn = [
  {
    key: 1,
    title: "#",
    align: "center",
    dataIndex: "dx_link",
  },
  {
    key: 2,
    title: "DX",
    align: "center",
    dataIndex: "dx",
  },
  {
    key: 3,
    title: "Description",
    dataIndex: "diagnosis_code",
    // ellipsis: true,
  },
];

const PrintSuperBill = ({ open, onClose, record, clientDetails, id }) => {
  const { patientBasicInfo, getPatientData } = useStateGlobalContext();


  useEffect(() => {
    getPatientData(id);
  }, []);

  const servicesColumn = [
    {
      key: 1,
      title: "#",
      align: "center",
      render: (_, __, index) => index + 1,
    },
    {
      key: 2,
      title: "Date of Service",
      align: "center",
      dataIndex: "date",
      render: (text) => <FormatDate date={text} />,
    },
    {
      key: 3,
      title: "POS",
      align: "center",
      dataIndex: "pos",
    },
    {
      key: 4,
      title: "code",
      align: "center",
      dataIndex: "code",
    },
    {
      key: 5,
      title: "Service",
      align: "center",
      dataIndex: "service",
    },
    {
      key: 6,
      title: "DX",
      align: "center",
      dataIndex: "dx",
      render: (text) => (
        <>{text && text?.map((dx, index) => <Tag key={index}>{dx}</Tag>)}</>
      ),
    },
    {
      key: 7,
      title: "Modifier",
      align: "center",
      dataIndex: "modifier",
      render: (text) => (
        <>
          {text &&
            text?.map((modifier, index) => <Tag key={index}>{modifier}</Tag>)}
        </>
      ),
    },
    {
      key: 8,
      title: "Unit",
      align: "center",
      dataIndex: "unit",
    },
    {
      key: 9,
      title: "Fee",
      align: "center",
      dataIndex: "fee",
      render: (text) => (
        <NumberFormatter value={text} options={{ style: "decimal" }} />
      ),
    },
    {
      key: 10,
      title: "Paid",
      align: "center",
      dataIndex: "paid",
      render: (text) => (
        <NumberFormatter value={text} options={{ style: "decimal" }} />
      ),
    },
  ];

  const calculateTotals = (data) => {
    let Fee = 0;
    let totalPaid = 0;
    let totalFee = 0;

    data?.forEach((item) => {
      Fee += parseFloat(item.fee);
      totalPaid += parseFloat(item.paid);
      totalFee += item.unit * item.fee;
    });

    return { totalFee, totalPaid };
  };

  const { totalFee, totalPaid } = calculateTotals(record?.super_data_details);

  const { toPDF, targetRef } = usePDF({
    filename: `${record?.superbill_id}.pdf` || "Superbill.pdf",
    page: {
      format: "A4",
      orientation: "portrait",
      margin: 10,
    },
    scale: 0.95,
  });

  const downloadPDF = () => {
    const noPrintElements = document.querySelectorAll(".no-print");

    // Hide no-print elements before generating the PDF
    noPrintElements.forEach((el) => (el.style.display = "none"));

    // Generate the PDF
    toPDF().then(() => {
      // Show no-print elements after PDF is generated
      noPrintElements.forEach((el) => (el.style.display = ""));
    });
  };

  return (
    <PrintDrawer title={record?.superbill_id} visible={open} onClose={onClose}>
      <div className="invoices-print">
        <div className="invoice-container">
          <Button onClick={downloadPDF} className="mb-2" type="primary">
            Download PDF
          </Button>
          <Card className="invoice-content" ref={targetRef}>
            <h2 className="text-center mt-4 mb-4">
              Superbill ID: {record?.superbill_id}
            </h2>
            {/* <div className="flex justify-between mt-4 invoice-header"> */}
            <Row gutter={[16, 16]}>
              <Col lg={12} xl={12}>
                <p className="font-semibold">Practice Information</p>
                <Typography.Text>
                  {record?.office_info?.split(",")?.map((info, index) => {
                    const [title, description] = info
                      .split(":")
                      .map((str) => str.trim());
                    return (
                      <LabelDisplay
                        key={index}
                        title={title || "Unknown Title"}
                        description={description || "N/A"}
                      />
                    );
                  })}
                </Typography.Text>
              </Col>
              <Col lg={12} xl={12}>
                <p className="font-semibold">Provider Information</p>
                <Typography.Text>
                  {providerDetails(record?.provider_info)}
                </Typography.Text>
              </Col>
            </Row>
            {/* </div> */}

            <Divider />
            <div>
              <SuperbillHeader
                patientInfo={patientBasicInfo}
                authNumber={record?.prior_auth_number}
              />
              <Divider />
              <div>
                <p className="font-semibold p-2">Diagnosis</p>
                <Table
                  size="small"
                  columns={diagnosisColumn}
                  dataSource={record && record?.super_diagnosis_code}
                  pagination={false}
                />
              </div>

              <Divider />

              <div>
                <p className="font-semibold p-2">Procedures</p>
                <Table
                  columns={servicesColumn}
                  dataSource={record && record?.super_data_details}
                  size="small"
                  pagination={false}
                />
              </div>

              <Divider />

              <div className="flex flex-col items-end p-2">
                <Typography.Text>
                  Total Fee:{" "}
                  <NumberFormatter
                    value={totalFee}
                    options={{ style: "decimal" }}
                  />
                </Typography.Text>
                <Typography.Text>
                  Total Paid:{" "}
                  <NumberFormatter
                    value={totalPaid || 0}
                    options={{ style: "decimal" }}
                  />
                </Typography.Text>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </PrintDrawer>
  );
};

export default PrintSuperBill;
