import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, Spin, message, Row, Col } from "antd";
import { doPost, doGet, doPatch, doDelete } from "../../../../../API/apis";
import BaseDatePicker from "../../../../../components/BaseDatePicker/BaseDatePicker";
import MedicalCardsTitle from "../MedicalCardsTitle";
import Notification from "../../../../../components/Notification/Notification";
import showConfirmModal from "../../../../../components/ModalConfirm/ModalConfirm";
import FormatDate from "../../../../../components/FormatDate/FormatDate";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";
import LabelDisplay from "../../../../../components/LabelDisplay/LabelDisplay";
import { AddButton } from "../../../../../components/GlobalComponents/GlobalComponents";

function PastSurgicalHistory() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(null);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [pastSurgicalHistoryVisible, setPastSurgicalHistoryVisible] =
    useState(false);

  const disabledDate = (current) => {
    return current && current > new Date();
  };

  const openModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
    setEditMode(false);
    setPastSurgicalHistoryVisible(false);
    form.resetFields();
  };

  const handleItemClick = (record) => {
    setSelectedItem(record);
    setPastSurgicalHistoryVisible(true);
  };

  const handleCopy = (record) => {
    const textToCopy = `
    Surgery Date: ${
      record.surgery_date
        ? dayjs(record.surgery_date).format("YYYY-MM-DD")
        : "N/A"
    }
    Past Surgical History: ${record.past_surgical_history || "N/A"}
     `;

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        message.success("Copied to clipboard");
      })
      .catch(() => {
        message.error("Failed to copy to clipboard");
      });
  };

  const handleEdit = (record) => {
    setEditMode(true);
    setSelectedItem(record);
    form.setFieldsValue({
      past_surgical_history: record?.past_surgical_history,
      surgery_date: record?.surgery_date ? dayjs(record?.surgery_date) : "",
    });
    setVisible(true);
  };

  const handleDelete = (record) => {
    showConfirmModal({
      title: "Are you sure you want to delete this item?",
      icon: <DeleteOutlined />,
      content: "This action cannot be undone.",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        deletePastSurgicalHistory(record);
      },
    });
  };

  const deletePastSurgicalHistory = async (record) => {
    try {
      const response = await doDelete(
        `/patient/delete-past-surgical-history/${id}/${record}/`
      );
      if (response.status === 200) {
        Notification.success("Past surgical history deleted successfully");
        getPatientSurgicalHistory();
      }
    } catch (error) {
      Notification.error("Something went wrong");
    }
  };

  const handlePatientSurgicalHistory = async (values) => {
    setLoading(true);
    const data = {
      surgery_date: values.surgery_date ? values.surgery_date : "",
      past_surgical_history: values.past_surgical_history,
    };
    try {
      const response = editMode
        ? await doPatch(
            `/patient/update-past-surgical-history/${id}/${selectedItem.id}/`,
            data
          )
        : await doPost(`/patient/add-past-surgical-history/${id}/`, data);
      if (response.status === 200 || response.status === 201) {
        const successMessage = editMode
          ? "Past medical history updated successfully"
          : "Past medical history added successfully";
        Notification.success(successMessage);
        getPatientSurgicalHistory();
        handleCancel();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getPatientSurgicalHistory = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/patient/get-past-surgical-history/${id}/`);
      if (response.status === 200) {
        setData(response.data.reverse());
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPatientSurgicalHistory();
  }, []);

  return (
    <div>
      <div className="shadow-class p-4 border rounded-md h-[180px]  max-h-[180px]">
        <div className="flex items-center justify-between">
          <MedicalCardsTitle title="Past Surgical History" />
          <AddButton openModal={openModal}/>
        </div>
        {loading ? (
          <div className="flex justify-center items-center">
            <Spin size="small" />
          </div>
        ) : (
          <div className="flex max-h-[130px]  overflow-auto mt-1">
            <ul>
              {data.map((item, index) => (
                <li
                  key={item.id}
                  className="text-[#1890ff] cursor-pointer flex justify-between items-center"
                  onMouseEnter={() => setHoveredItemId(item.id)}
                  onMouseLeave={() => setHoveredItemId(null)}
                >
                  <span
                    onClick={() => {
                      handleItemClick(item);
                    }}
                    title={item?.past_surgical_history}
                  >
                    {index + 1}. {item?.past_surgical_history?.slice(0, 26)}
                    {item.past_surgical_history?.length > 26 ? "..." : ""}
                  </span>
                  {hoveredItemId === item.id && (
                    <div className="flex items-center ml-3">
                      <CopyOutlined
                        className="text-[#1890ff] mr-2"
                        onClick={() => {
                          handleCopy(item);
                        }}
                      />
                      <EditOutlined
                        className="text-[#1890ff] mr-2"
                        onClick={() => {
                          handleEdit(item);
                        }}
                      />
                      <DeleteOutlined
                        className="text-[#1890ff]"
                        onClick={() => {
                          handleDelete(item.id);
                        }}
                      />
                    </div>
                  )}
                </li>
              ))}
              {data.length === 0 && (
                <li className="flex justify-between items-center">
                  <span>No past Surgical history</span>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>

      <Modal
        title="Past Surgical History"
        open={visible}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
        onCancel={handleCancel}
        maskClosable={false}
        footer={null}
        width={650}
      >
        <Form
          layout="vertical"
          name="past-surgical-history"
          form={form}
          onFinish={handlePatientSurgicalHistory}
        >
                    <Row gutter={[16,16]}>
                    <Col sm={24} lg={6}>
          <Form.Item name="surgery_date" label="Date of Surgery">
            <BaseDatePicker disabledDate={disabledDate} />
          </Form.Item>
          </Col>
          <Col sm={24} lg={18}>
          <Form.Item
            name="past_surgical_history"
            label="Past Surgical History"
            rules={[
              {
                required: true,
                message: "Please enter past surgical history",
              },
            ]}
          >
            <Input.TextArea maxLength={500} rows={1} showCount />
          </Form.Item>
          </Col>
          </Row>
          <div className="flex justify-end">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button
              type="primary"
              htmlType="submit"
              className="ml-2"
              loading={loading}
            >
              {editMode ? "Update" : "Add"}
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        title="Past Surgical History"
        open={pastSurgicalHistoryVisible}
        onCancel={handleCancel}
        footer={null}
        width={650}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
      >
        {selectedItem && (
          <>
            <LabelDisplay
              title="Date of Surgery"
              description={
                selectedItem?.surgery_date ? (
                  <FormatDate date={selectedItem?.surgery_date} />
                ) : (
                  "No Date Entered"
                )
              }
            />

            <LabelDisplay
              title="Surgery Name"
              description={selectedItem?.past_surgical_history}
            />
            <LabelDisplay
              title="Added By"
              description={selectedItem?.added_by}
            />
            <LabelDisplay
              title="Date"
              description={
                <FormatDateTime
                  date={selectedItem?.date_time}
                  type="datetime"
                />
              }
            />
          </>
        )}
      </Modal>
    </div>
  );
}

export default PastSurgicalHistory;
