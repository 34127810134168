import React from "react";
import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { NumberFormatter } from "../../../../../../utils/validator";
import FormatDateTime from "../../../../../../components/FormatDate/FormatDateTime";
import LabelDisplay from "../../../../../../components/LabelDisplay/LabelDisplay";
import { LabelList } from "recharts";
import FormatDate from "../../../../../../components/FormatDate/FormatDate";
import NotesHeader from "../../../GeneralNotes/NotesHeader";

export const providerDetails = (provider) => {
  if (!provider) return null;

  return provider.map((item, index) => (
    <div key={index}>
      <div key={index}>
        <LabelDisplay
          title="Provider Name"
          description={item?.provider_name || "N/A"}
        />
        <LabelDisplay title="License" description={item?.license || "N/A"} />
        <LabelDisplay title="Tax ID" description={item?.tax_id || "N/A"} />
        <LabelDisplay title="Phone" description={item?.phone || "N/A"} />
        <LabelDisplay
          title="Data l"
          description={item?.additional_data_1 || "N/A"}
        />
        <LabelDisplay
          title="Data ll"
          description={item?.additional_data_2 || "N/A"}
        />
        <LabelDisplay
          title="Data lll"
          description={item?.additional_data_3 || "N/A"}
        />
      </div>
    </div>
  ));
};

const ViewSuperbill = ({ open, onClose, record, showPatient = false }) => {

  const diagnosisColumn = [
    {
      key: 1,
      title: "#",
      align: "center",
      dataIndex: "dx_link",
    },
    {
      key: 2,
      title: "DX",
      align: "center",
      dataIndex: "dx",
    },
    {
      key: 3,
      title: "Description",
      dataIndex: "diagnosis_code",
      // ellipsis: true,
    },
  ];

  const servicesColumn = [
    {
      key: 1,
      title: "#",
      align: "center",
      render: (_, __, index) => index + 1,
    },
    {
      key: 2,
      title: "Date of Service",
      align: "center",
      dataIndex: "date",
      render: (text) => <FormatDate date={text} />,
    },
    {
      key: 3,
      title: "POS",
      align: "center",
      dataIndex: "pos",
    },
    {
      key: 4,
      title: "code",
      align: "center",
      dataIndex: "code",
    },
    {
      key: 5,
      title: "Service",
      align: "center",
      dataIndex: "service",
    },
    {
      key: 6,
      title: "DX",
      align: "center",
      dataIndex: "dx",
      render: (text) => (
        <>{text && text?.map((dx, index) => <Tag key={index}>{dx}</Tag>)}</>
      ),
    },
    {
      key: 7,
      title: "Modifier",
      align: "center",
      dataIndex: "modifier",
      render: (text) => (
        <>
          {text &&
            text?.map((modifier, index) => <Tag key={index}>{modifier}</Tag>)}
        </>
      ),
    },
    {
      key: 8,
      title: "Unit",
      align: "center",
      dataIndex: "unit",
    },
    {
      key: 9,
      title: "Fee",
      align: "center",
      dataIndex: "fee",
      render: (text) => (
        <NumberFormatter value={text} options={{ style: "decimal" }} />
      ),
    },
    {
      key: 10,
      title: "Paid",
      align: "center",
      dataIndex: "paid",
      render: (text) => (
        <NumberFormatter value={text} options={{ style: "decimal" }} />
      ),
    },
  ];
  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={null}
      title={record?.superbill_id}
      width={950}
      closeIcon={
        <Button size="small" className="app-close-icon" shape="round">
          Close
        </Button>
      }
    >
      <div className="mb-2">
        <LabelDisplay title="Added By" description={record?.added_by} />
        <LabelDisplay
          title="Issue Date"
          description={
            <FormatDateTime date={record?.issue_date} type="datetime" />
          }
        />
        <LabelDisplay title="Superbill ID" description={record?.superbill_id} />
        <LabelDisplay
          title="Status"
          description={record && record?.status ? "Sent" : "Not Sent"}
        />
      </div>
      <NotesHeader patientRecord={record?.patient} />
      <Card className="mb-2">
        <Row gutter={[16, 16]}>
          <Col sm={24} md={12} lg={12} xl={12}>
            <p className="font-semibold">Practice Information</p>
            <Typography.Text>
              {record?.office_info?.split(",")?.map((info, index) => {
                const [title, description] = info
                  .split(":")
                  .map((str) => str.trim());
                return (
                  <LabelDisplay
                    key={index}
                    title={title || "Unknown Title"}
                    description={description || "N/A"}
                  />
                );
              })}
            </Typography.Text>
          </Col>
          <Col sm={24} md={12} lg={12} xl={12}>
            <p className="font-semibold">Provider Details</p>
            <Typography.Text>
              {providerDetails(record?.provider_info)}
            </Typography.Text>
          </Col>
        </Row>
      </Card>

      <Card className="mb-2">
        <p className="font-semibold mb-2">Diagnosis Details</p>
        <Table
          columns={diagnosisColumn}
          dataSource={record && record?.super_diagnosis_code}
          size="small"
          pagination={false}
        />
      </Card>

      <Card>
        <p className="font-semibold mb-2">Procedures</p>
        <Table
          columns={servicesColumn}
          dataSource={record && record?.super_data_details}
          size="small"
          pagination={false}
        />
      </Card>
    </Modal>
  );
};

export default ViewSuperbill;
