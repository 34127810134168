import React, { useState } from "react";
import {
  Button,
  Drawer,
  Form,
  Table,
  Radio,
  Input,
  Select,
  Switch,
} from "antd";
import { useParams } from "react-router-dom";
import { formatDate } from "../../../../../../../../utils/DateFormat";
import { useStateGlobalContext } from "../../../../../../../../contexts/GlobalContext";
import { doPatch } from "../../../../../../../../API/apis";
import {
  BsCashCoin,
  BsBank,
  BsFillCreditCard2BackFill,
  BsCardChecklist,
} from "react-icons/bs";
import { useStateInvoicesContext } from "../../../../../../../../contexts/InvoiceContext";
import {
  discountCalulator,
  NumberFormatter,
} from "../../../../../../../../utils/validator";
import Notification from "../../../../../../../../components/Notification/Notification";
import InvoiceFooter from "../../../component/InvoiceFooter/InvoiceFooter";
const { Option } = Select;

const MySelect = ({ data, onChange }) => {
  return (
    <Select
      defaultValue="Select a discount"
      style={{ width: 200 }}
      onChange={onChange}
    >
      {Object.keys(data).map((key) => (
        <Option key={key} value={data[key].value}>
          <p>{data[key].name} </p>
          <p>{data[key].value}% </p>
        </Option>
      ))}
    </Select>
  );
};

const radioOptions = [
  { label: "CARD/POS", icon: <BsFillCreditCard2BackFill />, value: "card" },
  { label: "CASH", icon: <BsCashCoin />, value: "cash" },
  { label: "BANK WIRE", icon: <BsBank />, value: "bank" },
  { label: "CHECK", icon: <BsCardChecklist />, value: "check" },
];

const coloumn1 = [
  {
    key: 1,
    title: "Issue Date",
    dataIndex: "issue_date",
    render: (text) => <span>{formatDate(text)}</span>,
  },
  {
    key: 2,
    title: "Service Name",
    dataIndex: "service_name",
  },
  {
    key: 3,
    title: "Quantity",
    dataIndex: "quantity",
    align: "center",
  },
  {
    key: 4,
    title: "Price",
    dataIndex: "price",
  },
];

const CollectPayment = ({ visible, onClose, invoice }) => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [discount, setDiscount] = useState(0);
  const [discountApplied, setDiscountApplied] = useState(false);
  const { getInvoices, officeSetting , getUnpaidInvoicesCount} = useStateGlobalContext();
  // const { getUnpaidInvoicesCount,  } = useStateInvoicesContext();
  const [taxApplied, setTaxApplied] = useState(officeSetting?.apply_tax);

  const tax = officeSetting && officeSetting?.local_tax_rate;
  const taxRate = tax / 100;

  const handleDiscountChange = (checked) => {
    if (!checked) {
      setDiscount(0);
    }
    setDiscountApplied(checked);
  };

  const handleDiscount = (value) => {
    setDiscount(parseFloat(value));
  };

  const handleTaxChange = (checked) => {
    setTaxApplied(checked);
  };

  const calcTax = (invoice) => {
    const local_tax =
      taxApplied && invoice ? invoice * (1 - discount / 100) * taxRate : 0;
    return local_tax?.toFixed(2);
  };

  const calcGrandTotal = (invoice) => {
    const subtotal = invoice || 0;
    const appliedDiscount = discountApplied
      ? discountCalulator(discount, subtotal)
      : 0;
    const appliedTax = taxApplied ? calcTax(invoice) : 0;
    const total = subtotal - appliedDiscount;
    const totalPrice = total + Number(appliedTax);
    return totalPrice.toFixed(2);
  };

  const updateInvoice = async (values) => {
    if (!values.payment_method) {
      Notification.error("Please select a payment method.");
      return;
    }

    const subtotal = invoice?.sub_total;
    const discountValue = discountApplied
      ? discountCalulator(discount, subtotal)
      : 0;

    const data = {
      status: "Paid",
      payment_method: form.getFieldValue("payment_method"),
      discount: discount,
      discounted_amount: discountValue,
      tax: calcTax(invoice?.sub_total),
      notes: form.getFieldValue("notes"),
      grand_total_amount: calcGrandTotal(
        invoice?.sub_total === 0 ? invoice?.price : invoice?.sub_total
      ),
    };
    try {
      const response = await doPatch(
        `/invoice/invoice-update/${id}/${invoice?.id}/`,
        data
      );
      if ((response.status = 200)) {
        Notification.success("Patient Invoice Updated Successfully");
        getInvoices(id);
        getUnpaidInvoicesCount(id);
        handleCancel();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    setTimeout(() => {
      onClose();
      form.resetFields();
      setDiscountApplied(false);
    }, 200);
  };

  return (
    <Drawer
      title="Collect Payment"
      placement="right"
      width="780px"
      onClose={handleCancel}
      open={visible}
      closeIcon={
        <Button size="small" className="drawer-close-icon" shape="round">
          Close
        </Button>
      }
    >
      <p className="mb-2">
        <span className="font-medium">Patient Name: </span>
        {invoice?.patient_name}
      </p>
      <Table
        size="small"
        columns={coloumn1}
        dataSource={invoice ? [invoice] : []}
        pagination={false}
      />
      <Form
        form={form}
        layout="vertical"
        onFinish={updateInvoice}
        className="mt-2"
      >
        <Form.Item label="Apply Discount" name="discount">
          <Switch onChange={handleDiscountChange} />
        </Form.Item>

        {discountApplied && (
          <Form.Item label="Discounts">
            <MySelect
              data={officeSetting?.discounts}
              onChange={handleDiscount}
            />
          </Form.Item>
        )}

        <Form.Item label="Apply Tax" name="tax">
          <Switch onChange={handleTaxChange} defaultChecked={taxApplied} />
        </Form.Item>

        <div className="flex justify-end mt-2">
          <div></div>
          <InvoiceFooter
            subTotal={
              invoice?.sub_total === 0 ? invoice?.price : invoice?.sub_total
            }
            discount={discount}
            discountAmount={discountCalulator(discount, invoice?.sub_total)}
            tax={calcTax(invoice?.sub_total)}
            grandTotal={calcGrandTotal(
              invoice?.sub_total === 0 ? invoice?.price : invoice?.sub_total
            )}
          />
        </div>

        <Form.Item
          label="Select Payment Collection Method"
          name="payment_method"
        >
          <Radio.Group
            defaultValue="a"
            className="radio-tile-group"
            buttonStyle="solid"
          >
            {radioOptions.map((option, index) => (
              <Radio.Button key={index} value={option.value}>
                <span className="flex flex-row justify-center items-center gap-2">
                  <p>{option.label}</p>
                  <p>{option.icon}</p>
                </span>
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>

        <Form.Item label="Invoice Notes" name="notes">
          <Input.TextArea placeholder="Notes" />
        </Form.Item>

        <div className="flex justify-between">
          <Button type="primary" shape="round" onClick={onClose}>
            Close
          </Button>

          <Button
            shape="round"
            className="waiting-room-button"
            htmlType="submit"
          >
            Mark as Paid
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

export default CollectPayment;
