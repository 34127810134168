import React, { useRef } from 'react'
import { Layout } from 'antd';

const {Header} = Layout

const HeaderNav = ({ navFill, ...others }) => {
    const nodeRef = useRef(null);
    return <Header ref={nodeRef} {...others} />;
}

export default HeaderNav
