import { CheckCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'
import React from 'react'

const RolesInfo = () => {
  return (
    <div className="bg-white shadow-md p-6 rounded-lg max-h-[460px] overflow-auto border border-gray-200">
    <h3 className="text-xl font-bold text-gray-800 mb-4">
      Helpful Information
    </h3>

    {/* Manager/Admin Section */}
    <div className="mb-6">
      <h4 className="text-lg font-semibold text-blue-600 mb-2">
        Understanding User Roles
      </h4>
      <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
        <h5 className="text-base font-semibold text-gray-800 mb-2">
          <strong>Manager / Admin:</strong>
        </h5>
        <ul className="list-disc ml-6 space-y-2">
          <li className="flex items-start">
            <CheckCircleOutlined className="text-green-500 mr-2 mt-[2px]" />
            Office Settings - Office Setup
          </li>
          <li className="flex items-start">
            <CheckCircleOutlined className="text-green-500 mr-2 mt-[2px]" />
            User Management - Adding and removing facility staff
            members
          </li>
          <li className="flex items-start">
            <CheckCircleOutlined className="text-green-500 mr-2 mt-[2px]" />
            Templates - Create customized templates for each specialty
          </li>
          <li className="flex items-start">
            <CheckCircleOutlined className="text-green-500 mr-2 mt-[2px]" />
            Services - Services offered within your facility
          </li>
          <li className="flex items-start">
            <CheckCircleOutlined className="text-green-500 mr-2 mt-[2px]" />
            Manage Inventory - Inventory management
          </li>
          <li className="flex items-start">
            <CheckCircleOutlined className="text-green-500 mr-2 mt-[2px]" />
            Membership Plans - Primary care memberships and custom
            therapy sessions
          </li>
          <li className="flex items-start">
            <CheckCircleOutlined className="text-green-500 mr-2 mt-[2px]" />
            Remove Completed Tasks from Task reports
          </li>
          <li className="flex items-start">
            <CheckCircleOutlined className="text-green-500 mr-2 mt-[2px]" />
            Remove Checked out Patients from waiting room
          </li>
          <li className="flex items-start">
            <CheckCircleOutlined className="text-green-500 mr-2 mt-[2px]" />
            Monthly Reports - Detailed monthly financial reports
          </li>
          <li className="flex items-start">
            <MinusCircleOutlined className="text-red-500 mr-2 mt-[2px]" />
            Excludes Clinic Account Payment settings.
          </li>
        </ul>
      </div>
    </div>

    {/* Provider/Doctor Section */}
    <div className="mb-6">
      <h5 className="text-base font-semibold text-gray-800 mb-2">
        <strong>Provider / Doctor:</strong>
      </h5>
      <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
        <ul className="list-disc ml-6 space-y-2">
          <li className="flex items-start">
            <CheckCircleOutlined className="text-green-500 mr-2 mt-[2px]" />
            Prescriptions - Prescribing new medication to patients
          </li>
          <li className="flex items-start">
            <CheckCircleOutlined className="text-green-500 mr-2 mt-[2px]" />
            Lab Orders - Entering new lab order for patients
          </li>
          <li className="flex items-start">
            <CheckCircleOutlined className="text-green-500 mr-2 mt-[2px]" />
            Patient’s Charts - Entering SOAP notes for patient record
          </li>
          <li className="flex items-start">
            <CheckCircleOutlined className="text-green-500 mr-2 mt-[2px]" />
            Signature - Signing of patient notes and encounters
          </li>
          <li className="flex items-start">
            <MinusCircleOutlined className="text-red-500 mr-2 mt-[2px]" />
            Excludes access to financial reports and inventory editing
          </li>
        </ul>
      </div>
    </div>

    {/* Nurse/Front Desk Section */}
    <div>
      <h5 className="text-base font-semibold text-gray-800 mb-2">
        <strong>Nurse / Front Desk:</strong>
      </h5>
      <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
        <ul className="list-disc ml-6 space-y-2">
          <li className="flex items-start">
            <CheckCircleOutlined className="text-green-500 mr-2 mt-[2px]" />
            Billing, invoices, and reports - Manage billing, including
            creating invoices and processing payments
          </li>
          <li className="flex items-start">
            <CheckCircleOutlined className="text-green-500 mr-2 mt-[2px]" />
            Medical reports, patient records, doctors' charts,
            schedules, and tasks - Access to test results, treatment
            summaries, and diagnostic findings
          </li>
          <li className="flex items-start">
            <CheckCircleOutlined className="text-green-500 mr-2 mt-[2px]" />
            Scheduling appointments for patients and managing the
            office's overall schedule
          </li>
          <li className="flex items-start">
            <MinusCircleOutlined className="text-red-500 mr-2 mt-[2px]" />
            Excludes access to financial reports, inventory editing,
            and signing Notes, labs, and Prescriptions
          </li>
        </ul>
      </div>
    </div>
  </div>
  )
}

export default RolesInfo
