import { Button, Col, Divider, Row } from "antd";
import React, { useEffect, useState } from "react";
import RxImage from "../../../../../assets/Rx1.png";
import "./prescription.css";
import { ageCalculator } from "../../../../../utils/ageCalculator";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import providerInfo from "../../../../../components/PrescriptionsPad/providerInfo";
import { useParams } from "react-router-dom";
import PreferredItem from "../../../../../components/PrescriptionsPad/PreferredItem";
import FormatDate from "../../../../../components/FormatDate/FormatDate";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";
import ClinicInformation from "../../../../../components/PrescriptionsPad/ClinicInformation";
import { fullAddress } from "../../../../../components/GlobalComponents/GlobalComponents";
import PrintDrawer from "../../../../../components/GlobalComponents/PrintDrawer";
import { usePDF } from "react-to-pdf";
import LabelDisplay from "../../../../../components/LabelDisplay/LabelDisplay";

const PrescriptionPad = ({ visible, rxData, officeData, ptData, onClose }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const {
    providerDetails,
    getProviderDetails,
    preferredPharmacy: preferredData,
    getPreferredPharmacy,
  } = useStateGlobalContext();

  useEffect(() => {
    if (rxData?.provider_id) {
      getProviderDetails(rxData?.provider_id);
      setLoading(false);
    }
  }, [rxData?.provider_id]);

  useEffect(() => {
    if (id) {
      getPreferredPharmacy(id);
    }
  }, [id]);

  const fullName = `${ptData?.first_name} ${
    ptData?.middle_name ? ptData?.middle_name : ""
  } ${ptData?.last_name}`;

  const { toPDF, targetRef } = usePDF({
    filename: `${fullName}.pdf` || "Encounter.pdf",
    page: {
      format: "A4",
      orientation: "portrait",
      margin: 10,
    },
    scale: 0.95,
  });

  const downloadPDF = () => {
    const noPrintElements = document.querySelectorAll(".no-print");

    // Hide no-print elements before generating the PDF
    noPrintElements.forEach((el) => (el.style.display = "none"));

    // Generate the PDF
    toPDF().then(() => {
      // Show no-print elements after PDF is generated
      noPrintElements.forEach((el) => (el.style.display = ""));
    });
  };
  return (
    <PrintDrawer title={fullName} visible={visible} onClose={onClose}>
      <div className="invoices-print">
        <div className="invoice-container">
          <Button onClick={downloadPDF} className="mb-2" type="primary">
            Download PDF
          </Button>
          <div className="invoice-content" ref={targetRef}>
            <h2 className="text-center mt-4 mb-4">Prescription</h2>
            <Row>
              <Col span={11}>
                <p className="font-semibold">Clinic Information</p>
                {ClinicInformation(officeData)}
              </Col>
              <Col span={2} />
              <Col span={11}>
                <p className="font-semibold">Pharmacy Information </p>
                {PreferredItem(preferredData)}
                <div style={{ opacity: 0.5 }}>
                  <img
                    src={RxImage}
                    alt="Prescription-Rx"
                    className="flex h-[90px]"
                  />
                </div>
              </Col>
            </Row>
            <Divider />

            <div className="flex justify-between">
              <div className="patient-info">
                <p className="font-semibold">Patient Information</p>
                <LabelDisplay title="Name" description={fullName} />
                <LabelDisplay
                  title="DOB"
                  description={<FormatDate date={ptData?.date_of_birth} />}
                />
                <LabelDisplay
                  title="Age"
                  description={`${ageCalculator(ptData?.date_of_birth)} y/o`}
                />
                <LabelDisplay title="Gender" description={ptData?.gender} />
                <LabelDisplay
                  title="Address"
                  description={ptData && fullAddress(ptData)}
                />
                <LabelDisplay
                  title="Phone #"
                  description={ptData && ptData?.patient_phone}
                />
              </div>
              <div>
                <LabelDisplay
                  title="Prescription Date"
                  description={
                    rxData && (
                      <FormatDateTime
                        date={rxData?.date_time}
                        type="datetime"
                      />
                    )
                  }
                />
              </div>
            </div>
            <Divider />
            {rxData?.prescriptions?.map((med, idx) => (
              <div key={idx} className="prescription-details">
                <LabelDisplay
                  title={`${idx + 1}: Medicine`}
                  description={med?.searched_medicine}
                />
                <LabelDisplay
                  title="instructions"
                  description={med?.instructions}
                />
                <LabelDisplay
                  title="Quantity"
                  description={med?.dispense_quantity}
                />
                <LabelDisplay title="Refills" description={med?.refills} />
                <LabelDisplay
                  title="Dispense as Written"
                  description={med?.dispense_as_written ? "Yes" : "No"}
                />
                <LabelDisplay
                  title="Start Date"
                  description={
                    med?.start_date ? (
                      <FormatDate date={med?.start_date} />
                    ) : (
                      "N/A"
                    )
                  }
                />
                <LabelDisplay
                  title="Stop Date"
                  description={
                    med?.end_date ? <FormatDate date={med?.end_date} /> : "N/A"
                  }
                />
                <Divider />
              </div>
            ))}

            <div className="flex justify-between text-right">
              {providerInfo(providerDetails)}
              <div className="flex items-end">
                <div>
                  <p>_______________________________</p>
                  <p className="flex justify-end">Signature</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PrintDrawer>
  );
};

export default PrescriptionPad;
