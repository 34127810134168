import React, { useEffect, useState } from 'react'
import {Card, Col, Table,  Button, Tooltip} from 'antd'
import { doGet } from "../../../../API/apis";
import { useNavigate } from "react-router-dom";
import Notification from '../../../../components/Notification/Notification';
import { recentEncountersColumns } from '../../../../components/TableColumns/TableColumns';
import CustomTable from '../../../../components/CustomTable/CustomTable';



const RecentEncounters = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)

  const getRecentEncounters = async () => {
    setLoading(true);
    try {
      const response = await doGet(
        `/visits/recent-encounters/`
      );
      if (response.status === 200) {
        setData(response?.data);
      }
    } catch (err) {
      Notification.error("Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(()=>{
    getRecentEncounters()
  },[])


  return (
    <Col xs={24} md={24} lg={16} xl={16}>
    <Card className="shadow-md"
     title="Recent Encounters"
    >
      {/* <h1 className="font-bold text-lg mb-2">Recent Encounters </h1> */}
      <CustomTable
       columns={recentEncountersColumns(navigate)}
       dataSource={data}
       loading={loading}
       rowKey={"recent_encounters"}
      />
    </Card>
  </Col>
  )
}

export default RecentEncounters