import { Button, Form, Modal, Input } from "antd";
import React, { useEffect } from "react";
import { getSession } from "../customHooks";
import axios from "axios";
import { useStateGlobalContext } from "../contexts/GlobalContext";
import Notification from "./Notification/Notification";

const AddStaffModal = ({ open, onClose, record }) => {
  const [form] = Form.useForm();
  const {getStaff} = useStateGlobalContext()

  useEffect(() => {
    if (record?.email || record?.user__email) {
      form.setFieldsValue({ email: record.email || record?.user__email });
    }
  }, [record, form]);

  const onFinish = async (values) => {
    try {
      const username = record.username || record?.user__username
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/administration/admin-update-email/${username}/`,
        {
            email: values.email,
          },
        {
            headers: { Authorization: `Bearer ${getSession("token")}` },
        }
 
      );
      if (response.status === 200) {
        Notification.success("Email Updated Successfully");
        getStaff()
        onClose();
      } else {
        Notification.error("Failed to update email");
      }
    } catch (error) {
      Notification.error("Failed to update email: " + error.message);
    }
  };

  const handleCancel = ()=>{
    onClose()
    form.resetFields()
  }

  return (
    <Modal
      title="Update Email"
      open={open}
      onCancel={handleCancel}
      footer={null}
      closeIcon={
        <Button size="small" className="app-close-icon" shape="round">
          Close
        </Button>
      }
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ email: record?.email || record?.user__email }}
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: "Please enter the email" },
            { type: "email", message: "Please enter a valid email" },
          ]}
        >
          <Input />
        </Form.Item>

        <div className="flex justify-end">
          <Button className="mr-2" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Update Email
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddStaffModal;
