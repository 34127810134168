import React from "react";
import {  Menu, Layout } from "antd";
import { Outlet, useNavigate } from "react-router-dom";
import Title from '../../../components/Title/Title'
import { useStateGlobalContext } from "../../../contexts/GlobalContext";
import { RefreshButton } from "../../../components/GlobalComponents/GlobalComponents";

const { Content } = Layout;



const items = [
  { label: "Appointment Calender", key: "" },
  { label: "Today's Appointments", key: "today-appointments" },
  { label: "Tomorrow's Appointments", key: "tomorrows-appointments" },
];

function AppointmentTabs() {
  const navigate = useNavigate();
  const { officeSetting , getAppointments} = useStateGlobalContext()

  const officeDateFormat = officeSetting && officeSetting?.date_format
  const officeTimeFormat = officeSetting && officeSetting?.time_format
  const officeTimezone = officeSetting && officeSetting?.facility_time_zone 


  document.title = "Appointment Management | American EMR";
  return (
    <Layout style={{ backgroundColor: "transparent" }}>
      <div className="flex justify-between items-center">
      <Title title='Appointment Management'/>
      <RefreshButton onClick={getAppointments}/>
      </div>
    <Menu
      onClick={({ key }) => navigate(key)}
      mode="horizontal"
      defaultSelectedKeys={[""]}
      items={items}
      className="summary-menu mb-4 bg-white text-[16px] font-medium tracking-[0.2px]"
    />
    <Content
      className="layout"
      style={{
        backgroundColor: "white",
      }}
    >
      <Outlet context={[officeDateFormat, officeTimeFormat, officeTimezone]} />
    </Content>
  </Layout>
  );
}

export default AppointmentTabs
