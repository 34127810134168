import React, { useState, useEffect } from "react";
import { Button} from "antd";
import { CSVLink } from "react-csv";
import { doGet } from "../../../../API/apis";
import {
  dateFormat,
  formatDate,
  formatTime,
} from "../../../../utils/DateFormat";
import Notification from "../../../../components/Notification/Notification";
import FormatDate from "../../../../components/FormatDate/FormatDate";
import CustomTable from "../../../../components/CustomTable/CustomTable";
import DateRangePickerCard from "../../../../components/GlobalComponents/DateRangePickerCard";
import { DetailCard } from "../../../../components/GlobalComponents/GlobalComponents";


const transformedData = (records) => {
  if (!records || !Array.isArray(records)) {
    return [];
  }
  return records.map((record) => ({
    ID: record?.id,
    "Patient First Name": record?.patient__first_name,
    "Patient Middle Name": record?.patient__middle_name,
    "Patient Last Name": record?.patient__last_name,
    "Patient DOB": dateFormat(record?.patient__date_of_birth),
    "Insurance Company Name": record?.insurance_company_name,
    "Insurance Type": record?.insurance_type,
    "Primary Insurer Full Name": record?.primary_account_full_name,
    "Members ID": record?.members_id,
    "Claim Date": formatDate(record?.claim_date),
    "Claim Officer Full Name": record?.claim_officer_full_name,
    "Claim Officer Phone": record?.claim_officer_phone_number,
    "Claim Officer Fax": record?.claim_officer_fax,
    "Claim Officer Email": record?.claim_officer_email,
    "Billed to Insurance": record?.billed,
    "Payment Received": record?.paid,
    "Outstanding Balance": record?.outstanding,
    Status: record?.status,
    Notes: record?.notes,
    "Added By": record?.added_by,
    "Date Added": record?.added_date && formatDate(record?.added_date),
    "Added Time": record?.added_date && formatTime(record?.added_date),
    "Status Changed By": record?.status_changed_by,
    "Status Change Date":
      record?.status_change_date && formatDate(record?.status_change_date),
    "Status Change Time":
      record?.status_change_date && formatTime(record?.status_change_date),
  }));
};

const fullName = (record) => {
  return (
    <span>
      {record?.patient__first_name}{" "}
      {record?.patient__middle_name ? record?.patient__middle_name : " "}{" "}
      {record?.patient__last_name}
    </span>
  );
};

const InsuranceClaims = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [searchClicked, setSearchClicked] = useState(false);


  const columns = [
    {
      key: 1,
      title: "Patient Full Name",
      width: "6%",
      align: "center",
      render: (_, record) => fullName(record),
    },
    {
      key: 2,
      title: "Patients DOB",
      dataIndex: "patient__date_of_birth",
      width: "7%",
      align: "center",
      render: (text) => <FormatDate date={text}/>,
    },
    {
      key: 3,
      title: "Insurance Company",
      dataIndex: "insurance_company_name",
      width: "10%",
      align: "center",
    },
    {
      key: 4,
      title: "Primary Insurer Full Name",
      dataIndex: "primary_account_full_name",
      width: "10%",
      align: "center",
    },
    {
      key: 5,
      title: "Members ID",
      dataIndex: "members_id",
      width: "10%",
      align: "center",
    },
    {
      key: 6,
      title: "Claim Date",
      dataIndex: "claim_date",
      align: "center",
      render: (text) => <FormatDate date={text}/>,
    },
    {
      key: 7,
      title: "Claims Officer",
      align: "center",
      widht: "8%",
      render: (_, record) => (
        <div>
          {record?.claim_officer_full_name && (
            <p>Name: {record?.claim_officer_full_name}</p>
          )}
          {record?.claim_officer_phone_number && (
            <p>Phone:{record?.claim_officer_phone_number}</p>
          )}
          {record?.claim_officer_fax && <p>Fax: {record?.claim_officer_fax}</p>}
          {record?.claim_officer_email && (
            <p>Email: {record?.claim_officer_email}</p>
          )}
        </div>
      ),
    },
    {
      key: 8,
      title: "Billed to Insurance",
      dataIndex: "billed",
      align: "center",
      width: "8%",
    },
    {
      key: 9,
      title: "Payment Received",
      dataIndex: "paid",
      align: "center",
      width: "8%",
    },
    {
      key: 10,
      title: "Outstanding Balance",
      dataIndex: "outstanding",
      width: "10%",
      align: "center",
    },
    {
      key: 11,
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (text, record) => {
        let bgColor = "";
        let textColor = "";
        switch (text) {
          case "Draft":
            bgColor = "bg-blue-400";
            textColor = "text-white";
            break;
          case "Submitted":
            bgColor = "bg-blue-700";
            textColor = "text-white";
            break;
          case "on Hold":
            bgColor = "bg-yellow-400";
            textColor = "text-white";
            break;
          case "Denied":
            bgColor = "bg-orange-700";
            textColor = "text-white";
            break;
          case "Approved":
            bgColor = "bg-green-400";
            textColor = "text-white";
            break;
          case "Paid":
            bgColor = "bg-green-700";
            textColor = "text-white";
            break;
          case "Ready for Check Out":
            bgColor = "bg-gray-400";
            textColor = "text-white";
            break;
          case "Checked Out":
            bgColor = "bg-gray-200";
            textColor = "text-gray-500";
            break;
          default:
            bgColor = "bg-gray-200";
        }

        return (
          <span
            className={`${bgColor} px-3 py-1 ${textColor} font-semibold text-xs rounded-md`}
          >
            {text}
          </span>
        );
      },
    },
  ];

  const handleDateChange = (dates) => {
    setSelectedDateRange(dates);
  };

  const handleReset = ()=>{
    setData([])
  }

  const handleSearch = () => {
    if (selectedDateRange && selectedDateRange.length === 2) {
      setSearchClicked(true);
    } else {
      Notification.warning("Please select a date range");
    }
  };

  const getInsuranceClaimsReports = async () => {
    setLoading(true);
    try {
      if (selectedDateRange && selectedDateRange.length === 2) {
        const startDate = selectedDateRange[0].format("YYYY-MM-DD");
        const endDate = selectedDateRange[1].format("YYYY-MM-DD");

        const response = await doGet(
          `/reports/insurance-claims-reporting/?start_date=${startDate}&end_date=${endDate}`
        );
        if (response.status === 200) {
          setData(response.data);
        }
      } else {
        Notification.warning("Please select a valid date range");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchClicked) {
      getInsuranceClaimsReports();
      setSearchClicked(false);
    }
  }, [searchClicked, selectedDateRange]);

  return (
      <DetailCard title="Insurance Claims Report">
      <DateRangePickerCard
        onDateChange={handleDateChange}
        onSearch={handleSearch}
        onReset={handleReset}
      />
        <div className="flex justify-between items-center mb-2">
          <h1>Search Results</h1>
          {data?.length > 0 && (
            <Button type="primary">
              <CSVLink
                className="csv-link"
                data={transformedData(data)}
                filename={"Insurance-Claims-report.csv"}
              >
                Download Report
              </CSVLink>
            </Button>
          )}
        </div>
        <CustomTable
          loading={loading}
          columns={columns}
          dataSource={data}
          scroll={{
            x: 1500,
          }}
          key="insurance_claims_reports"
        />
      </DetailCard>
  );
};

export default InsuranceClaims;
