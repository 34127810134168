import React from 'react';
import LabelDisplay from '../LabelDisplay/LabelDisplay';

const PreferredItem = (preferredData) => {
  if (!preferredData || preferredData.length === 0) {
    return <p>No pharmacy information available.</p>;
  }

  const item = preferredData[0];
  return (
    <div>
      <LabelDisplay title="Name" description={item?.name}/>
      <LabelDisplay title="Address" description={item?.address}/>
      <LabelDisplay title="Phone" description={item?.phone}/>
      <LabelDisplay title="Fax" description={item?.fax}/>
      <LabelDisplay title="Email" description={item?.email}/>
    </div>
  );
};

export default PreferredItem;
