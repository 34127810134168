import { Button, Card, Row, Col, Modal , Empty, Divider, Space } from "antd";
import React, { useEffect, useState } from "react";
import CreditDebit from "./CreditDebit";
import { useParams } from "react-router-dom";
import { doGet, doDelete } from "../../../../../API/apis";
import { DeleteOutlined, EditOutlined,  EyeOutlined } from "@ant-design/icons";
import AccountDetails from "./AccountDetails";
import Notification from "../../../../../components/Notification/Notification";
import showConfirmModal from "../../../../../components/ModalConfirm/ModalConfirm";

const getMaskedCardNumber = (cardNumber) => {
  const maskedDigits = cardNumber.slice(0, -4).replace(/\d/g, "*");
  const visibleDigits = cardNumber.slice(-4);
  const formattedNumber = maskedDigits.replace(/(.{4})/g, "$1 ");
  return formattedNumber + visibleDigits;
};

const PaymentMethod = () => {
  const { id } = useParams();
  const [view, setView] = useState(false)
  const [loading, setLoading] = useState(false);
  const [record, setRecord] = useState(null)
  const [cardData, setCardData] = useState(null);
  const [cardModal, setCardModal] = useState(false);
  const [bankModal, setBankModal] = useState(false)
  const [bankData, setBankData]=useState(null)
  const [edit, setEdit] = useState(false);
  const [bankRecord, setBankRecord] = useState(null)
  const [editBankRecord, setEditBankRecord] = useState(false)
  const [bankView, setBankView] = useState(false)
  

  const openCardModal = () => {
    setCardModal(true);
  };

  const closeCardModal = () => {
    setCardModal(false);
    setEdit(false)
  };

  const handleEdit = (record)=>{
    setEdit(true)
    setRecord(record)
    setCardModal(true)
  }

  const handleView =(record)=>{
    setView(true)
    setRecord(record)
  }

  const closeView = ()=>{
    setView(false)
  }

  const openBankModal = ()=>{
    setBankModal(true)
  }

  const closeBankModal = ()=>{
    setBankModal(false)
    setEditBankRecord(false)
  }

  const handleDelete = (record) => {
    Modal.confirm({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        deletePaymentMethod(record);
      },
    });
  };

  const deletePaymentMethod = async (record) => {
    try {
      const response = await doDelete(
        `/invoice/payment-method-delete/${id}/${record}/`
      );
      if (response.status === 200) {
        Notification.success("Payment Method deleted successfully");
        getPaymentCardsInfo();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getPaymentCardsInfo = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/invoice/payment-method-get/${id}/`);
      if (response.status === 200) {
        setCardData(response.data);
      }
    } catch (error) {
      console.log("Something Went Wrong", error);
    } finally {
      setLoading(false);
    }
  };

  const getbankAccountInfo = async () => {
    setLoading(true);
    try {
      const response = await doGet(
        `/invoice/account-details-get/${id}/`
      );
      if (response.status === 200) {
        setBankData(response.data);
      }
    } catch (error) {
      Notification.error("Something Went Wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleBankView = (record)=>{
    setBankView(true)
    setBankRecord(record)
  }

  const closeBankView = ()=>{
    setBankView(false)
  }
  
  const handleBankEdit = (record)=>{
    setEditBankRecord(true)
    setBankRecord(record)
    setBankModal(true)
  }
  const handleBankDelete = (record)=>{
   showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        deleteAccountDetails(record);
      },
    });
  }

  const deleteAccountDetails = async (record) => {
    try {
      const response = await doDelete(
        `/invoice/account-details-delete/${id}/${record}/`
      );
      if (response.status === 200) {
        Notification.success("Bank Account Details deleted successfully");
        getbankAccountInfo();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  useEffect(() => {
    getPaymentCardsInfo(id);
  }, []);

  useEffect(()=>{
    getbankAccountInfo(id)
  },[])
  return (
    <div>
      <Card className="shadow-class" title="Payment Method"
       extra={
        <Space>
            <Button type="primary" onClick={openCardModal}>
              Add New Card
            </Button>
            <Button type="primary" className="ml-2" onClick={openBankModal}>
              Add New Bank Details
            </Button>
        </Space>
       }
      >
        <p className="mt-4 mb-2 font-semibold">Credit / Debit Card Details</p>
        <Row gutter={[16, 16]}>
          {cardData && cardData.length > 0 ? (
            cardData.map((card) => (
              <Col key={card.id} span={8} xs={24} md={12} lg={8}>
                <Card className="shadow-class bg-gradient-to-br from-blue-300 via-blue-600 to-blue-700 h-16" loading={loading}>
                  <div className="flex justify-between">
                    <p className="text-white font-bold">{getMaskedCardNumber(card.card_number)}</p>
                    <div className="flex mb-1 gap-2 text-white">
                    {/* <EllipsisOutlined/> */}
                      <EyeOutlined onClick={() => handleView(card)} />
                      <EditOutlined onClick={() => handleEdit(card)} />
                      <DeleteOutlined onClick={() => handleDelete(card.id)} />
                    </div>
                  </div>
                </Card>
              </Col>
            ))
          ) : (
            <Col span={24}>
              <Empty description="No credit/debit card details found" />
            </Col>
          )}
        </Row>

        <Divider/>
        <p className="mt-4 mb-2 font-semibold">Bank Account Details</p>
        <Row gutter={[16, 16]}>
          {bankData && bankData.length > 0 ? (
            bankData.map((account) => (
              <Col key={account.id} span={8} xs={24} md={12} lg={8}>
                <Card className="shadow-class bg-gradient-to-br from-blue-300 via-blue-600 to-blue-700 h-16" loading={loading}>
                  <div className="flex justify-between">
                    <p className="text-white font-bold">{getMaskedCardNumber(account.bank_number)}</p>
                    <div className="flex mb-1 gap-2 text-white">
                      <EyeOutlined onClick={() => handleBankView(account)} />
                      <EditOutlined onClick={() => handleBankEdit(account)} />
                      <DeleteOutlined onClick={() => handleBankDelete(account.id)} />
                    </div>
                  </div>
                </Card>
              </Col>
            ))
          ) : (
            <Col span={24}>
              <Empty description="No bank account details found" />
            </Col>
          )}
        </Row>
      </Card>

      <CreditDebit
        visible={cardModal}
        onClose={closeCardModal}
        edit={edit}
        record={record}
        getPaymentCardInfo={getPaymentCardsInfo}
        view={view}
        onCloseView={closeView}
      />

      <AccountDetails
       visible={bankModal}
       onClose={closeBankModal}
       record={bankRecord}
       edit={editBankRecord}
       getbankAccountInfo={getbankAccountInfo}
       view={bankView}
       onCloseView={closeBankView}
      />
    </div>
  );
};

export default PaymentMethod;
