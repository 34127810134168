import React, { useEffect, useState } from "react";
import { Dropdown, Modal, Tooltip, Typography, Button, Tag, Card } from "antd";
import { doDelete, doPatch } from "../../../../../API/apis";
import { useParams } from "react-router-dom";
import { EllipsisOutlined } from "@ant-design/icons";
import ViewOrderedLabs from "./ViewOrderedLabs";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import LabPrescription from "../LabPrescription/LabPrescription";
import Notification from "../../../../../components/Notification/Notification";
import showConfirmModal from "../../../../../components/ModalConfirm/ModalConfirm";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";
import CustomTable from "../../../../../components/CustomTable/CustomTable";
import { orderedLabsActions } from "../../../../../utils/Data";

const OrderedLabs = () => {
  const { id } = useParams();
  const [viewData, setViewData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [labDrawer, setLabDrawer] = useState(false);
  const [labData, setLabData] = useState(null);

  const {
    orderedLabs,
    getLabOrders,
    patientBasicInfo,
    getPatientData,
    officeSetting,
    profile
  } = useStateGlobalContext();

  const role = profile?.user_info?.[0]?.role;

  const columns = [
    {
      title: "Lab Order ID",
      dataIndex: "lab_id",
      key: "lab_id",
      width: "15%",
      render: (text) => (
        <Tooltip title="Click to copy Lab Order ID">
          <Typography.Text
            className="cursor-pointer text-[#1890ff]"
            onClick={() => {
              navigator.clipboard.writeText(text);
              Notification.success("Lab Order ID copied to clipboard");
            }}
          >
            {text}
          </Typography.Text>
        </Tooltip>
      ),
    },
     {
       ke: 3,
       title: "Lab Name",
       dataIndex: "lab_name",
       render: (labs, record) => {
     
         if (labs && labs.length > 0) {
           const labNames = [];
     
           for (let i = 0; i < Math.min(labs.length, 3); i++) {
             const lab = labs[i];
             if (lab.lab_name) {
               labNames.push(lab.lab_name);
             }
           }
     
           const displayText = labs.length > 3 ? labNames.join(", ") + "..." : labNames.join(", ");
           
           return (
             <Button type="link" onClick={()=> handleView(record)}>
                {displayText}
             </Button>
           );
         } else {
           return (
             <div style={{ cursor: 'pointer' }}>
               N/A
             </div>
           );
         }
       },
     },

    {
      title: "Order Type",
      dataIndex: "lab_id",
      key: "lab_id",
      width: "10%",
      render: (lab_id) =>
        lab_id.includes("LAB_EX") ? (
          <Tag className="bg-green-600 text-white rounded-md">External</Tag>
        ) : (
          <Tag className="bg-[#1890ff] text-white rounded-md">In-House</Tag>
        ),
    },
    {
      title: "Ordered By",
      dataIndex: "added_by",
      key: "added_by",
      align: "center",
      width: "10%",
      render: (text) => (
        <span title={text}>
          {text.length > 10 ? text.slice(0, 15) + "..." : text}
        </span>
      ),
    },
    {
      title: "Date",
      dataIndex: "date_added",
      key: "date_added",
      width: "10%",
      align: "center",
      render: (text) => (
        <Tooltip title={<FormatDateTime date={text} type="time" />}>
          <span>{text ? <FormatDateTime date={text} type="date" /> : ""}</span>
        </Tooltip>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10%",
      align: "center",
      render: (status) =>
        status === "Completed" ? (
          <Tag className="bg-[#1890ff] text-white rounded-md">{status}</Tag>
        ) : (
          ""
        ),
    },
    {
      title: "Action",
      key: "action",
      width: "5%",
      fixed: "right",
      align: "center",
      render: (_, record) => {
        const { status } = record;
        const availableItems = orderedLabsActions.filter((item) => {
          if (role === "biller") {
            return ![3, 4].includes(item.key);
          }
          return status === "Completed"
            ? [1, 2, 4].includes(item.key)
            : true; 
        });
        return (
          <Dropdown
            menu={{
              items: availableItems.map((item) => ({
                ...item,
                onClick: () => {
                  if (item.key === 2) {
                    handlePrint(record);
                  } else if (item.key === 1) {
                    handleView(record);
                  } else if (item.key === 4) {
                    handleDelete(record);
                  } else if (item.key === 3) {
                    handleStatus(record);
                  }
                },
              })),
            }}
            trigger={["click"]}
          >
            <EllipsisOutlined className="cursor-pointer" />
          </Dropdown>
        );
      },
    },
  ];

  const handlePrint = (record) => {
    setLabDrawer(true);
    setLabData(record);
  };

  const onClose = () => {
    setLabDrawer(false);
  };

  const handleStatus = async (record) => {
    const response = await doPatch(
      `/labs/images/complete-ordered-labs/${id}/${record.id}/`,
      { status: "Completed" }
    );
    if (response.status === 200) {
      Notification.success("Status udpated Successfully");
      getLabOrders(id);
    }
  };

  const handleView = (record) => {
    setViewData(record);
    setVisible(true);
  };

  const handleDelete = (record) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        deleteLab(record.lab_id);
      },
    });
  };

  const deleteLab = async (recordId) => {
    try {
      const response = await doDelete(
        `/labs/images/delete-ordered-labs/${id}/${recordId}/`
      );
      if (response.status === 200) {
        getLabOrders(id);
        Notification.success("Lab deleted successfully");
      }
    } catch (error) {
      Notification.error("Something Went Wrong");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await getLabOrders(id)
        await getPatientData(id)
      } catch (error) {
        Notification.error("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  return (
    <Card title="Ordered Labs">
      <CustomTable
        columns={columns}
        dataSource={orderedLabs}
        loading={loading}
        scroll={{
          x: 1500,
        }}
        key="ordered_labs"
      />

      <Modal
        open={visible}
        footer={null}
        onCancel={() => setVisible(false)}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
      >
        <ViewOrderedLabs orderedLabData={viewData} />
      </Modal>

      <LabPrescription
        visible={labDrawer}
        rxData={labData}
        onClose={onClose}
        officeData={officeSetting}
        ptData={patientBasicInfo}
      />
    </Card>
  );
};

export default OrderedLabs;
