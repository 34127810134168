import React, { useEffect, useState } from "react";
import { ConfigProvider, Tabs } from "antd";
import InHouseLabs from './component/InhouseLabs';
import ExternalLabs from './component/ExternalHouse';
import OrderedLabs from './component/OrderedLabs';
import Results from './component/Results';
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";

const TabPane = [
  {
    key: "1",
    label: "Ordered Labs",
    content: <OrderedLabs />,
  },
  {
    key: "2",
    label: "Lab Results",
    content: <Results />,
  },
  {
    key: "3",
    label: "New In-house Labs",
    content: <InHouseLabs />,
  },
  {
    key: "4",
    label: "New External Labs",
    content: <ExternalLabs />,
  },
];

function Prescription() {
  const { profile } = useStateGlobalContext();

  const role = profile?.user_info?.[0]?.role;

  // Determine the default selected tab
  const defaultSelectedTab = "1";

  const [activeKey, setActiveKey] = useState(defaultSelectedTab);

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  // Filter tabs based on the user's role
  const filteredTabPane =
    role === "nurse"
      ? TabPane.filter((tab) => tab.key === "1" || tab.key === "2") // Nurse can only see "Ordered Labs" and "Lab Results"
      : role === "biller"
      ? TabPane.filter((tab) => tab.key === "1") // Biller can only see "Ordered Labs"
      : TabPane; // All tabs for other roles

  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              fontSize: 13,
            },
          },
        }}
      >
        <Tabs activeKey={activeKey} type="card" onTabClick={handleTabChange}>
          {filteredTabPane.map((tab) => (
            <Tabs.TabPane key={tab.key} tab={tab.label}>
              {activeKey === tab.key && tab.content}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </ConfigProvider>
    </div>
  );
}

export default Prescription;
