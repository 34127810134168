import { Button, Modal, Typography, Image, Spin } from "antd";
import {
  FilePdfOutlined,
} from "@ant-design/icons";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";
import LabelDisplay from "../../../../../components/LabelDisplay/LabelDisplay";
import { useEffect, useState } from "react";
import { doGet } from "../../../../../API/apis";

const ViewPatientFiles = ({ visible, onClose, data }) => {
  const [fileData, setFileData]= useState([])
  const [loading,setLoading] = useState(false)
  const showFile = (fileContent) => {
    window.open(fileContent, "_blank");
  };

    const handleFile = async () => {
      setLoading(true);
      try {
        const response = await doGet(`/upload/media/retrieve/${data?.uploaded_file_encrypted_name}/`);
        if (response.status === 200) {
          const fileContent = response.data;
          setFileData(fileContent);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    useEffect(()=>{
      handleFile()
    },[data])

  const renderFileContent = () => {
    const fileExtension = fileData?.type?.split("/").pop();
    if (fileExtension === "pdf") {
      return (
        <div className="pdf-container">
          <FilePdfOutlined style={{ fontSize: "64px", color: "#ff0000" }} />
          <Typography.Text strong className="pdf-label">
            PDF Document
          </Typography.Text>
        </div>
      );
    } else {
      return (
        <Image
          className="image-preview"
          src={fileData?.url}
        />
      );
    }
  };

  function formatFileSize(sizeInMB, decimals = 2) {
    return sizeInMB?.toFixed(decimals) + ' MB';
  }

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      width={650}
      title={data && data?.filename}
      confirmLoading={loading}
      closeIcon={
        <Button size="small" className="app-close-icon" shape="round">Close</Button>
      }
    >
      <LabelDisplay title="Uploaded By" description={data?.added_by}/>
      <LabelDisplay title="Date Added" description={<FormatDateTime date={data?.date_time} type="datetime"/>}/>
      <LabelDisplay title="File Name" description={data?.filename || data?.filename && formatFileSize(fileData?.size)}/>
      <LabelDisplay title="Description" description={data?.description}/>

      
      {loading ? (
        <div className="text-center">
          <Spin size="small" />
        </div>
      ) : (
        renderFileContent()
      )}

      <div className="mt-4">
        <Button
          type={loading ? "default":"primary"}
          onClick={() => showFile(fileData && fileData?.url)}
          disabled={loading}
        >
          View File
        </Button>
      </div>
    </Modal>
  );
};

export default ViewPatientFiles;
