import React, { useEffect, useRef, useState } from "react";
import { ConfigProvider, Layout, Menu } from "antd";
import {
  SettingOutlined,
  HomeOutlined,
  CalendarOutlined,
  FolderOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import { FaTasks } from "react-icons/fa";
import { LuSofa } from "react-icons/lu";
import {
  MdOutlineMedicalServices,
  MdOutlineCardMembership,
} from "react-icons/md";
import { LiaClinicMedicalSolid } from "react-icons/lia";
import { MdOutlineHomeRepairService } from "react-icons/md";
import { TbReportSearch } from "react-icons/tb";
import { CgTemplate } from "react-icons/cg";
import Logo from "./Logo";
import { COLOR } from "./ApplicationLayout";
import { useLocation, useNavigate } from "react-router-dom";

const { Sider } = Layout;

const SideNav = ({ role , ...others }) => {
    const nodeRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();

  const menuItems = [
    {
      key: "/main",
      icon: <HomeOutlined />,
      label: "Admin Dashboard",
      role: ["clinic", "manager"],
    },
    {
      key: "/main/biller_dashboard",
      icon: <HomeOutlined />,
      label: "Biller Dashboard",
      role: ["biller"],
    },

    {
      key: "/main/work_dashboard",
      icon: <LiaClinicMedicalSolid size={18} />,
      label: "Work Dashboard",
      role: ["clinic", "manager", "nurse", "doctor"],
    },
    {
      key: "/main/appointments",
      icon: <CalendarOutlined />,
      label: "Appointment",
      role: ["clinic", "manager", "nurse", "doctor"],
    },
    {
      key: "/main/charts",
      icon: <ProfileOutlined />,
      label: "Charts",
      role: ["clinic", "manager", "nurse", "doctor", "biller"],
    },
    {
      key: "/main/waiting-room",
      icon: <LuSofa size={18} />,
      label: "Waiting Room",
      role: ["clinic", "manager", "nurse", "doctor"],
    },
    {
      key: "/main/tasks",
      icon: <FaTasks size={18} />,
      label: "Tasks",
      role: ["clinic", "manager", "nurse", "doctor"],
    },

    {
      key: "/main/services",
      icon: <MdOutlineHomeRepairService size={18} />,
      label: "Services",
      role: ["clinic", "manager", "doctor", "nurse"],
    },
    {
      key: "/main/inventory",
      icon: <MdOutlineMedicalServices size={18} />,
      label: "Inventory",
      role: ["clinic", "manager", "nurse", "doctor"],
    },
    {
      key: "/main/memberships",
      icon: <MdOutlineCardMembership size={18} />,
      label: "Memberships",
      role: ["clinic", "manager", "nurse", "doctor"],
    },
    {
      key: "/main/templates",
      icon: <CgTemplate size={18} />,
      label: "Templates",
      role: ["clinic", "manager", "doctor"],
    },
    {
      key: "/main/reports",
      icon: <TbReportSearch size={18} />,
      label: "Reports",
      role: ["clinic", "manager", "doctor", "nurse", "biller"],
    },
    {
      key: "/main/setting",
      icon: <SettingOutlined />,
      label: "Office Setting",
      role: ["clinic", "manager"],
    },
  ];


  const filteredMenuItems = menuItems.filter(item => item.role.includes(role));

  return (
    <Sider ref={nodeRef} breakpoint="lg"   width={220} collapsedWidth="80" {...others}>
      <Logo
      others={others}
      />

        <Menu
          mode="inline"
          selectedKeys={[location.pathname]}
          onClick={({ key }) => navigate(key)}
          style={{ border: "none", background:  "#f7f7f7", }}
        >
          {filteredMenuItems.map((item) => (
            <Menu.Item key={item.key} icon={item.icon}>
              {item.label}
            </Menu.Item>
          ))}
        </Menu>
    </Sider>
  );
};

export default SideNav;
